import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

export const StaffAuthenticationGuard = ({ component, options }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <div></div>,
    });

    return <Component options={options} />;
};
