import { useContext, useEffect } from "react";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { LoginButton } from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

const FamilyLoginPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { loggedInUser, refreshUser } = useContext(AppContext);

    useEffect(() => {
        document.title = "Cofertility | Login";

        if (isAuthenticated) {
            const User = async () => {
                await refreshUser();
            };

            if (!loggedInUser) {
                User();
            } else {
                if (loggedInUser.isStaff) {
                    navigate("/staff/split-members");
                } else if (loggedInUser.freeze_member) {
                    if (loggedInUser.freeze_member.status === "failed") {
                        navigate("/keep/dashboard");
                    } else if (loggedInUser.appFinished) {
                        navigate("/freeze/dashboard");
                    } else if (loggedInUser.isWelcomed) {
                        navigate("/freeze/application");
                    } else {
                        navigate("/freeze/welcome");
                    }
                } else if (loggedInUser.intended_parent) {
                    if (loggedInUser.appFinished) {
                        navigate("/family/donors");
                    } else {
                        let env = process.env.REACT_APP_ENV;
                        if (env === "local") {
                            env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
                        }
                        window.location.href = `https://zvokymtzgt.formsort.app/flow/family-intake-form/variant/main?responderUuid=${loggedInUser.responderUuid}&formsortEnv=${env}`;
                    }
                }
            }
        }
    }, [isAuthenticated, loggedInUser, refreshUser, navigate]);

    let logo = <img className="pb-2" height="31" width="148" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    const mobileLogo = <img srcSet={`${process.env.PUBLIC_URL}/images/logo.svg`} className="block md:hidden w-45 pt-8 pb-16" alt="Mobile logo" />;

    let signInLogo = <img className="pt-2" height="34" width="181" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/family_by_co_logo.svg`} />;

    const sidebar_image = (
        <img
            srcSet={`${process.env.PUBLIC_URL}/images/family_login_image.jpeg 390w`}
            sizes="(min-width: 768px) 390px"
            className="hidden md:block md:w-sidebar md:h-screen object-cover object-[70%_center]"
            alt="Father smiling while holding child"
        />
    );

    return (
        <div id="inner-body">
            <div id="app" className="pb-10 freeze">
                <div className="w-full md:h-screen md:w-sidebar md:fixed">
                    <h1 className="absolute hidden md:block top-0 p-12 w-3/4">
                        <a href="https://www.cofertility.com">{logo}</a>
                        Family building options that put you first.
                    </h1>
                    <div className="absolute hidden md:block bottom-0 p-12 w-3/4">
                        Have a question?
                        <br />
                        Email us at{" "}
                        <a href="mailto:support@cofertility.com" className="hover:text-freeze-secondary underline underline-offset-2">
                            support@cofertility.com
                        </a>
                    </div>
                    {sidebar_image}
                </div>
                <div className="md:ml-sidebar md:h-screen flex-1 flex flex-col px-6">
                    <div className="md:mx-auto w-full md:max-w-md md:mt-29 flex flex-col justify-center relative">
                        <div className="bg-black md:bg-transparent bg-family-login-mobile bg-cover bg-[60%_center] bg-no-repeat mb-10 md:bg-none -mx-6 px-6 md:text-black flex flex-col">
                            {mobileLogo}
                            <div className="mb-5 h-8 w-8 mt-6 md:mt-0">
                                <div className="active" style={{ display: "none" }}>
                                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h1 className="mb-8 display h2 md:h1 flex items-center">
                                <div className="mr-2">Sign in to</div>
                                <div>{signInLogo}</div>
                            </h1>
                        </div>
                        <div className="flex md:flex-col gap-8 flex-col-reverse">
                            <GoogleLoginButton />
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm min-w-full">
                                    <span className="px-4 py bg-white-200 border border-gray-300 text-gray-700 uppercase text-[10px] rounded-full font-bold">Or</span>
                                </div>
                            </div>
                            <div>
                                <LoginButton />
                            </div>
                        </div>

                        <div className="bg-black text-white p-3 mt-10 text-center text-sm">
                            Having trouble logging in? You may not have migrated your account to our new experience yet. Check your email for instructions or contact{" "}
                            <a href="mailto:support@cofertility.com" className="!text-white underline">
                                support@cofertility.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyLoginPage;
