import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../auth/LogoutButton";
import { redirectToFormsort } from "../../util/helpers";
import { AppContext } from "../../App";

const Application = () => {
    const [applicationForms, setApplicationForms] = useState([]);
    const [userRetrieved, setUserRetrieved] = useState(false);
    const [prescreener, setPrescreener] = useState(null);
    const [progress, setProgress] = useState(0);
    const [hasError, setHasError] = useState(null);
    const navigate = useNavigate();
    const { loggedInUser, refreshUser, request } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
            setUserRetrieved(true);
        };

        if (loggedInUser === null) {
            refresh();
        } else {
            setUserRetrieved(true);
        }
    }, [loggedInUser, refreshUser, setUserRetrieved]);

    useEffect(() => {
        document.title = "Cofertility | Application";

        if (!userRetrieved) {
            return;
        }

        if (loggedInUser.appFinished) {
            navigate("/freeze/dashboard");
        }

        const fetchApplications = async () => {
            try {
                const response = await request({
                    url: `/applications`,
                    method: "GET",
                });

                setApplicationForms(response.applicationForms.filter((a) => a.flow !== "screener"));
                setPrescreener(response.applicationForms.filter((a) => a.flow === "screener")[0]);

                let count = 0;
                response.applicationForms
                    .filter((a) => a.flow !== "screener")
                    .forEach((form) => {
                        if (form.application && form.application.status === "complete") {
                            count++;
                        }
                    });

                setProgress(count);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching applications:", error);
                setHasError(error);
            }
        };

        fetchApplications();
    }, [request, navigate, loggedInUser, userRetrieved]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const submitApplication = async () => {
        try {
            await request({
                url: `/applications/submit-application`,
                method: "PUT",
            });

            navigate("/freeze/dashboard", { state: { from: "/freeze/application" } });
        } catch (err) {
            // TODO: handle this error
            console.error(err);
        }
    };

    const navigateToTimline = () => {
        navigate("/freeze/timeline");
    };

    const navigateToFaq = () => {
        navigate("/freeze/application/faq");
    };

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/freeze/logo.svg`} />;

    let footerLogo = <img alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    const getApplicationStatusIcon = (form) => {
        if (!form.application) {
            return null;
        } else if (form.application && form.application.status === "in_progress") {
            return (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.0732 8.54895C22.7871 7.58022 24.2348 7.80985 24.6139 8.95192L32.1775 31.7407C32.5392 32.8305 31.648 33.9442 30.5533 33.7705L8.74571 30.3117C7.65095 30.1381 7.14814 28.8033 7.82928 27.8789L22.0732 8.54895Z"
                        fill="#191919"
                        stroke="#191919"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5088 6.63856C21.2485 5.64656 22.7535 5.88528 23.15 7.05748L31.0608 30.4474C31.4391 31.5659 30.5155 32.7062 29.3773 32.5257L6.70638 28.9299C5.56827 28.7494 5.04278 27.3793 5.7486 26.4327L20.5088 6.63856Z"
                        fill="#FFDA56"
                        stroke="#191919"
                    />
                    <rect x="19.5606" y="14.7686" width="2" height="7" transform="rotate(9.01247 19.5606 14.7686)" fill="#191919" />
                    <rect x="18.1508" y="23.6575" width="2" height="2" transform="rotate(9.01247 18.1508 23.6575)" fill="#191919" />
                </svg>
            );
        } else if (form.application && form.application.status === "complete") {
            return (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_4794_69487" fill="white">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M28.311 10.7016C27.8733 10.3649 27.2454 10.4467 26.9087 10.8844L17.7793 22.7499L14.5534 20.2678C14.1157 19.931 13.4878 20.0129 13.1511 20.4506L9.68747 24.9522C9.35069 25.39 9.43252 26.0178 9.87023 26.3546L13.8775 29.4378C13.8812 29.4407 13.8849 29.4436 13.8886 29.4465L17.3868 32.138C17.8245 32.4748 18.4524 32.393 18.7892 31.9553L31.992 14.7955C32.3287 14.3578 32.2469 13.7299 31.8092 13.3931L28.311 10.7016Z"
                        />
                    </mask>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.311 10.7016C27.8733 10.3649 27.2454 10.4467 26.9087 10.8844L17.7793 22.7499L14.5534 20.2678C14.1157 19.931 13.4878 20.0129 13.1511 20.4506L9.68747 24.9522C9.35069 25.39 9.43252 26.0178 9.87023 26.3546L13.8775 29.4378C13.8812 29.4407 13.8849 29.4436 13.8886 29.4465L17.3868 32.138C17.8245 32.4748 18.4524 32.393 18.7892 31.9553L31.992 14.7955C32.3287 14.3578 32.2469 13.7299 31.8092 13.3931L28.311 10.7016Z"
                        fill="#191919"
                    />
                    <path
                        d="M26.9087 10.8844L27.7012 11.4942L26.9087 10.8844ZM17.7793 22.7499L17.1695 23.5424L17.9621 24.1522L18.5719 23.3597L17.7793 22.7499ZM14.5534 20.2678L15.1632 19.4753L14.5534 20.2678ZM13.1511 20.4506L13.9436 21.0604L13.1511 20.4506ZM9.68747 24.9522L8.89491 24.3424L9.68747 24.9522ZM9.87023 26.3546L10.48 25.562L9.87023 26.3546ZM13.8775 29.4378L14.4983 28.6537L14.4873 28.6453L13.8775 29.4378ZM13.8886 29.4465L13.2788 30.2391L13.8886 29.4465ZM17.3868 32.138L17.9966 31.3455L17.3868 32.138ZM18.7892 31.9553L17.9966 31.3455L18.7892 31.9553ZM31.992 14.7955L32.7845 15.4053L31.992 14.7955ZM27.7012 11.4942L27.7012 11.4942L28.9208 9.90908C28.0454 9.23551 26.7897 9.39916 26.1161 10.2746L27.7012 11.4942ZM18.5719 23.3597L27.7012 11.4942L26.1161 10.2746L16.9868 22.1401L18.5719 23.3597ZM13.9436 21.0604L17.1695 23.5424L18.3891 21.9573L15.1632 19.4753L13.9436 21.0604ZM13.9436 21.0604L13.9436 21.0604L15.1632 19.4753C14.2878 18.8017 13.0321 18.9654 12.3585 19.8408L13.9436 21.0604ZM10.48 25.562L13.9436 21.0604L12.3585 19.8408L8.89491 24.3424L10.48 25.562ZM10.48 25.562L10.48 25.562L8.89491 24.3424C8.22135 25.2179 8.385 26.4736 9.26044 27.1472L10.48 25.562ZM14.4873 28.6453L10.48 25.562L9.26044 27.1472L13.2677 30.2304L14.4873 28.6453ZM14.4984 28.6539C14.4983 28.6539 14.4983 28.6538 14.4982 28.6538L13.2568 30.2219C13.2641 30.2277 13.2715 30.2334 13.2788 30.2391L14.4984 28.6539ZM17.9966 31.3455L14.4984 28.6539L13.2788 30.2391L16.777 32.9306L17.9966 31.3455ZM17.9966 31.3455L17.9966 31.3455L16.777 32.9306C17.6525 33.6041 18.9082 33.4405 19.5817 32.565L17.9966 31.3455ZM31.1994 14.1857L17.9966 31.3455L19.5817 32.565L32.7845 15.4053L31.1994 14.1857ZM31.1994 14.1857L31.1994 14.1857L32.7845 15.4053C33.4581 14.5299 33.2944 13.2742 32.419 12.6006L31.1994 14.1857ZM27.7012 11.4942L31.1994 14.1857L32.419 12.6006L28.9208 9.90908L27.7012 11.4942Z"
                        fill="#191919"
                        mask="url(#path-1-inside-1_4794_69487)"
                    />
                    <mask id="path-3-inside-2_4794_69487" fill="white">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.5975 8.92203C27.1598 8.58525 26.5319 8.66707 26.1951 9.10479L17.0658 20.9703L13.5534 18.2678C13.1157 17.931 12.4878 18.0129 12.1511 18.4506L8.68747 22.9522C8.35069 23.39 8.43252 24.0178 8.87023 24.3546L13.1652 27.6591C13.1685 27.6617 13.1718 27.6643 13.1751 27.6669L17.0357 30.6372C17.4734 30.974 18.1013 30.8922 18.438 30.4545L31.6408 13.2947C31.9776 12.857 31.8958 12.2292 31.4581 11.8924L27.5975 8.92203Z"
                        />
                    </mask>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.5975 8.92203C27.1598 8.58525 26.5319 8.66707 26.1951 9.10479L17.0658 20.9703L13.5534 18.2678C13.1157 17.931 12.4878 18.0129 12.1511 18.4506L8.68747 22.9522C8.35069 23.39 8.43252 24.0178 8.87023 24.3546L13.1652 27.6591C13.1685 27.6617 13.1718 27.6643 13.1751 27.6669L17.0357 30.6372C17.4734 30.974 18.1013 30.8922 18.438 30.4545L31.6408 13.2947C31.9776 12.857 31.8958 12.2292 31.4581 11.8924L27.5975 8.92203Z"
                        fill="#ECEDED"
                    />
                    <path
                        d="M26.1951 9.10479L25.4026 8.49499L26.1951 9.10479ZM27.5975 8.92203L26.9877 9.71459L27.5975 8.92203ZM17.0658 20.9703L16.456 21.7628L17.2486 22.3726L17.8584 21.5801L17.0658 20.9703ZM12.1511 18.4506L12.9436 19.0604L12.1511 18.4506ZM8.68747 22.9522L7.89491 22.3424L8.68747 22.9522ZM8.87023 24.3546L8.26044 25.1472L8.87023 24.3546ZM13.1652 27.6591L13.7847 26.874L13.775 26.8666L13.1652 27.6591ZM31.4581 11.8924L32.0679 11.0998L31.4581 11.8924ZM26.9877 9.71459L26.9877 9.71459L28.2073 8.12947C27.3319 7.45591 26.0761 7.61956 25.4026 8.49499L26.9877 9.71459ZM17.8584 21.5801L26.9877 9.71459L25.4026 8.49499L16.2732 20.3605L17.8584 21.5801ZM12.9436 19.0604L16.456 21.7628L17.6756 20.1777L14.1632 17.4753L12.9436 19.0604ZM12.9436 19.0604L12.9436 19.0604L14.1632 17.4753C13.2878 16.8017 12.0321 16.9654 11.3585 17.8408L12.9436 19.0604ZM9.48003 23.562L12.9436 19.0604L11.3585 17.8408L7.89491 22.3424L9.48003 23.562ZM9.48003 23.562L7.89491 22.3424C7.22135 23.2179 7.385 24.4736 8.26044 25.1472L9.48003 23.562ZM13.775 26.8666L9.48003 23.562L8.26044 25.1472L12.5554 28.4517L13.775 26.8666ZM13.7849 26.8743L13.7846 26.8741L12.5457 28.4442C12.5521 28.4492 12.5586 28.4543 12.5653 28.4594L13.7849 26.8743ZM17.6455 29.8447L13.7849 26.8743L12.5653 28.4594L16.4259 31.4298L17.6455 29.8447ZM17.6455 29.8447L17.6455 29.8447L16.4259 31.4298C17.3013 32.1034 18.557 31.9397 19.2306 31.0643L17.6455 29.8447ZM30.8483 12.6849L17.6455 29.8447L19.2306 31.0643L32.4334 13.9045L30.8483 12.6849ZM30.8483 12.6849L30.8483 12.6849L32.4334 13.9045C33.107 13.0291 32.9433 11.7734 32.0679 11.0998L30.8483 12.6849ZM26.9877 9.71459L30.8483 12.6849L32.0679 11.0998L28.2073 8.12947L26.9877 9.71459Z"
                        fill="#191919"
                        mask="url(#path-3-inside-2_4794_69487)"
                    />
                </svg>
            );
        }
    };

    if (!userRetrieved) {
        return null;
    }

    return (
        <div id="inner-body" className="bg-white">
            <header className="sm:py-8 sm:px-10 px-6 py-5 bg-primary flex items-center justify-between">
                {logo}
                <LogoutButton />
            </header>

            <div id="app" className="pb-10 freeze">
                <header className={`${applicationForms.length !== 0 && progress === applicationForms.length ? "bg-tertiary-light" : "bg-freeze-primary"} px-6 sm:px-10 z-0`}>
                    <div className="flex flex-col gap-1 max-w-container mx-auto mt-8 mb-6 sm:mb-10">
                        {applicationForms.length !== 0 && progress === applicationForms.length ? (
                            <div>
                                <section>
                                    <h1 className="mb-6">
                                        Great work, {loggedInUser.firstName}!<br />
                                        Please <span className="heading-underline">submit your application</span> for review.
                                    </h1>
                                </section>
                                <button type="submit" onClick={submitApplication} className="button-primary">
                                    <div className="flex gap-3 items-center text-lg">
                                        <span>Submit</span>
                                        <span className="w-7 h-7">
                                            <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        ) : (
                            <div>
                                <section className="flex items-center">
                                    <h1 className="display grow mb-0">
                                        <span className="heading-underline-tertiary">Split Program Application</span>
                                    </h1>
                                    <div className="flex gap-1">
                                        <span className="w-6 h-6">
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                    fill="#191919"
                                                    stroke="#191919"
                                                ></path>
                                                <path
                                                    d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                    fill="#ECEDED"
                                                    stroke="#191919"
                                                ></path>
                                                <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                            </svg>
                                        </span>
                                        <span>30 min</span>
                                    </div>
                                </section>
                                <section>All sections must be complete before your application can be submitted. Your progress will save automatically.</section>
                                <section className="flex items-center gap-2">
                                    <span onClick={navigateToFaq} className="font-normal cursor-pointer underline">
                                        FAQs
                                    </span>
                                </section>
                            </div>
                        )}
                    </div>
                    <div className="absolute left-0 w-full -z-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 108">
                            <path
                                className={`${applicationForms.length !== 0 && progress === applicationForms.length ? "fill-tertiary-light" : "fill-freeze-primary"}`}
                                d="M617.208 0H0v105.007C23.7 88.585 160.614 9.794 617.208 0ZM1440 105.255V0H808.834C1269.04 9.844 1415.29 89.393 1440 105.255Z"
                            ></path>
                        </svg>
                    </div>
                </header>

                <section className="sm:px-10 px-6">
                    <div className="max-w-container mx-auto flex flex-col gap-4">
                        {progress < 2 ? (
                            <div className="flex items-center bg-freeze-secondary-50 border-2 border-black w-full rounded-full mb-4 relative overflow-hidden">
                                <div
                                    className={`bg-freeze-secondary items-center rounded-full text-right transition-width h-7 duration-1000 bg-none flex justify-end`}
                                    style={{ width: `${(progress / applicationForms.length) * 100}%` }}
                                ></div>
                                <div className={`flex items-center text-sm whitespace-nowrap  ml-2 duration-500 text-freeze-secondary`}>
                                    {progress} of {applicationForms.length} {applicationForms.length !== 0 && progress === applicationForms.length ? <span className="ml-3 text-lg">🥳</span> : null}
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center bg-freeze-secondary-50 border-2 border-black w-full rounded-full mb-4 relative overflow-hidden">
                                <div
                                    className={`bg-freeze-secondary items-center rounded-full text-right transition-width h-7 duration-1000 bg-none flex justify-end`}
                                    style={{ width: `${(progress / applicationForms.length) * 100}%` }}
                                >
                                    <div className={`flex items-center text-sm whitespace-nowrap absolute transition-left mr-4 duration-500 ${progress > 0 ? "text-white" : "text-freeze-secondary"}`}>
                                        {progress} of {applicationForms.length}{" "}
                                        {applicationForms.length !== 0 && progress === applicationForms.length ? <span className="ml-3 text-lg">🥳</span> : null}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div id="application-links" className="flex flex-col gap-4 relative before:absolute before:bg-white-400 before:left-1/2 before:w-px before:h-full before:-z-10">
                            {applicationForms.map((form) => (
                                <div
                                    key={`${form.id}`}
                                    onClick={() => {
                                        if (["freeze-split-application-general-questions", "split-application-physical-attributes", "split-application-lifestyle-factors"].includes(form.flow)) {
                                            redirectToFormsort({
                                                form: form,
                                                user: loggedInUser,
                                                preload: prescreener,
                                            });
                                            return;
                                        }
                                        redirectToFormsort({ form, user: loggedInUser });
                                    }}
                                >
                                    <button
                                        className={`w-full rounded-full py-4 px-8 flex justify-between items-center ${
                                            form.application && form.application.status === "complete"
                                                ? "bg-[#ECEDED] text-black border-2 border-[#B3B3B3]"
                                                : "bg-freeze-secondary text-white hover:border-freeze-primary border border-freeze-secondary"
                                        }`}
                                    >
                                        <h3 className="mb-0 truncate">{form.name}</h3>
                                        <span>{getApplicationStatusIcon(form)}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <footer className="flex justify-between bg-black text-white sm:py-4 sm:px-10 px-6 py-5 font-normal">
                {footerLogo}
                <div>
                    <span className="hidden sm:inline">We're here to help. Email us at </span>
                    <a className="underline" href="mailto:support@cofertility.com">
                        support@cofertility.com
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Application;
