import React from "react";

export const InputFilter = ({ name, title, placeholder, clearState, clearValue, filters, handleNameSearch }) => {
    return (
        <div>
            <h4 className="p-1 my-1 ">{title}</h4>
            <div
                className={`flex justify-between p-2 w-3/4 bg-white rounded-lg border border-black`}
                onFocus={(e) => {
                    e.currentTarget.classList.add("ring-2", "ring-blue-500");
                    e.currentTarget.classList.remove("border", "border-black");
                }}
                onBlur={(e) => {
                    e.currentTarget.classList.remove("ring-2", "ring-blue-500");
                    e.currentTarget.classList.add("border", "border-black");
                }}
            >
                <input
                    className="focus:outline-none focus:ring-0 w-3/4 "
                    value={filters.filter((f) => f.name === name).length ? filters.filter((f) => f.name === name)[0].value : ""}
                    onChange={(event) => handleNameSearch(name, event)}
                    placeholder={placeholder}
                />
                <button onClick={() => clearState(clearValue)} className="bg-none cursor-pointer -translate-x-1/2">
                    x
                </button>
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
