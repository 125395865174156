import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../auth/LogoutButton";

const FAQ = ({ backNav, items }) => {
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Cofertility | FAQ";
    }, []);

    const toggleSelected = (num) => {
        const filteredSelected = structuredClone(selected).filter((selected) => selected !== num);

        if (!selected.includes(num)) {
            filteredSelected.push(num);
        }

        setSelected(filteredSelected);
    };

    const goBack = (path) => {
        navigate(path);
    };

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/freeze/logo.svg`} />;

    return (
        <div className="pb-20 bg-white-200">
            {backNav === "/freeze/application" ? (
                <header className="sm:py-8 sm:px-10 px-6 py-5 bg-primary flex items-center justify-between">
                    {logo}
                    <LogoutButton />
                </header>
            ) : null}
            <div className={`px-6 grow flex flex-col`}>
                <div id="faq" className="container mx-auto my-auto">
                    <div className="mt-6 mb-6">
                        <div onClick={() => goBack(backNav)} className="flex gap-x-2 items-center !no-underline cursor-pointer">
                            <span className="h-5 w-5">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <span className="!no-underline">Back</span>
                        </div>
                    </div>

                    <h1 className="mb-1">
                        <span className="heading-underline">Frequently asked questions</span>
                    </h1>

                    <div className="mb-6">
                        <p>If you have any questions at all, please don't hesitate to email. We're here to help.</p>
                        <p>
                            <a href="mailto:support@cofertility.com" rel="noopener noreferrer" target="_blank">
                                support@cofertility.com
                            </a>
                        </p>
                    </div>

                    <div>
                        {items.map((item, i) => (
                            <div className={`${i === items.length - 1 ? "" : "border-b"} mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(i)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        {item.question}
                                        <span className={`${selected.includes(i) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(i) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-7 mb-2 answer whitespace-pre-line">{item.answer}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
