import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const CheckIns = () => {
    const [checkins, setCheckIns] = useState([]);
    const [hasError, setHasError] = useState(null);
    // TODO: redo as individual states setting values to asc/desc
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Staff | IP | Check-Ins";
        const fetchCheckIns = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/check-ins`,
                    method: "GET",
                });

                const { checkins } = response;

                setCheckIns(checkins);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchCheckIns();
    }, [request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/intended-parents/${userId}`);
    };

    return (
        <table className="table-fixed w-full divide-y divide-dotted">
            <thead>
                <tr>
                    <th className="w-1/6 relative">Time Until Deadline</th>
                    <th className="w-1/6 relative">Donor Name</th>
                    <th className="w-1/6 relative">Check-In Sent</th>
                    <th className="w-1/6 relative">Submission Deadline</th>
                    <th className="w-1/6 relative">Edit</th>
                    <th className="w-1/6 relative">Action</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-dotted">
                {checkins &&
                    checkins.map((checkin) => {
                        return (
                            <tr key={checkin.id}>
                                <td className="w-1/6 text-center"></td>
                                <td className="w-1/6 text-center text-secondary">
                                    <div onClick={() => navigateToSplitMemberPage(checkin.userId)}>
                                        {checkin.user.firstName} {checkin.user.lastName}
                                    </div>
                                </td>
                                <td className="w-1/6 text-center"></td>
                                <td className="w-1/6 text-center"></td>
                                <td className="w-1/6 text-center"></td>
                                <td className="w-1/6 text-center"></td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

export default CheckIns;
