import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const PhotoRequests = () => {
    const [photoRequests, setPhotoRequests] = useState([]);
    const [hasError, setHasError] = useState(null);
    // TODO: redo as individual states setting values to asc/desc
    const { request } = useContext(AppContext);
    const navigate = useNavigate();
    const [filterBy, setFilterBy] = useState("pending");

    useEffect(() => {
        document.title = "Staff | SM | Photo Requests";
        const fetchPhotoRequests = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/photo-requests?status=${filterBy}`,
                    method: "GET",
                });

                const { photoRequests } = response;

                setPhotoRequests(photoRequests);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchPhotoRequests();
    }, [request, filterBy]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleString = (s) => {
        if (typeof s !== "string") {
            return s;
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const setStatusStyle = (status) => {
        if (status === "rejected") {
            return {
                backgroundColor: "#E0605A",
                color: "white",
            };
        } else if (status === "approved") {
            return {
                backgroundColor: "#D2FC99",
                color: "black",
            };
        }

        return {
            backgroundColor: "#D2E9E6",
            color: "secondary",
        };
    };

    const setQuickFilter = (status) => {
        setPhotoRequests([]);
        setFilterBy(status);
    };

    return (
        <div className="mx-10 mb-20">
            <div className="flex gap-2 flex-wrap w-[90%]">
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "pending" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("pending")}
                >
                    Pending
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "rejected" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("rejected")}
                >
                    Rejected
                </button>
                <button
                    className={`text-secondary border border-secondary rounded-full py-2 px-6 font-semibold text-xs hover:bg-green-100 focus:outline-none ${
                        filterBy === "approved" ? "bg-green-100" : "bg-white"
                    }`}
                    onClick={() => setQuickFilter("approved")}
                >
                    Approved
                </button>
            </div>
            <table className="table-fixed w-full mt-10">
                <thead>
                    <tr className="my-5">
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Submitted</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Donor Name</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Photo</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Status</th>
                        <th className="w-1/7 text-left py-2 border-b border-dashed border-gray-300">Actions</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-dotted">
                    {photoRequests &&
                        photoRequests.map((request) => {
                            return (
                                <tr key={request.id}>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">{extractDate(request.created_at)}</td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 text-secondary">
                                        <div onClick={() => navigateToSplitMemberPage(request.user_id)} className="cursor-pointer">
                                            {request.user.firstName} {request.user.lastName}
                                        </div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 whitespace-pre-line">
                                        <div
                                            className="mr-1"
                                            style={{
                                                backgroundImage: `url("${request.photo.cropped_source}")`,
                                                height: 300,
                                                gridRowStart: null,
                                                gridColumnStart: null,
                                                backgroundSize: "cover",
                                                backgroundPosition: "top",
                                            }}
                                        ></div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300 font-semibold">
                                        <div
                                            className="h-7 text-secondary px-2 py-1 w-3/4 rounded-md border font-medium text-sm flex justify-center items-center"
                                            style={setStatusStyle(request.status)}
                                        >
                                            <span>{handleString(request.status)}</span>
                                        </div>
                                    </td>
                                    <td className="py-2 pr-4 border-b border-dashed border-gray-300">
                                        <button
                                            onClick={() => navigate(`/staff/split-members/${request.user_id}/photos`)}
                                            className="bg-secondary text-white font-normal py-2 px-4 hover:text-primary w-full h-12 my-2 rounded-full"
                                        >
                                            {filterBy !== "pending" ? "Review Again" : "Review"}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default PhotoRequests;
