export const fmStatuses = [
    {
        label: "APP STARTED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_in_progress",
    },
    {
        label: "APP SUBMITTED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_submission",
    },
    {
        label: "APP SUB. HOLD",
        bg: "yellow",
        color: "secondary",
        value: "application_subhold",
    },
    {
        label: "APP APPROVED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "application_approved",
    },
    {
        label: "P&P COMPLETED",
        bg: "#F67EDF",
        color: "white",
        value: "pp_completed",
    },
    {
        label: "P&P UNDER REVIEW",
        bg: "#D2FC99",
        color: "black",
        value: "pp_under_review",
    },
    {
        label: "P&P REVISIONS",
        bg: "#D2FC99",
        color: "gold",
        value: "pp_revisions",
    },
    {
        label: "P&P QA",
        bg: "#D2FC99",
        color: "black",
        value: "pp_qa",
    },
    {
        label: "ACTIVE",
        bg: "#60D86A",
        color: "black",
        value: "active",
    },
    {
        label: "MATCHED",
        bg: "#60D86A",
        color: "black",
        value: "matched",
    },
    {
        label: "ON HOLD",
        bg: "#60D86A",
        color: "black",
        value: "on_hold",
    },
    {
        label: "PDF ONLY",
        bg: "#60D86A",
        color: "black",
        value: "pdf_only",
    },
    {
        label: "DISQUALIFIED",
        bg: "#E0605A",
        color: "white",
        value: "disqualified",
    },
    {
        label: "RETIRED",
        bg: "#E0605A",
        color: "purple",
        value: "retired",
    },
]

export const callStatuses = [
    {
        label: "CALL NOT SCHEDULED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "call_not_scheduled",
    },
    {
        label: "CALL SCHEDULED",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "call_scheduled",
    },
    {
        label: "CALL NO SHOW",
        bg: "#CACD9D",
        color: "red",
        value: "call_no_show",
    },
    {
        label: "CALL CANCELLED",
        bg: "#CACD9D",
        color: "red",
        value: "call_cancelled",
    },
    {
        label: "CALL COMPLETED",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "call_completed",
    },
]

export const agreementStatuses = [
    {
        label: "AGR. NOT SENT",
        bg: "#D2E9E6",
        color: "secondary",
        value: "agreement_not_sent",
    },
    {
        label: "AGR. SENT",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "agreement_sent",
    },
    {
        label: "AGR. SIGNED",
        bg: "#C6E6FD",
        color: "#04375C",
        value: "agreement_completed",
    },
    {
        label: "AGR. VOIDED",
        bg: "#E0605A",
        color: "white",
        value: "agreement_voided",
    },
    {
        label: "AGR. DECLINED",
        bg: "#E0605A",
        color: "white",
        value: "agreement_declined",
    },
]

export const amhStatuses = [
    {
        label: "AMH NOT STARTED",
        bg: "#E6E6FA",
        color: "black",
        value: "amh_not_started"
    },
    {
        label: "LAB ORDER SENT",
        bg: "#DDA0DD",
        color: "black",
        value: "amh_lab_order_sent"
    },
    {
        label: "TEST SCHEDULED",
        bg: "#D8BFD8",
        color: "black",
        value: "amh_test_scheduled"
    },
    {
        label: "RESULTS PENDING",
        bg: "#EE82EE",
        color: "black",
        value: "amh_results_pending"
    },
    {
        label: "PASSED F&F (>3.0)",
        bg: "#800080",
        color: "white",
        value: "amh_test_passed_frf"
    },
    {
        label: "PASSED FRSH (2.0-3.0)",
        bg: "#662d91",
        color: "white",
        value: "amh_test_passed_fr"
    },
    {
        label: "FAILED (<2.0)",
        bg: "#E0605A",
        color: "white",
        value: "amh_test_failed"
    },
]

export const migrationStatuses = [
    {
        label: "ACTIVATION REQUIRED",
        bg: "#D2E9E6",
        color: "secondary",
        value: "activation_required",
    },
    {
        label: "ACTIVATION SENT",
        bg: "yellow",
        color: "secondary",
        value: "activation_sent",
    },
    {
        label: "QUESTIONS NEEDED",
        bg: "#D2FC99",
        color: "black",
        value: "questions_required",
    },
    {
        label: "MIGRATION COMPLETE",
        bg: "#60D86A",
        color: "black",
        value: "activation_complete",
    },
]

export const scStatuses = [
    {
        label: "FZ NOT REVIEWED",
        bg: "#bcf5f9",
        color: "#04375C",
        value: "not_yet_reviewed_for_frozen",
    },
    {
        label: "FZ PENDING REVIEW",
        bg: "#89c5fd",
        color: "#04375C",
        value: "pending_review_for_frozen",
    },
    {
        label: "FZ SELECTED",
        bg: "#3a80ec",
        color: "#04375C",
        value: "frozen_selected",
    },
    {
        label: "FZ SIGNED",
        bg: "#0229bf",
        color: "white",
        value: "frozen_signed",
    },
    {
        label: "FZ SCREENING",
        bg: "#080b6c",
        color: "white",
        value: "frozen_screening",
    },
    {
        label: "FZ CYCLING",
        bg: "#006666",
        color: "white",
        value: "frozen_cycling",
    },
    {
        label: "FZ CYCLE COMPLETE",
        bg: "#D2FC99",
        color: "#04375C",
        value: "frozen_cycle_complete",
    },
    {
        label: "NO FZ, FS",
        bg: "#E0605A",
        color: "#04375C",
        value: "not_a_candidate_for_frozen",
    },
]

export const ipStatuses = [
    {
        label: "INTAKE INCOMPLETE", 
        bg: "rgb(251 191 36)",
        color: "black",
        value: "intake_incomplete"
    },
    {
        label: "UNMATCHED", 
        bg: "rgb(56 189 248)",
        color: "black",
        value: "unmatched"
    },
    // {
    //     label: "CALL PENDING", 
    //     bg: "rgb(192 132 252)",
    //     color: "white",
    //     value: "call_pending"
    // },
    // {
    //     label: "CALL SCHEDULED", 
    //     bg: "rgb(244 114 182)",
    //     color: "black",
    //     value: "call_scheduled"
    // },
    // {
    //     label: "CALL NO SHOW", 
    //     bg: "rgb(225 29 72)",
    //     color: "white",
    //     value: "call_no_show"
    // },
    // {
    //     label: "CONFIRM PREFERENCES", 
    //     bg: "rgb(134 239 172)",
    //     color: "black",
    //     value: "confirm_preferences"
    // },
    // {
    //     label: "SIGN AGREEMENT", 
    //     bg: "rgb(163 230 53)",
    //     color: "black",
    //     value: "sign_family_agreement"
    // },
    // {
    //     label: "CONFIRM MATCH", 
    //     bg: "rgb(20 184 166)",
    //     color: "white",
    //     value: "confirm_match"
    // },
    // {
    //     label: "MATCH FS", 
    //     bg: "rgb(132 204 22)",
    //     color: "black",
    //     value: "matched_fresh"
    // },
    // {
    //     label: "MATCH FZ", 
    //     bg: "rgb(3 105 161)",
    //     color: "white",
    //     value: "matched_frozen"
    // },
    {
        label: "MATCHED", 
        bg: "rgb(132 204 22)",
        color: "black",
        value: "matched"
    },
]

export const matchStatuses = [
    {
        label: "ACTIVE", 
        bg: "rgb(132 204 22)",
        color: "black",
        value: "active"
    },
    {
        label: "CANCELLED", 
        bg: "rgb(220 38 38)",
        color: "black",
        value: "cancelled"
    },
]

export const matchStages = [
    {
        label: "STAGE 1",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_1",
    },
    {
        label: "STAGE 2",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_2",
    },
    {
        label: "STAGE 3",
        bg: "#D2E9E6",
        color: "secondary",
        value: "stage_3",
    },
    {
        label: "STAGE 4",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "stage_4",
    },
    {
        label: "STAGE 5",
        bg: "#CACD9D",
        color: "red",
        value: "stage_5",
    },
    {
        label: "STAGE 6",
        bg: "#CACD9D",
        color: "#3B3F06",
        value: "stage_6",
    },
    {
        label: "STAGE 7",
        bg: "#C6E6FD",
        color: "#04375C",
        value: "stage_7",
    },
    {
        label: "STAGE 8",
        bg: "#F67EDF",
        color: "white",
        value: "stage_8",
    },
    {
        label: "STAGE 9",
        bg: "#D2FC99",
        color: "black",
        value: "stage_9",
    },
    {
        label: "STAGE 10",
        bg: "#D2FC99",
        color: "black",
        value: "stage_10",
    },
    {
        label: "CANCELLED",
        bg: "#E0605A",
        color: "white",
        value: "cancelled",
    }
]

export const paymentStatuses = [
    {
        label: "DEPOSIT", 
        bg: "rgb(132 204 22)",
        color: "black",
        value: "deposit"
    },
    {
        label: "REFUND", 
        bg: "rgb(220 38 38)",
        color: "black",
        value: "refund"
    },
]