import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Timeline = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Cofertility | Timeline";
    }, []);

    // TODO: update to return to previous destination
    const backToApplication = async () => {
        navigate("/freeze/application");
    };

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/freeze/logo.svg`} />;

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col bg-white-200 bg-cover bg-fixed bg-top bg-freeze-guide`}>
            <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center justify-between">{logo}</header>
            <div id="app" className="pb-10 freeze">
                <div className="px-6 grow flex flex-col">
                    <div id="welcome" className="container mx-auto my-auto">
                        <article id="container" className="text-black mb-20">
                            <div className="max-w-container mx-auto mt-2">
                                <h1 className="display">
                                    Let's start your <span className="px-1 -mx-1 heading-underline">journey.</span>
                                </h1>
                                <ol id="stepper">
                                    <li className="active">
                                        <ul>
                                            <li>
                                                <h3>Submit your application</h3>
                                            </li>
                                            <li className="text-sm">Determine if you are medically eligible for the Split program per FDA and ASRM guidelines.</li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                30 min
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="subtask">
                                        <ul>
                                            <li className="italic text-sm">Application review</li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                3-5 days
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <h3>Split program call</h3>
                                            </li>
                                            <li className="text-sm">Meet 1:1 with a member of our team via Zoom and get an overview of what to expect next.</li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                30 mins
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <h3>Sign your Split agreement</h3>
                                            </li>
                                            <li className="text-sm">Confirm your commitment to the program.</li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                1 min
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <h3>AMH screening</h3>
                                            </li>
                                            <li className="text-sm">
                                                Complete your free AMH test at a lab local to you. This hormone test provides insight into your ovarian reserve so we can ensure you are a good
                                                candidate for egg donation.
                                            </li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                1-2 weeks
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <h3>Finalize your profile</h3>
                                            </li>
                                            <li className="text-sm">Submit photos and answer questions about your personality and motivations to let your true self shine.</li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                10-20 mins
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <h3>Matching & retrieval</h3>
                                            </li>
                                            <li className="text-sm">
                                                Match with intended parents, complete your screening and egg freezing cycle, and move forward knowing you’ve got options on ice. 🧊
                                            </li>
                                            <li className="flex gap-2 items-center text-xs duration">
                                                <span className="h-4 w-4">
                                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.25 12.75C21.25 17.4444 17.4444 21.25 12.75 21.25C8.05558 21.25 4.25 17.4444 4.25 12.75C4.25 8.05558 8.05558 4.25 12.75 4.25C17.4444 4.25 21.25 8.05558 21.25 12.75Z"
                                                            fill="#191919"
                                                            stroke="#191919"
                                                        ></path>
                                                        <path
                                                            d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                                                            fill="#ECEDED"
                                                            stroke="#191919"
                                                        ></path>
                                                        <rect x="11.25" y="6.75" width="0.75" height="6" rx="0.375" fill="#191919"></rect>
                                                        <rect x="16.8212" y="15" width="0.75" height="6" rx="0.375" transform="rotate(120 16.8212 15)" fill="#191919"></rect>
                                                    </svg>
                                                </span>
                                                Varies (2 - 4 months from your screening start date)
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </article>

                        <footer className="z-1 transition-all duration-300 fixed flex lg:justify-normal lg:grid items-right text-black bg-tertiary-light w-full left-0 bottom-0 h-20 px-6 sm:px-10 border-t-3 border-black justify-end gap-x-6 lg:gap-x-0">
                            <div className="justify-self-end ml-auto lg:ml-0 mt-3">
                                <div onClick={backToApplication} className="lg:h-auto lg:!w-auto button-primary !no-underline gap-3 cursor-pointer">
                                    <span className="whitespace-nowrap">Application</span>
                                    <span className="h-7 w-7">
                                        <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timeline;
