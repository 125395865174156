import React, { useState } from "react";
import SplitMembers from "./SplitMembers";
import { useNavigate } from "react-router-dom";
import EditRequests from "./EditRequests";
import CheckIns from "./CheckIns";
import PhotoRequests from "./PhotoRequests";

const SplitMembersPage = ({ tab }) => {
    const navigate = useNavigate();
    const [splitMemberCount, setSplitMemberCount] = useState(0);

    const handleSplitMemberCount = (newSplitMemberCount) => {
        setSplitMemberCount(newSplitMemberCount)
    }

    return (
        <div className="">
            <div className="divide-y divide-solid bg-white w-full" style={{ position: "fixed", top: 0, zIndex: 10 }}>
                <div className="flex flex-row w-1/2 pt-5 ml-10">
                    <div
                        onClick={() => navigate("/staff/split-members")}
                        className={`${tab === "splitMembers" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        SPLIT MEMBERS ({splitMemberCount})
                    </div>
                    <div
                        onClick={() => navigate("/staff/split-members/edit-requests")}
                        className={`${tab === "editRequests" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        PROFILE EDIT REQUESTS
                    </div>
                    <div
                        onClick={() => navigate("/staff/split-members/photo-requests")}
                        className={`${tab === "photoRequests" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        PROFILE PHOTO REQUESTS
                    </div>
                    {/* <div onClick={() => navigate('/staff/split-members/check-ins')} className={`${tab === 'checkIns' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}>CHECK-INS</div> */}
                </div>

                <div></div>
            </div>

            <div className="mt-20">
                {tab === "splitMembers" ? <SplitMembers handleSplitMemberCount={handleSplitMemberCount} /> : null}

                {tab === "editRequests" ? <EditRequests /> : null}

                {tab === "photoRequests" ? <PhotoRequests /> : null}

                {tab === "checkIns" ? <CheckIns /> : null}
            </div>
        </div>
    );
};

export default SplitMembersPage;
