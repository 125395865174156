import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const StaffErrorBoundary = ({ error = null, message = "Oops something went wrong!", options }) => {
    const [profileMenu, setProfileMenu] = useState(false);
    const { logout } = useAuth0();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/staff/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    let sidebarLogo = (
        <div className="flex-shrink-0 flex items-center pl-10">
            <img className="child-left block xl:hidden" src={`${process.env.PUBLIC_URL}/images/logo_c.svg`} alt="" width="24px" height="265" />
            <img className="child-left hidden xl:block h-8 w-auto " src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} alt="" width="257" height="50" />
        </div>
    );

    const updatePathAndNavigate = (newPath) => {
        // Get the current URL object
        const currentUrl = new URL(window.location.href);

        // Update the pathname of the URL
        currentUrl.pathname = newPath;

        // Navigate to the updated URL in the same tab
        window.location.href = currentUrl.href;
    };

    if (!error) {
        return null;
    }

    return (
        <div className="flex">
            <div className="w-1/6 flex flex-col">
                <header x-data="{open: false}" className="h-20 z-10">
                    <nav className="bg-black fixed w-1/6 h-full">
                        <div className="container max-w-outer">
                            <div className="flex h-20" style={{ alignItems: "center", justifyContent: "space-between" }}>
                                {sidebarLogo}

                                <div ref={menuRef} className=" child-right flex">
                                    <div className="relative max-h-10.5">
                                        <button
                                            onClick={toggleProfileMenu}
                                            type="button"
                                            className="header-menu-button"
                                            aria-haspopup="true"
                                            aria-labelledby="alpine-menu-label-1"
                                            id="alpine-menu-button-1"
                                            aria-expanded={profileMenu}
                                        >
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="hidden xl:block h-10.5 w-10.5 rounded-full"
                                                src="https://images.unsplash.com/photo-1601814933824-fd0b574dd592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80"
                                                alt="Profile"
                                            />
                                        </button>

                                        <div
                                            className="origin-top-right absolute right-0 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                            aria-orientation="vertical"
                                            role="menu"
                                            id="alpine-menu-items-1"
                                            aria-labelledby="alpine-menu-button-1"
                                            tabIndex="0"
                                            style={profileMenu ? {} : { display: "none" }}
                                        >
                                            <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                                <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    onClick={() => updatePathAndNavigate("/staff/split-members")}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    SPLIT MEMBERS
                                </div>
                                <div
                                    onClick={() => updatePathAndNavigate("/staff/intended-parents")}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    INTENDED PARENTS
                                </div>
                                <div
                                    onClick={() => updatePathAndNavigate("/staff/matches")}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    MATCHES
                                </div>
                                {/* <div
                                    onClick={() => updatePathAndNavigate("/staff/clinics")}
                                    className={`${
                                        options.page === "clinics" || options.page === "clinic" ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    CLINICS
                                </div> */}
                                <div
                                    onClick={() => updatePathAndNavigate("/family/donors")}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    ACTIVE DONOR PLATFORM
                                </div>
                                <div
                                    onClick={() => updatePathAndNavigate("/staff/account")}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    MY ACCOUNT
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>

            <div id="staff-content" className="w-5/6 h-screen flex items-center justify-center">
                {message}
            </div>
        </div>
    );
};

export default StaffErrorBoundary;
