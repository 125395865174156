import React from "react";

export const SavedSearches = ({
    searchRef,
    filters,
    searches,
    selectedSearch,
    setSelectedSearch,
    clearSelectedSearch,
    setSavedSearchModal,
    searchDropdownOpen,
    setSearchDropdownOpen,
    haveFilters,
}) => {
    console.log(selectedSearch);
    if (selectedSearch !== null) {
        for (const key in selectedSearch.criteria) {
            if (key === "height") {
                filters[key]((prev) => prev.push(...selectedSearch.criteria[key]));
            }
            filters[key](selectedSearch.criteria[key]);
        }
    }
    return (
        <div key={"savedSearches"}>
            <div className="flex my-2 justify-between">
                <div className="flex items-center">
                    <h4 className="p-1 my-1">Saved searches</h4>
                    <img className="cursor-pointer" alt="i" width={20} src={`${process.env.PUBLIC_URL}/images/tooltip.svg`} />
                </div>
                {haveFilters ? (
                    <button
                        className="bg-primary hover:bg-secondary hover:text-primary font-normal text-secondary px-2 rounded-lg border border-secondary hover:border-primary"
                        onClick={() => setSavedSearchModal(true)}
                    >
                        Save current search
                    </button>
                ) : null}
            </div>

            <div className="relative p-1" ref={searchRef}>
                <button
                    onClick={() => setSearchDropdownOpen(!searchDropdownOpen)}
                    className="w-full py-2 px-2 rounded-md border bg-white text-gray-700 border-solid border-black flex justify-between items-center"
                >
                    {selectedSearch ? <span>{selectedSearch.name}</span> : <span>Select a search</span>}
                    {selectedSearch ? (
                        <svg onClick={clearSelectedSearch} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                        </svg>
                    )}
                </button>

                {searchDropdownOpen && searches.length ? (
                    <div className="absolute mt-1 ml-1 w-[95%] py-2 left-0 right-0 bg-white rounded-md shadow-lg" style={{ zIndex: 1000 }}>
                        {searches.length
                            ? searches.map((option) => (
                                  <div
                                      key={option.value}
                                      onClick={() => {
                                          setSelectedSearch(option);
                                          setSearchDropdownOpen(false);
                                      }}
                                      className={`w-[90%] px-2 py-1 rounded-md font-medium text-xs my-2 mx-auto ${
                                          selectedSearch && selectedSearch.name === option.name ? "border-2 border-solid border-black" : ""
                                      }`}
                                  >
                                      {option.name}
                                  </div>
                              ))
                            : null}
                    </div>
                ) : null}
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
