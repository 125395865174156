import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const GoogleLoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                operation: "login",
            },
            authorizationParams: {
                prompt: "login",
                connection: "google-oauth2",
            },
        });
    };

    return (
        <div>
            <div onClick={handleLogin} className="button-google !no-underline cursor-pointer">
                <div className="flex flex-row gap-4 font-normal items-center text-lg whitespace-nowrap">
                    <svg className="w-6 h-6" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_2414_35332" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
                            <path
                                d="M22.75 10H12.5V14.25H18.4C17.85 16.95 15.55 18.5 12.5 18.5C8.9 18.5 6 15.6 6 12C6 8.4 8.9 5.5 12.5 5.5C14.05 5.5 15.45 6.05 16.55 6.95L19.75 3.75C17.8 2.05 15.3 1 12.5 1C6.4 1 1.5 5.9 1.5 12C1.5 18.1 6.4 23 12.5 23C18 23 23 19 23 12C23 11.35 22.9 10.65 22.75 10Z"
                                fill="white"
                            ></path>
                        </mask>
                        <g mask="url(#mask0_2414_35332)">
                            <path d="M0.5 18.5V5.5L9 12L0.5 18.5Z" fill="#FBBC05"></path>
                        </g>
                        <mask id="mask1_2414_35332" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
                            <path
                                d="M22.75 10H12.5V14.25H18.4C17.85 16.95 15.55 18.5 12.5 18.5C8.9 18.5 6 15.6 6 12C6 8.4 8.9 5.5 12.5 5.5C14.05 5.5 15.45 6.05 16.55 6.95L19.75 3.75C17.8 2.05 15.3 1 12.5 1C6.4 1 1.5 5.9 1.5 12C1.5 18.1 6.4 23 12.5 23C18 23 23 19 23 12C23 11.35 22.9 10.65 22.75 10Z"
                                fill="white"
                            ></path>
                        </mask>
                        <g mask="url(#mask1_2414_35332)">
                            <path d="M0.5 5.5L9 12L12.5 8.95L24.5 7V0H0.5V5.5Z" fill="#EA4335"></path>
                        </g>
                        <mask id="mask2_2414_35332" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
                            <path
                                d="M22.75 10H12.5V14.25H18.4C17.85 16.95 15.55 18.5 12.5 18.5C8.9 18.5 6 15.6 6 12C6 8.4 8.9 5.5 12.5 5.5C14.05 5.5 15.45 6.05 16.55 6.95L19.75 3.75C17.8 2.05 15.3 1 12.5 1C6.4 1 1.5 5.9 1.5 12C1.5 18.1 6.4 23 12.5 23C18 23 23 19 23 12C23 11.35 22.9 10.65 22.75 10Z"
                                fill="white"
                            ></path>
                        </mask>
                        <g mask="url(#mask2_2414_35332)">
                            <path d="M0.5 18.5L15.5 7L19.45 7.5L24.5 0V24H0.5V18.5Z" fill="#34A853"></path>
                        </g>
                        <mask id="mask3_2414_35332" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
                            <path
                                d="M22.75 10H12.5V14.25H18.4C17.85 16.95 15.55 18.5 12.5 18.5C8.9 18.5 6 15.6 6 12C6 8.4 8.9 5.5 12.5 5.5C14.05 5.5 15.45 6.05 16.55 6.95L19.75 3.75C17.8 2.05 15.3 1 12.5 1C6.4 1 1.5 5.9 1.5 12C1.5 18.1 6.4 23 12.5 23C18 23 23 19 23 12C23 11.35 22.9 10.65 22.75 10Z"
                                fill="white"
                            ></path>
                        </mask>
                        <g mask="url(#mask3_2414_35332)">
                            <path d="M24.5 24L9 12L7 10.5L24.5 5.5V24Z" fill="#4285F4"></path>
                        </g>
                    </svg>
                    Log in with Google
                </div>
            </div>
        </div>
    );
};
