import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateFilter = ({ title, name, placeholder, dateRange, setDateRange, handleDateFilter }) => {
    return (
        <div key={name} className="!text-[#191919]">
            <h4 className="p-1 my-1">{title}</h4>
            <div className="p-1 w-3/4">
                <DatePicker
                    selected={dateRange[0]}
                    className="border border-solid border-black rounded-lg p-2 pr-10"
                    popperPlacement="bottom-end"
                    popperModifiers={{
                        offset: ({ reference, popper }) => {
                            const referenceRect = reference.getBoundingClientRect();
                            const popperRect = popper.getBoundingClientRect();
                            const offsetX = referenceRect.width / 4 - popperRect.width / 2;

                            return [offsetX, 5];
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "viewport",
                        },
                    }}
                    onChange={(update) => {
                        handleDateFilter(name, update, setDateRange);
                    }}
                    selectsRange
                    isClearable
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    placeholderText={placeholder}
                />
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
