import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";

const SMAdminChecklist = ({ user }) => {
    const [refetchChecklist, setRefetchChecklist] = useState(0);
    const [checklist, setChecklist] = useState(null);
    const [persForm, setPersForm] = useState(null);
    const [activePhase, setActivePhase] = useState("");
    const [viewedPhase, setViewedPhase] = useState("");
    const [appSubmittedModal, setAppSubmittedModal] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChecklist = async () => {
            try {
                let response = await request({
                    url: `/staff/split-members/${user.id}/checklist`,
                    method: "GET",
                });

                const { user_checklist } = response;

                setChecklist(user_checklist);

                outerloop: for (let i = 0; i < user_checklist.checklist_phases.length; i++) {
                    const phase = user_checklist.checklist_phases[i];
                    for (let j = 0; j < phase.checklist_tasks.length; j++) {
                        const task = phase.checklist_tasks[j];
                        for (let k = 0; k < task.subtasks.length; k++) {
                            if (task.subtasks[k].user_subtask.status === "active") {
                                setActivePhase(phase.name);
                                setViewedPhase(phase.name);
                                break outerloop;
                            }
                        }
                    }
                }
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        const fetchForm = async () => {
            try {
                const response = await request({
                    url: `/split-members/${user.id}/form/freeze-personality-motivations`,
                    method: "GET",
                });

                const { form } = response;
                setPersForm(form);
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchChecklist();
        fetchForm();
    }, [request, user, refetchChecklist]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const toggleViewedPhase = (phaseName) => {
        if (viewedPhase === phaseName) {
            setViewedPhase("");
        } else {
            setViewedPhase(phaseName);
        }
    };

    const navigateToPhotos = () => {
        navigate("/freeze/profile/photos/upload");
    };

    const closeAppSubmittedModal = () => {
        setAppSubmittedModal(false);
    };

    const closeActiveModal = () => {
        setActiveModal(false);
        localStorage.setItem("activeModalSeen", true);
    };

    const determineDisplayedTask = (task) => {
        let data = {
            totalSubtasks: 0,
            reqSubtasks: 0,
            completeReq: 0,
            blockingTask: null,
            activeTask: null,
            inactiveTask: null,
            lastComplete: null,
        };

        let subtask = task.subtasks[0];
        let taskComplete = false;

        task.subtasks.forEach((sub) => {
            data.totalSubtasks++;
            if (sub.required) data.reqSubtasks++;
            if (sub.user_subtask.status === "blocker" && data.blockingTask === null) data.blockingTask = sub;
            if (sub.user_subtask.status === "active" && data.activeTask === null) data.activeTask = sub;
            if (sub.user_subtask.status === "finished") {
                data.completeReq++;
                data.lastComplete = sub;
            }
            if (sub.user_subtask.status === "inactive" && data.inactiveTask === null) data.inactiveTask = sub;
        });

        if (data.blockingTask !== null) {
            subtask = data.blockingTask;
        } else if (data.activeTask !== null) {
            subtask = data.activeTask;
        } else if (data.reqSubtasks !== 0 && data.reqSubtasks === data.completeReq) {
            taskComplete = true;
            subtask = data.lastComplete;
        } else {
            subtask = data.inactiveTask;
        }

        return (
            <div
                key={task.id}
                className={`rounded-lg bg-white border-2 border-black px-6 pt-8 pb-4 mb-4 ${subtask.user_subtask.status === "inactive" ? "opacity-50" : ""} ${taskComplete ? "bg-opacity-0" : ""}`}
            >
                <div className="md:flex flex-col gap-4">
                    <div>
                        <div className="flex">
                            {taskComplete ? (
                                <img className="max-w-none h-10" src={`${process.env.PUBLIC_URL}/images/task_check.svg`} alt="Complete your application" width="40" height="40" />
                            ) : (
                                <img className="max-w-none h-10" src={`${process.env.PUBLIC_URL}/images/${task.icon}.svg`} alt="Complete your application" width="40" height="40" />
                            )}
                            <div className="ml-3">
                                <h3 className="">{task.name}</h3>
                                <p className="mb-4">{subtask.description}</p>
                            </div>
                        </div>
                        <div className="flex items-center ml-[50px]">
                            {!taskComplete ? (
                                subtask.badge_override ? (
                                    <div className="bg-tertiary-light border border-black rounded px-3">
                                        <span className="uppercase text-xs leading-6 w-full text-nowrap">{subtask.badge_override}</span>
                                    </div>
                                ) : null
                            ) : null}

                            <div className="flex justify-end items-center w-full">
                                {!taskComplete ? (
                                    subtask.cta_button ? (
                                        <div className={`flex justify-center bg-primary border-2 border-secondary rounded-full px-3 py-2 w-full md:w-max mt-2 cursor-not-allowed"`}>
                                            <button className={`mr-2 text-secondary font-medium px-2 cursor-not-allowed`}>{subtask.cta_text}</button>
                                            <img className="max-w-none h-8" src={`${process.env.PUBLIC_URL}/images/next_arrow.svg`} alt="Complete your application" width="30" height="30" />
                                        </div>
                                    ) : subtask.cta_text ? (
                                        <div className="italic">{subtask.cta_text}</div>
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <span className="block md:hidden italic font-normal mt-6"></span>
            </div>
        );
    };

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top "
        >
            <div id="app" className="pb-10 freeze">
                <div className="relative w-full z-1 bg-freeze-primary mb-12 md:mb-22">
                    <header className="bg-freeze-primary px-6 sm:px-10 z-0">
                        <div className="flex flex-col gap-1 max-w-container mx-auto mt-8 ">
                            <div className="bg-primary">
                                <div className="max-w-container mx-auto flex flex-col gap-4">
                                    <div className="flex flex-col items-center">
                                        <img src={`${process.env.PUBLIC_URL}/images/split_dashboard_icon.svg`} alt="Split dashboard" />
                                        <h1 className="mb-7 mt-2 text-4xxl">Welcome, {user.firstName}!</h1>
                                        <div className="flex flex-wrap md:flex-nowrap justify-center">
                                            {checklist &&
                                                checklist.checklist_phases.map((phase, index) => (
                                                    <span key={phase.id} className={`pizza-tracker-item ${activePhase === phase.name ? "active" : ""}`}>
                                                        <span className={`pizza-tracker-order ${activePhase === phase.name ? "active" : ""}`}>{index + 1}</span>
                                                        <span className="pizza-tracker-title">{phase.name}</span>
                                                        <span className="text-xs font-light">{phase.timeline}</span>
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 w-full -z-1">
                            <svg viewBox="0 0 1440 49" fill="none" className="fill-freeze-primary w-full" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2131 -301.5C2131 -107.924 1498.83 49.0004 719.004 49.0004C-60.8219 49.0004 -692.996 -107.924 -692.996 -301.5C-692.996 -318.767 -693 -351 -693 -351C-693 -351 -33.619 -351 719.004 -351C1446.7 -351 2131 -351 2131 -351C2131 -351 2131 -319.113 2131 -301.5Z"
                                    fill="#D2FC99"
                                ></path>
                            </svg>
                        </div>
                    </header>
                </div>

                {appSubmittedModal ? (
                    <div
                        className="modal"
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <div
                            className="modal-content w-full max-w-3xl"
                            style={{
                                backgroundColor: "white",
                                padding: "40px",
                                borderRadius: "5px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            <div className="flex justify-end">
                                <span onClick={closeAppSubmittedModal} className="cursor-pointer">
                                    X
                                </span>
                            </div>
                            <h1 className="mb-4 flex flex-col">
                                <div className="mx-auto">Thanks!</div>
                                <div className="mx-auto">Your profile is under review.</div>
                            </h1>
                            <div className="mx-auto p-4 w-3/4 mb-5">This can take up to 7 days. Your member advocate will be reaching out soon for next steps.</div>
                            <div onClick={closeAppSubmittedModal} className="flex justify-center mx-auto bg-primary border-2 border-secondary rounded-full py-2 w-1/2 mt-2 cursor-pointer">
                                <button className="text-secondary font-medium px-2">Got it!</button>
                                <img className="max-w-none h-8" src={`${process.env.PUBLIC_URL}/images/next_arrow.svg`} alt="Complete your application" width="30" height="30" />
                            </div>
                        </div>
                    </div>
                ) : null}

                {activeModal ? (
                    <div
                        className="modal"
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <div
                            className="modal-content w-full max-w-3xl"
                            style={{
                                backgroundColor: "white",
                                padding: "40px",
                                borderRadius: "5px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            <h1 className="mb-4 flex flex-col">
                                <div className="mx-auto">Congrats!</div>
                            </h1>
                            <div className="mx-auto p-4 w-3/4 mb-5">
                                Your profile is live! Once we have a family interested in matching, we'll reach out to you to confirm your availability for next steps.
                            </div>
                            <div onClick={closeActiveModal} className="flex justify-center mx-auto bg-primary border-2 border-secondary rounded-full py-2 w-1/2 mt-2 cursor-pointer">
                                <button className="text-secondary font-medium px-2">Continue</button>
                                <img className="max-w-none h-8" src={`${process.env.PUBLIC_URL}/images/next_arrow.svg`} alt="Complete your application" width="30" height="30" />
                            </div>
                        </div>
                    </div>
                ) : null}

                <section className="mx-6 md:mx-0 md:mb-24">
                    <div className="max-w-content mx-auto flex flex-col gap-4">
                        <div className="flex flex-col gap-4 relative">
                            {checklist &&
                                checklist.checklist_phases.map((phase, index) => {
                                    return (
                                        <div key={phase.id} className="border-b mt-4 !border-black pb-8 pt-4">
                                            <h2 className="mb-0 px-6 -mx-6 flex items-center">
                                                <span className={`pizza-tracker-order bg-transparent basis-10 font-body font-normal mr-4 ${phase.name === activePhase ? "active" : ""}`}>
                                                    {index + 1}
                                                </span>
                                                <div
                                                    onClick={() => toggleViewedPhase(phase.name)}
                                                    className="hover:text-secondary flex w-full items-center justify-between text-left !no-underline cursor-pointer flex-1 !font-normal"
                                                    aria-expanded={phase.name === viewedPhase}
                                                >
                                                    {phase.step_title}
                                                    <span className={`w-8 h-8 ml-4 transition-transform flex-shrink-0 ${phase.name === viewedPhase ? "-rotate-180" : ""}`}>
                                                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </h2>
                                            <div style={phase.name === viewedPhase ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                                <div className="mt-7 !mb-0">
                                                    <div className="mb-8"></div>
                                                    <div>{phase.checklist_tasks.map((task) => determineDisplayedTask(task))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SMAdminChecklist;
