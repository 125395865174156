import React, { useState } from "react";
import Favorites from "./Favorites";
import Views from "./Views";

const Activity = ({ tab, user }) => {
    const [currentTab, setCurrentTab] = useState(tab);

    const changeTab = (tabName) => {
        setCurrentTab(tabName);
        window.history.replaceState(null, "", `/staff/split-members/${user.id}/activity/${tabName}`);
    };

    return (
        <div className="pb-5">
            <div className={`bg-white w-full pb-5`}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <div
                        onClick={() => changeTab(`favorites`)}
                        className={`${currentTab === "favorites" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        FAVORITES
                    </div>
                    <div
                        onClick={() => changeTab(`views`)}
                        className={`${currentTab === "views" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        VIEWS
                    </div>
                </div>
            </div>

            <div>
                {currentTab === "favorites" ? <Favorites user={user} /> : null}

                {currentTab === "views" ? <Views user={user} /> : null}
            </div>
        </div>
    );
};

export default Activity;
