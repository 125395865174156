import React from "react";

export const MinMaxFilter = ({ title, isText, filters, nameMin, nameMax, setMinMax, clearState }) => {
    return (
        <div>
            <h4 className="p-1 my-1 !text-[#191919]">{title}</h4>
            <div className="flex items-center">
                <div
                    className={`flex justify-between p-2 w-1/4 bg-white rounded-lg border border-black`}
                    onFocus={(e) => {
                        e.currentTarget.classList.add("ring-2", "ring-blue-500");
                        e.currentTarget.classList.remove("border", "border-black");
                    }}
                    onBlur={(e) => {
                        e.currentTarget.classList.remove("ring-2", "ring-blue-500");
                        e.currentTarget.classList.add("border", "border-black");
                    }}
                >
                    <input
                        type={isText ? "text" : "number"}
                        placeholder="Min"
                        value={filters.filter((f) => f.name === nameMin).length ? filters.filter((f) => f.name === nameMin)[0].value : ""}
                        onChange={(event) => setMinMax(nameMin, event)}
                        className="focus:outline-none focus:ring-0 w-3/4 !text-[#191919]"
                    />
                    <button onClick={() => clearState(nameMin)} className="bg-none cursor-pointer -translate-x-1/2 !text-[#191919]">
                        x
                    </button>
                </div>
                <span className="mx-3 !text-[#191919]">-</span>
                <div
                    className={`flex justify-between p-2 w-1/4 bg-white rounded-lg border border-black`}
                    onFocus={(e) => {
                        e.currentTarget.classList.add("ring-2", "ring-blue-500");
                        e.currentTarget.classList.remove("border", "border-black");
                    }}
                    onBlur={(e) => {
                        e.currentTarget.classList.remove("ring-2", "ring-blue-500");
                        e.currentTarget.classList.add("border", "border-black");
                    }}
                >
                    <input
                        type={isText ? "text" : "number"}
                        placeholder="Max"
                        value={filters.filter((f) => f.name === nameMax).length ? filters.filter((f) => f.name === nameMax)[0].value : ""}
                        onChange={(event) => setMinMax(nameMax, event)}
                        className="focus:outline-none focus:ring-0 w-3/4 !text-[#191919]"
                    />
                    <button onClick={() => clearState(nameMax)} className="bg-none cursor-pointer -translate-x-1/2 !text-[#191919]">
                        x
                    </button>
                </div>
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
