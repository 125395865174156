import UploadGallery from "./UploadGallery";
import { useParams } from "react-router-dom";

const PhotoViewer = ({ staff = false }) => {
    const params = useParams();

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top"
        >
            <UploadGallery user={{ id: params.userId }} userType={"split-members"} returnButton={true} />
        </div>
    );
};

export default PhotoViewer;
