import React, { useState } from "react";
import DatePicker from "react-datepicker";

const ClinicStaff = ({ staff }) => {
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [joinDateRange, setJoinDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("last_login");
    const [orderDir, setOrderDir] = useState("desc");
    const [hasError, setHasError] = useState(null);

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleNameSearch = (event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "name");

        if (search.length) {
            filteredFilters.push({
                name: "name",
                value: search,
            });

            window.history.replaceState(null, "", `${window.location.pathname.includes("?") ? `${window.location.pathname}&name=${search}` : `${window.location.pathname}?name=${search}`}`);
        } else {
            window.history.replaceState(null, "", window.location.pathname.replace("?name=", ""));
            window.history.replaceState(null, "", window.location.pathname.replace("&name=", ""));
        }

        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setFilters(filteredFilters);
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop === "last_name") {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
    };

    return (
        <div className="mx-30 mb-20">
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Join Date</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("last_name")} className="flex items-center">
                                <span className="mr-1">Staff</span>
                                <span className="flex items-center">
                                    {orderBy === "last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Credentials</span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Is Doctor</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {staff &&
                        staff.map((member) => {
                            return (
                                <tr key={member.id} className="w-full">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(member.created_at)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {member.first_name.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">
                                                {member.first_name} {member.last_name}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                            {member.credentials}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                            {member.is_doctor ? "Yes" : "No"}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default ClinicStaff;
