import React from "react";

export const FlagsFilter = ({ setFilterArray, flagsFilter, setFlagsFilter }) => {
    return (
        <div>
            <h4 className="p-1 my-1">Flags</h4>
            <div className="p-1">
                <span onClick={() => setFilterArray(flagsFilter, setFlagsFilter, "red")} className="ml-3 mr-5">
                    <div className="w-4 h-4 rotate-45 bg-red-500 inline-block cursor-pointer">
                        {flagsFilter.includes("red") ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check -rotate-45" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>
                        ) : null}
                    </div>
                </span>
                <span onClick={() => setFilterArray(flagsFilter, setFlagsFilter, "orange")} className="mr-5">
                    <div className="w-4 h-4 rotate-45 bg-orange-500 inline-block cursor-pointer">
                        {flagsFilter.includes("orange") ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check -rotate-45" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>
                        ) : null}
                    </div>
                </span>
                <span onClick={() => setFilterArray(flagsFilter, setFlagsFilter, "yellow")} className="mr-5">
                    <div className="w-4 h-4 rotate-45 bg-yellow-500 inline-block cursor-pointer">
                        {flagsFilter.includes("yellow") ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check -rotate-45" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>
                        ) : null}
                    </div>
                </span>
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
