export const FreezeButton = ({ onClick, styles = "", label, arrow = false, active = true }) => {
    return (
        <div
            onClick={onClick}
            className={`flex justify-center bg-freeze-primary hover:bg-freeze-secondary-500 border-2 border-freeze-secondary-500 hover:border-primary text-freeze-secondary-500 hover:text-primary rounded-full px-3 py-2 w-full md:w-max cursor-pointer ${
                !active ? "!cursor-not-allowed" : ""
            } ${styles} group`}
        >
            <button className={`font-medium px-2 ${!active ? "!cursor-not-allowed" : ""}`}>{label}</button>
            {arrow ? (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="max-w-none h-8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M28.5725 15.4322C24.2347 15.4322 20.708 11.9056 20.708 7.56777C20.708 7.25306 20.4549 7 20.1402 7C19.8255 7 19.5725 7.25306 19.5725 7.56777C19.5725 10.9452 21.4445 13.8944 24.2022 15.4322H3.56777C3.25306 15.4322 3 15.6853 3 16C3 16.3147 3.25306 16.5678 3.56777 16.5678H24.2055C21.4445 18.1056 19.5757 21.0548 19.5757 24.4322C19.5757 24.7469 19.8288 25 20.1435 25C20.4582 25 20.7112 24.7469 20.7112 24.4322C20.7112 20.0944 24.2379 16.5678 28.5757 16.5678C28.8904 16.5678 29.1435 16.3147 29.1435 16C29.1435 15.6853 28.8904 15.4322 28.5757 15.4322H28.5725Z"
                        className="fill-family-forest-800 group-hover:fill-primary"
                    />
                </svg>
            ) : null}
        </div>
    );
};

export const FamilyButton = ({ onClick, styles = "", label, arrow = false, active = true }) => {
    return (
        <div
            onClick={onClick}
            className={`flex justify-center bg-family-apricot hover:bg-family-forest-500 border-2 border-family-apricot hover:border-family-forest-500 text-family-forest-800 hover:text-white rounded-full px-3 py-2 w-full md:w-max cursor-pointer ${
                !active ? "!cursor-not-allowed" : ""
            } ${styles} group`}
        >
            <button className={`font-medium px-2 ${!active ? "!cursor-not-allowed" : ""}`}>{label}</button>
            {arrow ? (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className="max-w-none h-8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        className="fill-family-forest-800 group-hover:fill-white"
                        d="M28.5725 15.4322C24.2347 15.4322 20.708 11.9056 20.708 7.56777C20.708 7.25306 20.4549 7 20.1402 7C19.8255 7 19.5725 7.25306 19.5725 7.56777C19.5725 10.9452 21.4445 13.8944 24.2022 15.4322H3.56777C3.25306 15.4322 3 15.6853 3 16C3 16.3147 3.25306 16.5678 3.56777 16.5678H24.2055C21.4445 18.1056 19.5757 21.0548 19.5757 24.4322C19.5757 24.7469 19.8288 25 20.1435 25C20.4582 25 20.7112 24.7469 20.7112 24.4322C20.7112 20.0944 24.2379 16.5678 28.5757 16.5678C28.8904 16.5678 29.1435 16.3147 29.1435 16C29.1435 15.6853 28.8904 15.4322 28.5757 15.4322H28.5725Z"
                    />
                </svg>
            ) : null}
        </div>
    );
};
