import React, { useState, useEffect, useRef, useContext } from "react";
import { DndContext, closestCenter, MouseSensor, TouchSensor, PointerSensor, DragOverlay, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import Cropper from "react-easy-crop";

import { Grid } from "./Grid";
import { SortablePhoto } from "./SortablePhoto";
import { Photo } from "./Photo";
import { AppContext } from "../../../../App";
import { useLocation, useNavigate } from "react-router-dom";

const UploadGallery = ({ userType, user, setRefreshHeader, staff = false, owner = false, returnButton = null }) => {
    const [donorCode, setDonorCode] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [needsReview, setNeedsReview] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [items, setItems] = useState([]);
    const [photoGuidelines, showPhotoGuidelines] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCropModal, setShowCropModal] = useState(false);
    const [showReviewCropModal, setShowReviewCropModal] = useState(false);
    const [imgToDelete, setImgToDelete] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [imgToView, setImgToView] = useState(null);
    const [imgToCrop, setImgToCrop] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [refreshPhotos, setRefreshPhotos] = useState(0);
    const [hasError, setHasError] = useState(null);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropArea, setCropArea] = useState(null);
    const [zoom, setZoom] = useState(1);
    const { loggedInUser, refreshUser } = useContext(AppContext);

    const navigate = useNavigate();
    const location = useLocation();

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );
    const fileInputRef = useRef(null);

    const { request } = useContext(AppContext);

    useEffect(() => {
        if (returnButton && !donorCode && !owner) {
            const fetchDonorCode = async () => {
                try {
                    const response = await request({
                        url: `/split-members/${user.id}/donor-code`,
                        method: "GET",
                    });

                    const { donorCode } = response;
                    setDonorCode(donorCode);
                } catch (err) {
                    console.log(err);
                }
            };

            fetchDonorCode();
        } else if (owner) {
            setDonorCode(loggedInUser.donorCode);
        }
    }, [returnButton, donorCode, setDonorCode, request, user, loggedInUser, owner]);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await request({
                    url: `/${staff ? "staff/" : ""}${userType}/${user.id}/${!owner && !staff ? "public-" : ""}photos`,
                    method: "GET",
                });

                const { photos } = response;
                if (photos) {
                    if (staff) {
                        setNeedsReview(
                            photos
                                .filter((file) => !file.is_approved)
                                .sort((a, b) => {
                                    const dateA = new Date(a.created_at);
                                    const dateB = new Date(b.created_at);
                                    return dateB - dateA;
                                })
                        );
                        let urls = photos
                            .filter((file) => file.is_approved)
                            .sort((a, b) => {
                                return a.position - b.position;
                            })
                            .map((file) => {
                                return file.cropped_source;
                            });
                        setItems(urls);
                        setPhotos(
                            photos
                                .filter((file) => file.is_approved)
                                .sort((a, b) => {
                                    return a.position - b.position;
                                })
                        );
                    } else if (owner) {
                        let urls = photos.map((file) => {
                            return file.cropped_source;
                        });
                        setItems(urls);
                        setPhotos(photos);
                    } else {
                        let urls = photos
                            .filter((file) => file.is_approved)
                            .map((file) => {
                                return file.cropped_source;
                            });
                        setItems(urls);
                        setPhotos(photos);
                    }
                }
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchPhotos();
    }, [request, user, userType, refreshPhotos, staff, owner]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const previews = [];

        if (files.length) {
            setSelectedFiles(files);
            setUploadModal(true);

            // Use Promise.all to wait for all files to be read
            Promise.all(
                files.map((file) => {
                    return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            previews.push(reader.result);
                            resolve(); // Resolve the promise when the file is loaded
                        };
                        reader.readAsDataURL(file);
                    });
                })
            ).then(() => {
                // After all files are loaded, set the previews
                setPreviewUrls(previews);
            });
        } else {
            setSelectedFiles([]);
            setPreviewUrls([]);
            setUploadModal(false);
        }
    };

    const handleUpload = async () => {
        if (!selectedFiles.length) return;

        setUploadInProgress(true);

        const formData = new FormData();
        formData.append("amount", selectedFiles.length);
        selectedFiles.forEach((file, i) => {
            formData.append(`image-${i}`, file);
        });

        try {
            let response = await request({
                url: `/${staff ? "staff/" : ""}${userType}/${user.id}/photos`,
                method: "POST",
                headers: {
                    "content-type": "multipart/form-data",
                },
                data: formData,
            });

            setSelectedFiles([]);
            setPreviewUrls([]);
            setUploadModal(false);
            setUploadInProgress(false);
            setRefreshPhotos((prev) => prev + 1);
        } catch (error) {
            console.error("Upload error:", error);
        }
    };

    const staffDecision = async (photo, decision) => {
        try {
            let response = await request({
                url: `/staff/${userType}/${user.id}/photos/decision`,
                method: "POST",
                data: {
                    photoID: photo.id,
                    decision,
                    crop: cropArea,
                    zoom: zoom,
                    x: crop.x,
                    y: crop.y,
                },
            });

            setRefreshPhotos((prev) => prev + 1);
            setRefreshHeader((prev) => prev + 1);
            setShowReviewCropModal(false);
        } catch (error) {
            console.error("Upload error:", error);
        }
    };

    const handleDragCancel = () => {
        setActiveId(null);
    };

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };

    const handleDragEnd = async (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            let copy = structuredClone(photos);
            copy = arrayMove(copy, oldIndex, newIndex);

            setItems((items) => arrayMove(items, oldIndex, newIndex));
            setPhotos((photos) => arrayMove(photos, oldIndex, newIndex));

            try {
                await request({
                    url: `/${staff ? "staff/" : ""}${userType}/${user.id}/photos/reorder`,
                    method: "PUT",
                    data: {
                        photos: copy,
                        photoID: copy[oldIndex].id,
                    },
                });

                if (setRefreshHeader) {
                    setRefreshHeader((prev) => prev + 1);
                }

                // NEED TO CONDITIONALLY REFRESH USER IF NOT STAFF AND
                // THERE IS NEW POSITION 1 PHOTO ONLY
                // await refreshUser();
            } catch (err) {
                console.log(err);
            }
        }

        setActiveId(null);
    };

    const openDelete = (img) => {
        setImgToDelete(img);
        setShowDeleteModal(true);
    };

    const closeDelete = () => {
        setImgToDelete(null);
        setShowDeleteModal(false);
    };

    const openView = (photo, url) => {
        photo.cropped_source = url;
        setImgToView(photo);
        setShowViewModal(true);
    };

    const closeView = () => {
        setImgToView(null);
        setShowViewModal(false);
    };

    const closeUpload = () => {
        setSelectedFiles([]);
        setPreviewUrls([]);
        setUploadModal(false);
    };

    const openCrop = (img) => {
        if (img.crop_dimensions) {
            setCrop({ x: img.crop_dimensions.x, y: img.crop_dimensions.y });
            setZoom(img.crop_dimensions.zoom);
        }
        setImgToCrop(img);
        setShowCropModal(true);
    };

    const closeCrop = () => {
        setImgToCrop(null);
        setShowCropModal(false);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCropArea(null);
    };

    const openReviewCrop = (img) => {
        if (img.crop_dimensions) {
            setCrop({ x: img.crop_dimensions.x, y: img.crop_dimensions.y });
            setZoom(img.crop_dimensions.zoom);
        }
        setImgToCrop(img);
        setShowReviewCropModal(true);
    };

    const closeReviewCrop = () => {
        setImgToCrop(null);
        setShowReviewCropModal(false);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCropArea(null);
    };

    const confirmDelete = async () => {
        const index = photos.findIndex((photo) => photo.cropped_source === imgToDelete);
        try {
            const response = await request({
                url: `/${staff ? "staff/" : ""}${userType}/${user.id}/photos`,
                method: "DELETE",
                data: {
                    photoID: photos[index].id,
                },
            });

            setRefreshPhotos((prev) => prev + 1);
            if (setRefreshHeader) setRefreshHeader((prev) => prev + 1);
            closeDelete();
        } catch (err) {
            console.log(err);
        }
    };

    const updateCropComplete = (croppedArea, pixels) => {
        setCropArea(pixels);
    };

    const saveCrop = async () => {
        try {
            const response = await request({
                url: `/staff/${userType}/${user.id}/photos/crop`,
                method: "POST",
                data: {
                    photoID: imgToCrop.id,
                    crop: cropArea,
                    zoom: zoom,
                    x: crop.x,
                    y: crop.y,
                },
            });

            setRefreshPhotos((prev) => prev + 1);
            setRefreshHeader((prev) => prev + 1);
            setShowCropModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const onCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    return (
        <div className={`${staff ? "mx-30" : "w-full md:mx-auto md:w-2/3 bg-gray-100 p-5 sm:px-20 min-h-screen"}`}>
            {!staff ? (
                <div>
                    <div onClick={() => navigate(-1)} className={`hidden md:flex items-center absolute left-[2%] top cursor-pointer`}>
                        <svg width="32" height="32" viewBox="0 0 32 32" className="max-w-none h-8 rotate-180 mb-2 mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28.5725 15.4322C24.2347 15.4322 20.708 11.9056 20.708 7.56777C20.708 7.25306 20.4549 7 20.1402 7C19.8255 7 19.5725 7.25306 19.5725 7.56777C19.5725 10.9452 21.4445 13.8944 24.2022 15.4322H3.56777C3.25306 15.4322 3 15.6853 3 16C3 16.3147 3.25306 16.5678 3.56777 16.5678H24.2055C21.4445 18.1056 19.5757 21.0548 19.5757 24.4322C19.5757 24.7469 19.8288 25 20.1435 25C20.4582 25 20.7112 24.7469 20.7112 24.4322C20.7112 20.0944 24.2379 16.5678 28.5757 16.5678C28.8904 16.5678 29.1435 16.3147 29.1435 16C29.1435 15.6853 28.8904 15.4322 28.5757 15.4322H28.5725Z"
                                className="fill-secondary"
                            />
                        </svg>
                        {!owner ? <h3 className="text-secondary">Donor {donorCode}</h3> : null}
                    </div>
                    <div onClick={() => navigate(-1)} className="flex md:hidden items-center w-full top cursor-pointer">
                        <svg width="32" height="32" viewBox="0 0 32 32" className="max-w-none h-8 rotate-180 mb-2 mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28.5725 15.4322C24.2347 15.4322 20.708 11.9056 20.708 7.56777C20.708 7.25306 20.4549 7 20.1402 7C19.8255 7 19.5725 7.25306 19.5725 7.56777C19.5725 10.9452 21.4445 13.8944 24.2022 15.4322H3.56777C3.25306 15.4322 3 15.6853 3 16C3 16.3147 3.25306 16.5678 3.56777 16.5678H24.2055C21.4445 18.1056 19.5757 21.0548 19.5757 24.4322C19.5757 24.7469 19.8288 25 20.1435 25C20.4582 25 20.7112 24.7469 20.7112 24.4322C20.7112 20.0944 24.2379 16.5678 28.5757 16.5678C28.8904 16.5678 29.1435 16.3147 29.1435 16C29.1435 15.6853 28.8904 15.4322 28.5757 15.4322H28.5725Z"
                                className="fill-secondary"
                            />
                        </svg>
                        {!owner ? <h3 className="text-secondary">Donor {donorCode}</h3> : null}
                    </div>
                </div>
            ) : null}

            {owner ? (
                <div className="md:grid grid-cols-2">
                    <div>
                        <h1>Add photos to your profile</h1>
                        <div>
                            The photos you share offer Intended Parents greater insight into your life over the years.{" "}
                            <span className="font-semibold">
                                Please upload a selection of photos from infancy, childhood, and adulthood. We require at least 10 (five recent photos and five from infancy and childhood). However,
                                there is no limit, and most donors add 15-20 photos.
                            </span>
                        </div>
                        <div className="underline my-5">
                            <span className="cursor-pointer" onClick={() => showPhotoGuidelines(true)}>
                                Photo guidelines
                            </span>
                        </div>
                        <div className="flex justify-center items-center w-full md:hidden">
                            <label
                                htmlFor="fileInput"
                                className="bg-primary hover:bg-secondary text-secondary hover:text-primary border border-secondary hover:border-primary w-full mb-4 rounded-full px-5 py-3 text-center font-medium cursor-pointer"
                            >
                                Upload Photo
                            </label>
                            <input type="file" multiple ref={fileInputRef} id="fileInput" style={{ display: "none" }} accept="image/jpeg,image/png" name="image" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="hidden md:flex justify-center items-center">
                        <label
                            htmlFor="fileInput"
                            className="bg-primary hover:bg-secondary text-secondary hover:text-primary border border-secondary hover:border-primary rounded-full px-5 py-3 font-medium cursor-pointer"
                        >
                            Upload Photo
                        </label>
                        <input type="file" multiple ref={fileInputRef} id="fileInput" style={{ display: "none" }} accept="image/jpeg,image/png" name="image" onChange={handleFileChange} />
                    </div>
                </div>
            ) : null}
            {staff ? (
                <div className="mb-10 mt-3">
                    <label
                        htmlFor="fileInput"
                        className="h-12 bg-primary hover:bg-secondary text-secondary hover:text-primary border border-secondary hover:border-primary rounded-full py-3 px-6 font-semibold mr-10 cursor-pointer"
                    >
                        Upload Photo
                    </label>
                    <input type="file" multiple ref={fileInputRef} id="fileInput" style={{ display: "none" }} accept="image/jpeg,image/png" name="image" onChange={handleFileChange} />
                </div>
            ) : null}

            {uploadModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            height: "90%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-forest-50 ">Confirm upload and finalize details</span>
                        </h3>
                        <div className="text-xs mb-3">
                            When you upload your photos, they will automatically be mirrored. This is done as an intentional privacy measure to protect against reverse-image search.
                        </div>
                        {uploadInProgress ? (
                            <div className="absolute inset-0 flex justify-center items-center z-50">
                                <img width={65} height={65} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
                            </div>
                        ) : null}
                        <div className={`h-full grid grid-cols-3 gap-1 overflow-y-scroll bg-white-100 ${uploadInProgress ? "opacity-50" : ""}`}>
                            {previewUrls.map((url) => (
                                <img className={`w-full`} src={url} alt="imgToView" />
                            ))}
                        </div>
                        <div className="mt-auto">
                            <div className="flex justify-between">
                                <div className="col-span-1 flex justify-end mt-4">
                                    <button onClick={closeUpload} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                        Close
                                    </button>
                                </div>
                                <div className="col-span-1 flex justify-end mt-4">
                                    <button
                                        onClick={() => {
                                            if (!uploadInProgress) {
                                                handleUpload();
                                            }
                                        }}
                                        className={`mr-2 px-4 py-2 rounded-md bg-green-100`}
                                    >
                                        {uploadInProgress ? "Loading" : "Upload"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showCropModal ? (
                <div
                    className="modal border border-solid border-black rounded-t-md"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-1/2 relative h-3/5"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <div className="flex w-full relative h-full justify-center mx-auto">
                            <Cropper image={imgToCrop.source} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={onCropChange} onCropComplete={updateCropComplete} onZoomChange={setZoom} />
                        </div>
                        <div className="flex justify-between p-4 z-20 w-full h-20 bg-white rounded-b-md">
                            <button onClick={closeCrop} className="h-10 mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                Close
                            </button>
                            <button onClick={saveCrop} className="h-10 px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}

            {showReviewCropModal ? (
                <div
                    className="modal border border-solid border-black rounded-t-md"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-1/2 relative h-3/5"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <div className="flex w-full relative h-full justify-center mx-auto">
                            <Cropper image={imgToCrop.source} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={onCropChange} onCropComplete={updateCropComplete} onZoomChange={setZoom} />
                        </div>
                        <div className="flex justify-between p-4 z-20 w-full h-20 bg-white rounded-b-md">
                            <button onClick={closeReviewCrop} className="h-10 mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                Close
                            </button>
                            <div>
                                <button onClick={() => staffDecision(imgToCrop, "rejected")} className="h-10 mx-1 bg-red-500 text-white border border-secondary rounded-full py-2 px-6 font-semibold">
                                    Reject
                                </button>
                                <button onClick={() => staffDecision(imgToCrop, "approved")} className="h-10 mx-1 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold">
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showDeleteModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            height: "80%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-forest-50 ">Are you sure you want to delete this photo?</span>
                        </h3>
                        <div className="h-[70%] flex flex-grow items-center justify-center">
                            <img className="max-h-full max-w-full" src={imgToDelete} alt="imgToView" />
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeDelete} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={confirmDelete} className="px-4 py-2 rounded-md bg-red-500 hover:bg-red-600 text-white">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showViewModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            height: "80%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-forest-50 ">Photo view</span>
                        </h3>
                        <div className="h-[70%] flex flex-grow items-center justify-center">
                            <img className="max-h-full max-w-full" src={imgToView.cropped_source} alt="imgToView" />
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeView} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {photoGuidelines ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl px-10 pt-10 pb-5 h-full md:h-[550px]"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <h3 className="flex justify-between text-2xl font-semibold mb-4">
                            <span className="heading-underline-forest-50 ">Photo guidelines</span>
                            <button onClick={() => showPhotoGuidelines(false)} className="font-normal">
                                X
                            </button>
                        </h3>
                        <div className="h-full overflow-y-auto">
                            <div className="sm:grid grid-cols-2 gap-x-5">
                                <div className="flex flex-col">
                                    <div className="text-xl font-medium text-secondary mb-4">Do</div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_do.svg`} alt="Split dashboard" />
                                        <div>Share close-up portraits as well as full-body photos</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_do.svg`} alt="Split dashboard" />
                                        <div>Share photos that are well-lit, in focus</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_do.svg`} alt="Split dashboard" />
                                        <div>Share candid photos that highlight what makes you unique — think hobbies, photos with your pets, or photos from education or professional milestones.</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_do.svg`} alt="Split dashboard" />
                                        <div>Share photos where you're standing alone. A few friend/family photos (with their permission!) is okay!</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_do.svg`} alt="Split dashboard" />
                                        <div>Share photos from various stages of your life — infancy, childhood, teen years, and adulthood.</div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-xl font-medium text-[#8C1948] mb-4">Don't</div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_dont.svg`} alt="Split dashboard" />
                                        <div>Don't share risque shots, please</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_dont.svg`} alt="Split dashboard" />
                                        <div>Don't share mirror selfies</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_dont.svg`} alt="Split dashboard" />
                                        <div>Don't share noisy or grainy photos</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                        <img className="mr-3" src={`${process.env.PUBLIC_URL}/images/freeze/check_dont.svg`} alt="Split dashboard" />
                                        <div>Don't share photos with filters, stickers, or excessive retouching</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {needsReview.length ? (
                <div>
                    <h1 className="mr-5 w-fit inline-block">
                        <span className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"}`}>Needs Review</span>
                        <span className="ml-2">( {needsReview.length} )</span>
                    </h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {needsReview.map((photo) => (
                            <div key={photo.id} className="aspect-square">
                                <a href={photo.cropped_source} target="_blank" rel="noreferrer">
                                    <img src={photo.cropped_source} alt={photo.name} className="object-cover w-full h-full border border-black" />
                                </a>
                                <div className="my-3 flex justify-center">
                                    <button className="block h-10 w-40 bg-primary text-secondary border border-secondary rounded-full py-1 px-6 font-semibold" onClick={() => openReviewCrop(photo)}>
                                        Review
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}

            <div>
                {staff ? (
                    <h1 className="mr-5 mt-5 w-fit inline-block">
                        <span className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"}`}>Approved</span>
                        <span className="ml-2">( {items.length} )</span>
                    </h1>
                ) : (
                    <h1 className="mr-5 w-fit inline-block">
                        <span className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"}`}>{photos.length} Photos</span>
                        {owner && photos.length < 10 ? <span className="ml-4 text-red-500 text-lg">Please add {10 - photos.length} more photos</span> : null}
                    </h1>
                )}
                {owner || staff ? (
                    <div>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
                            <SortableContext items={items} strategy={rectSortingStrategy}>
                                <Grid columns={staff ? 3 : 2}>
                                    {items.map((url, index) => (
                                        <SortablePhoto
                                            key={index}
                                            url={url}
                                            staff={staff}
                                            owner={owner}
                                            index={index}
                                            openDelete={openDelete}
                                            openView={openView}
                                            openCrop={openCrop}
                                            openReviewCrop={openReviewCrop}
                                            photo={photos[index]}
                                        />
                                    ))}
                                </Grid>
                            </SortableContext>

                            <DragOverlay adjustScale={true}>{activeId ? <Photo url={activeId} index={items.indexOf(activeId)} /> : null}</DragOverlay>
                        </DndContext>
                    </div>
                ) : (
                    <div>
                        <DndContext>
                            <Grid columns={2}>
                                {items.map((url, index) => (
                                    <SortablePhoto key={index} url={url} staff={staff} owner={owner} index={index} openView={openView} photo={photos[index]} />
                                ))}
                            </Grid>
                        </DndContext>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadGallery;
