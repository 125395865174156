import { useContext, useEffect } from "react";
import PublicProfile from "../profile/Profile";
import { IPDashboardContext } from "./Dashboard";
import { useNavigate, useParams } from "react-router-dom";

const DonorProfile = () => {
    const params = useParams();
    const { setCurrentPage } = useContext(IPDashboardContext);
    const navigate = useNavigate();
    setCurrentPage("profile");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    return (
        <div>
            <PublicProfile ip={true} viewPhotos={() => navigate(`/family/donors/${params.userId}/photos`)} />
        </div>
    );
};

export default DonorProfile;
