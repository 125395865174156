import React, {useState} from "react";
import IntendedParents from "./IntendedParents";
import { useNavigate } from "react-router-dom";
import EditRequests from "./EditRequest";
import CheckIns from "./CheckIns";

const IntendedParentsPage = ({ tab }) => {
    const navigate = useNavigate();
    const [ipCount, setIpCount] = useState(0);

    const handleIpCount = (newIpCount) => {
        setIpCount(newIpCount)
    }

    return (
        <div className="">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10">
                    <div
                        onClick={() => navigate("/staff/intended-parents")}
                        className={`${tab === "intendedParents" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        INTENDED PARENTS ({ipCount})
                    </div>
                    {/* <div onClick={() => navigate('/staff/intended-parents/edit-requests')} className={`${tab === 'editRequests' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}>PROFILE EDIT REQUESTS</div> */}
                    {/* <div onClick={() => navigate('/staff/intended-parents/check-ins')} className={`${tab === 'checkIns' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}>CHECK-INS</div> */}
                </div>

                <div></div>
            </div>

            <div>
                {tab === "intendedParents" ? <IntendedParents handleIpCount={handleIpCount}/> : null}

                {tab === "editRequests" ? <EditRequests /> : null}

                {tab === "checkIns" ? <CheckIns /> : null}
            </div>
        </div>
    );
};

export default IntendedParentsPage;
