import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AppContext } from "../../../../App";

const ClinicMatches = ({ clinicId }) => {
    const [matches, setMatches] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [loginDateRange, setLoginDateRange] = useState([null, null]);
    const [joinDateRange, setJoinDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [orderDir, setOrderDir] = useState("desc");
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [hasError, setHasError] = useState(null);

    const statusDropdownRef = useRef(null);
    const navigate = useNavigate();
    const { request } = useContext(AppContext);

    const statuses = ["application_in_progress"];

    useEffect(() => {
        document.title = "Staff | Matches";
        const fetchMatches = async () => {
            try {
                let filterString = "";
                if (filters.length) {
                    filters.forEach((filter) => {
                        if (filter.name.includes("range")) {
                            if (filter.value[0] !== null) {
                                filterString += `&${filter.name}_min=${filter.value[0]}`;
                            }
                            if (filter.value[1] !== null) {
                                filterString += `&${filter.name}_max=${filter.value[1]}`;
                            }
                        } else if (filter.name === "status") {
                            filter.value.forEach((v) => {
                                filterString += `&${filter.name}=${v}`;
                            });
                        } else {
                            filterString += `&${filter.name}=${filter.value}`;
                        }
                    });
                }

                const response = await request({
                    url: `/staff/matches?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}&clinic_id=${clinicId}`,
                    method: "GET",
                });

                const { results } = response;

                if (page !== 1) {
                    setMatches((prev) => [...prev, ...results]);
                } else {
                    setMatches(results);
                }
                setHasMore(results.length > 0);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchMatches();
    }, [request, page, orderBy, orderDir, filters, clinicId]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const navigateToIntendedParentPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop.includes("last_name")) {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleIPNameSearch = (event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "ip_name" && filter.name !== "sm_name");

        if (search.length) {
            filteredFilters.push({
                name: "ip_name",
                value: search,
            });

            window.history.replaceState(null, "", `${window.location.pathname.includes("?") ? `${window.location.pathname}&ip_name=${search}` : `${window.location.pathname}?ip_name=${search}`}`);
        } else {
            window.history.replaceState(null, "", window.location.pathname.replace("?ip_name=", ""));
            window.history.replaceState(null, "", window.location.pathname.replace("&ip_name=", ""));
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleSplitNameSearch = (event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "sm_name" && filter.name !== "ip_name");

        if (search.length) {
            filteredFilters.push({
                name: "sm_name",
                value: search,
            });

            window.history.replaceState(null, "", `${window.location.pathname.includes("?") ? `${window.location.pathname}&sm_name=${search}` : `${window.location.pathname}?sm_name=${search}`}`);
        } else {
            window.history.replaceState(null, "", window.location.pathname.replace("?sm_name=", ""));
            window.history.replaceState(null, "", window.location.pathname.replace("&sm_name=", ""));
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleStatusClick = (status) => {
        let ns = [];
        if (selectedStatuses.includes(status)) {
            ns = structuredClone(selectedStatuses).filter((s) => s !== status);
        } else {
            ns = [...structuredClone(selectedStatuses), status];
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        if (ns.length) {
            filteredFilters.push({
                name: "status",
                value: ns,
            });
        }
        setPage(1);
        setFilters(filteredFilters);
        setSelectedStatuses(ns);
    };

    const clearAllStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        setPage(1);
        setFilters(filteredFilters);
        setSelectedStatuses([]);
    };

    return (
        <div className="mx-30 mb-20">
            <div className="mb-5 ml-auto">
                <div className="p-3">
                    <input
                        className="p-1 inline-block border border-solid ml-2 rounded-lg"
                        onChange={handleIPNameSearch}
                        value={filters.filter((f) => f.name === "ip_name").length ? filters.filter((f) => f.name === "ip_name")[0].value : ""}
                        placeholder="Parent name..."
                    ></input>
                    <input
                        className="p-1 inline-block border border-solid ml-2 rounded-lg"
                        onChange={handleSplitNameSearch}
                        value={filters.filter((f) => f.name === "sm_name").length ? filters.filter((f) => f.name === "sm_name")[0].value : ""}
                        placeholder="Split member name..."
                    ></input>
                    <div className="p-1 inline-block border border-solid ml-2 rounded-lg">
                        <DatePicker
                            selected={loginDateRange[0]}
                            onChange={(update) => {
                                handleDateFilter("login_range", update, setLoginDateRange);
                            }}
                            selectsRange
                            isClearable
                            startDate={loginDateRange[0]}
                            endDate={loginDateRange[1]}
                            placeholderText="Login range"
                        />
                    </div>
                    <div className="p-1 inline-block border border-solid ml-2 rounded-lg">
                        <DatePicker
                            selected={joinDateRange[0]}
                            onChange={(update) => {
                                handleDateFilter("join_range", update, setJoinDateRange);
                            }}
                            selectsRange
                            isClearable
                            startDate={joinDateRange[0]}
                            endDate={joinDateRange[1]}
                            placeholderText="Join range"
                        />
                    </div>
                    <div className="relative p-1 inline-block" ref={statusDropdownRef}>
                        <button
                            onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                            className="py-1 px-2 rounded-md border bg-white text-gray-700" // Style as needed
                        >
                            Filter by Status{" "}
                            <span className="mx-2" onClick={clearAllStatuses}>
                                x
                            </span>
                        </button>

                        {statusDropdownOpen && (
                            <div className="absolute mt-2 w-full left-0 right-0 bg-white rounded-md shadow-lg divide-y divide-dotted">
                                {statuses.map((status) => (
                                    <div key={status} onClick={() => handleStatusClick(status)} className={`px-4 py-2 cursor-pointer ${selectedStatuses.includes(status) ? "bg-gray-100" : ""}`}>
                                        {status}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Matched</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[15%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("intended_parents.last_name")} className="flex items-center">
                                <span className="mr-1">Intended Parent</span>
                                <span className="flex items-center">
                                    {orderBy === "intended_parents.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "intended_parents.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[15%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("freeze_members.last_name")} className="flex items-center">
                                <span className="mr-1">Split Member</span>
                                <span className="flex items-center">
                                    {orderBy === "freeze_members.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "freeze_members.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[10%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("member_advocates.last_name")} className="flex items-center">
                                <span className="mr-1">Member Advocate</span>
                                <span className="flex items-center">
                                    {orderBy === "member_advocates.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "member_advocates.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[15%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinics.name")} className="flex items-center">
                                <span className="mr-1">Clinic</span>
                                <span className="flex items-center">
                                    {orderBy === "clinics.name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinics.name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinic_staff.last_name")} className="flex items-center">
                                <span className="mr-1">Doctor</span>
                                <span className="flex items-center">
                                    {orderBy === "clinic_staff.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinic_staff.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Status</span>
                            </span>
                        </th>
                        <th className="w-[10%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("is_paid")} className="flex items-center">
                                <span className="mr-1">Paid</span>
                                <span className="flex items-center">
                                    {orderBy === "is_paid" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "is_paid" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {matches &&
                        matches.map((match) => {
                            return (
                                <tr key={match.id} className="w-full">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(match.created_at)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span onClick={() => navigateToIntendedParentPage(match.family.intended_parents[0].user.id)}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {match.family.intended_parents[0].user.firstName.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">
                                                {match.family.intended_parents[0].user.firstName} {match.family.intended_parents[0].user.lastName}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span onClick={() => navigateToSplitMemberPage(match.freeze_member.id)}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {match.freeze_member.firstName.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">
                                                {match.freeze_member.firstName} {match.freeze_member.lastName}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>
                                            {match.member_advocate.firstName} {match.member_advocate.lastName[0]}.
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span className="font-medium">{match.clinic.name}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>
                                            {match.doctor.first_name} {match.doctor.last_name}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span
                                            className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm"
                                            style={{ backgroundColor: match.status !== "cancelled" ? "#D2E9E6" : "rgb(252 165 165)" }}
                                        >
                                            {match.status
                                                .split("_")
                                                .map((word) => word[0].toUpperCase() + word.substring(1))
                                                .join(" ")}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span
                                            className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm"
                                            style={{ backgroundColor: match.is_paid ? "#D2E9E6" : "rgb(252 165 165)" }}
                                        >
                                            {match.is_paid ? "PAID" : "NOT PAID"}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default ClinicMatches;
