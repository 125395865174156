import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { paymentStatuses } from "../../../util/statuses";
import { AppContext } from "../../../App";

const CreateMatchModal = ({ sm = null, ip = null, closeModal }) => {
    const [smInput, setSMInput] = useState("");
    const [smResults, setSMResults] = useState([]);
    const [smSelection, setSMSelection] = useState(sm);
    const [ipInput, setIPInput] = useState("");
    const [ipResults, setIPResults] = useState([]);
    const [ipSelection, setIPSelection] = useState(ip);
    const [sendMatchEmail, setSendMatchEmail] = useState(false);
    const [sendInvoice, setSendInvoice] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(paymentStatuses.filter((p) => p.value === "deposit")[0].value);
    const [paymentDropdown, setPaymentDropdown] = useState(false);
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    const debounceTimeoutRef = useRef(null);
    const payRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (payRef.current && !payRef.current.contains(event.target)) {
                setPaymentDropdown(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleInputChange = (event, type, setSearch, setResults) => {
        const search = event.target.value;
        setSearch(search);

        clearTimeout(debounceTimeoutRef.current);
        debounceTimeoutRef.current = setTimeout(async () => {
            if (search.length) {
                try {
                    const response = await request({
                        url: `/staff/powersearch?query=${search}&type=${type}`,
                        method: "GET",
                    });

                    const { results } = response;

                    setResults(results);
                } catch (error) {
                    console.error("Error fetching search results:", error);
                }
            } else {
                setResults([]);
            }
        }, 300);
    };

    const extractDate = (dateTimeString) => {
        if (!dateTimeString) {
            return "";
        }

        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return null;
    };

    const createMatch = async () => {
        try {
            const response = await request({
                url: `/staff/matches`,
                method: "POST",
                data: {
                    freezeMemberID: smSelection.id,
                    familyID: ipSelection.intended_parent.family_id,
                    matchEmail: sendMatchEmail,
                    matchInvoice: sendInvoice,
                    paymentStatus: paymentStatus,
                },
            });

            const { match } = response;

            navigate(`/staff/matches/${match.id}`);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    const selectPaymentStatus = (status) => {
        setPaymentStatus(status);
        setPaymentDropdown(false);
    };

    const selectSM = (sm) => {
        setSMSelection(sm);
        setSMInput("");
        setSMResults([]);
    };

    const selectIP = (ip) => {
        setIPSelection(ip);
        setIPInput("");
        setIPResults([]);
    };

    return (
        <div
            className="modal"
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
            }}
        >
            <div
                className="modal-content w-full max-w-3xl"
                style={{
                    backgroundColor: "white",
                    padding: "40px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                }}
            >
                <h3 className="mb-4 flex justify-between items-center">
                    <div>
                        <div>
                            <span className="text-2xl font-semibold heading-underline-forest-50">Create a match</span>
                        </div>
                        <button onClick={() => setSendMatchEmail(!sendMatchEmail)} className={`focus:outline-none flex items-center`}>
                            <input type="checkbox" className="accent-secondary mr-2" checked={sendMatchEmail}></input>
                            <span className="mr-2">Send Email Confirmation</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                            </svg>
                        </button>
                        <button onClick={() => setSendInvoice(!sendInvoice)} className={`focus:outline-none flex items-center`}>
                            <input type="checkbox" className="accent-secondary mr-2" checked={sendInvoice}></input>
                            <span className="mr-2">Send Payment Request</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
                            </svg>
                        </button>
                    </div>
                    <div ref={payRef} className="hidden flex">
                        <div
                            onClick={() => setPaymentDropdown(!paymentDropdown)}
                            className="px-4 py-2 rounded-md border font-semibold text-md flex items-center cursor-pointer"
                            style={
                                !paymentDropdown
                                    ? {
                                          backgroundColor: paymentStatuses.filter((status) => status.value === paymentStatus)[0].bg,
                                          color: paymentStatuses.filter((status) => status.value === paymentStatus)[0].color,
                                      }
                                    : { display: "none" }
                            }
                        >
                            {paymentStatuses.filter((status) => status.value === paymentStatus)[0].label}
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                        </div>
                        <div className="min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden" style={paymentDropdown ? { zIndex: 100 } : { display: "none" }}>
                            <div>
                                {paymentStatuses.map((status) => (
                                    <div
                                        key={status.value}
                                        onClick={() => selectPaymentStatus(status.value)}
                                        className={`px-4 py-2 rounded-md font-medium text-md my-2`}
                                        style={{ backgroundColor: status.bg, color: status.color }}
                                    >
                                        {status.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </h3>
                <div>
                    <h3 className="text-2xl font-semibold mb-4">
                        <span className="heading-underline ">Split Member</span>
                    </h3>
                    {sm ? null : (
                        <div>
                            <input
                                autoFocus
                                value={smInput}
                                onChange={(e) => handleInputChange(e, "split_member", setSMInput, setSMResults)}
                                className="w-full py-5 px-5 rounded-lg border border-gray-300 mb-1"
                                placeholder="Search for split members..."
                            />
                            <div className="overflow-y-auto max-h-[300px] ml-2 divide-y divide-dashed">
                                {smResults.map((user) => {
                                    return (
                                        <div key={user.id} onClick={() => selectSM(user)} className={`flex my-3 bg-gray-100 rounded-md items-center p-3`}>
                                            <div className="w-7 mr-2 mt-1 flex">
                                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                                    {user.firstName.charAt(0).toUpperCase()}
                                                </h1>
                                            </div>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center">
                                                    <span>
                                                        <span className="font-semibold">
                                                            {user.freeze_member && user.donorCode ? `[${user.donorCode}]` : null} {user.firstName} {user.lastName}{" "}
                                                            {user.preferredName ? `"${user.preferredName}"` : null} {user.pronouns ? `(${user.pronouns})` : null}
                                                        </span>
                                                        <span className="text-gray-400 ml-2">| {user.freeze_member ? "Freeze Member" : "Intended Parent"}</span>
                                                    </span>
                                                    <span className="mr-3 flex items-center">
                                                        <span className="text-gray-400">DOB: {extractDate(user.dateOfBirth)}</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="font-base text-xs">
                                                        {user.email} | {formatPhoneNumber(user.phoneNumber)} | {user.city ? `${user.city},` : null} {user.state}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {smSelection ? (
                        <div key={smSelection.id} className={`flex my-3 bg-gray-100 rounded-md items-center p-3`}>
                            <div className="w-7 mr-2 mt-1 flex">
                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                    {smSelection.firstName.charAt(0).toUpperCase()}
                                </h1>
                            </div>
                            <div className="flex-grow flex flex-col text-sm">
                                <div className="flex justify-between items-center">
                                    <span>
                                        <span className="font-semibold">
                                            {smSelection.freeze_member ? `[${smSelection.firstName[0]}${smSelection.firstName[1].toUpperCase()}${smSelection.lastName[0].toUpperCase()}]` : null}{" "}
                                            {smSelection.firstName} {smSelection.lastName} {smSelection.preferredName ? `"${smSelection.preferredName}"` : null}{" "}
                                            {smSelection.pronouns ? `(${smSelection.pronouns})` : null}
                                        </span>
                                        <span className="text-gray-400 ml-2">| {smSelection.freeze_member ? "Freeze Member" : "Intended Parent"}</span>
                                    </span>
                                    <span className="mr-3 flex items-center">
                                        <span className="text-gray-400">DOB: {extractDate(smSelection.dateOfBirth)}</span>
                                    </span>
                                </div>
                                <div>
                                    <span className="font-base text-xs">
                                        {smSelection.email} | {formatPhoneNumber(smSelection.phoneNumber)} | {smSelection.city ? `${smSelection.city},` : null} {smSelection.state}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="mt-10">
                    <h3 className="text-2xl font-semibold mb-4">
                        <span className="heading-underline ">Intended Parent</span>
                    </h3>

                    {ip ? null : (
                        <div>
                            <input
                                autoFocus
                                value={ipInput}
                                onChange={(e) => handleInputChange(e, "intended_parent", setIPInput, setIPResults)}
                                className="w-full py-5 px-5 rounded-lg border border-gray-300 mb-1"
                                placeholder="Search for intended parents..."
                            />
                            <div className="overflow-y-auto max-h-[300px] ml-2 divide-y divide-dashed">
                                {ipResults.map((user) => {
                                    return (
                                        <div key={user.id} onClick={() => selectIP(user)} className={`flex my-3 bg-gray-100 rounded-md items-center p-3`}>
                                            <div className="w-7 mr-2 mt-1 flex">
                                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                                    {user.firstName.charAt(0).toUpperCase()}
                                                </h1>
                                            </div>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center">
                                                    <span>
                                                        <span className="font-semibold">
                                                            {user.freeze_member ? `[${user.firstName[0]}${user.firstName[1].toUpperCase()}${user.lastName[0].toUpperCase()}]` : null} {user.firstName}{" "}
                                                            {user.lastName} {user.preferredName ? `"${user.preferredName}"` : null} {user.pronouns ? `(${user.pronouns})` : null}
                                                        </span>
                                                        <span className="text-gray-400 ml-2">| {user.freeze_member ? "Freeze Member" : "Intended Parent"}</span>
                                                    </span>
                                                    <span className="mr-3 flex items-center">
                                                        <span className="text-gray-400">DOB: {extractDate(user.dateOfBirth)}</span>
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="font-base text-xs">
                                                        {user.email} | {formatPhoneNumber(user.phoneNumber)} | {user.city ? `${user.city},` : null} {user.state}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {ipSelection ? (
                        <div key={ipSelection.id} className={`flex my-3 bg-gray-100 rounded-md items-center p-3`}>
                            <div className="w-7 mr-2 mt-1 flex">
                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                    {ipSelection.firstName.charAt(0).toUpperCase()}
                                </h1>
                            </div>
                            <div className="flex-grow flex flex-col text-sm">
                                <div className="flex justify-between items-center">
                                    <span>
                                        <span className="font-semibold">
                                            {ipSelection.freeze_member ? `[${ipSelection.firstName[0]}${ipSelection.firstName[1].toUpperCase()}${ipSelection.lastName[0].toUpperCase()}]` : null}{" "}
                                            {ipSelection.firstName} {ipSelection.lastName} {ipSelection.preferredName ? `"${ipSelection.preferredName}"` : null}{" "}
                                            {ipSelection.pronouns ? `(${ipSelection.pronouns})` : null}
                                        </span>
                                        <span className="text-gray-400 ml-2">| {ipSelection.freeze_member ? "Freeze Member" : "Intended Parent"}</span>
                                    </span>
                                    <span className="mr-3 flex items-center">
                                        <span className="text-gray-400">DOB: {extractDate(ipSelection.dateOfBirth)}</span>
                                    </span>
                                </div>
                                <div>
                                    <span className="font-base text-xs">
                                        {ipSelection.email} | {formatPhoneNumber(ipSelection.phoneNumber)} | {ipSelection.city ? `${ipSelection.city},` : null} {ipSelection.state}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                    <div className="col-span-2 flex justify-end mt-4">
                        <button onClick={closeModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                            Close
                        </button>
                        <button onClick={createMatch} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                            Create Match
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateMatchModal;
