import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";

const Notes = ({ user, userType }) => {
    const [notes, setNotes] = useState([]);
    const [fetchNotes, setFetchNotes] = useState(1);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await request({
                    url: `/staff/${userType}/${user.id}/notes`,
                    method: "GET",
                });

                const { notes } = response;
                if (notes) {
                    setNotes(notes);
                }
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchNotes();
    }, [request, user, userType, fetchNotes]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const extractDate = (dateTimeString) => {
        const dateParts = dateTimeString.split("T")[0]; // Split on 'T' and take the first part
        return dateParts;
    };

    const pinNote = async (note) => {
        try {
            await request({
                url: `/staff/${userType}/${user.id}/pin-note`,
                method: "PUT",
                data: {
                    noteID: note.id,
                    pinned: !note.pinned,
                },
            });

            setFetchNotes(fetchNotes + 1);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="mx-30 mb-10">
            <h1 className={`${userType === "split-members" ? "heading-underline-forest-50" : "heading-underline-goldenrod"} mr-5 w-fit inline-block`}>Notes</h1>
            <span>({notes.length})</span>
            <div className="rounded-md px-6 py-2 h-full self-stretch -m-px overflow-y-auto divide-y divide-dashed">
                {notes.map((note) => (
                    <div key={note.id} className={`flex my-3 ${note.pinned ? "bg-yellow-100" : "bg-gray-100"} rounded-md pt-3 pl-3`}>
                        <div className="w-7 mr-2 mt-1 flex">
                            <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                {note.staff.firstName.charAt(0).toUpperCase()}
                            </h1>
                        </div>
                        <div className="flex-grow flex flex-col text-sm">
                            <div className="flex justify-between items-center">
                                <span>
                                    <span className="font-semibold ">
                                        {note.staff.firstName} {note.staff.lastName[0]}.
                                    </span>
                                    <span className="text-gray-400 ml-2">| Note</span>
                                </span>
                                <span className="mr-3 flex items-center">
                                    <span className="mr-2">
                                        <button onClick={() => pinNote(note)} className="focus:outline-none">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className={`h-3 w-3 fill-current ${note.pinned ? "text-yellow-600" : "text-gray-500"} hover:text-yellow-600`}
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                        </button>
                                    </span>
                                    <span className="text-gray-400">{extractDate(note.created_at)}</span>
                                </span>
                            </div>
                            <div className="pt-2 pb-4 whitespace-pre-line">{note.note}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Notes;
