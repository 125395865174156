import { useContext } from "react";
import Documents from "../staff/files/Documents";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";

const IPDocuments = () => {
    const { loggedInUser } = useContext(AppContext);
    const { setCurrentPage } = useContext(IPDashboardContext);

    setCurrentPage("documents");

    return (
        <div id="inner-body" className="min-h-screen flex flex-col h-full w-full bg-white-200 ">
            <div className="w-3/4 mx-auto mt-10 bg-white py-8 px-4">
                <Documents user={loggedInUser} userType={"family"} />
            </div>
        </div>
    );
};

export default IPDocuments;
