import { useContext, useEffect } from "react";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

const StaffLoginPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { refreshUser } = useContext(AppContext);

    useEffect(() => {
        document.title = "Cofertility | Login";

        if (isAuthenticated) {
            const User = async () => {
                let userData = await refreshUser();

                if (userData) {
                    if (userData.isStaff) {
                        navigate("/staff/split-members");
                    } else if (userData.freeze_member) {
                        if (userData.freeze_member.status === "failed") {
                            navigate("/keep/dashboard");
                        } else if (userData.appFinished) {
                            navigate("/freeze/dashboard");
                        } else if (userData.isWelcomed) {
                            navigate("/freeze/application");
                        } else {
                            navigate("/freeze/welcome");
                        }
                    } else if (userData.intended_parent) {
                        if (userData.appFinished) {
                            navigate("/family/dashboard");
                        } else {
                            let env = process.env.REACT_APP_ENV;
                            if (env === "local") {
                                env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
                            }
                            window.location.href = `https://zvokymtzgt.formsort.app/flow/family-intake-form/variant/main?responderUuid=${userData.responderUuid}&formsortEnv=${env}`;
                        }
                    }
                }
            };

            User();
        }
    }, [isAuthenticated, refreshUser, navigate]);

    let logo = <img className="pb-2" height="31" width="148" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    const mobileLogo = <img srcSet={`${process.env.PUBLIC_URL}/images/logo.svg`} className="block md:hidden w-45 pt-8 pb-16" alt="Mobile logo" />;

    let signInLogo = <img className="pt-2" height="34" width="181" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/family_by_co_logo.svg`} />;

    const sidebar_image = (
        <img
            srcSet={`${process.env.PUBLIC_URL}/images/freeze_login_image.jpeg 390w`}
            sizes="(min-width: 768px) 390px"
            className="hidden md:block md:w-sidebar md:h-screen object-cover object-[50%_center]"
            alt="Woman using Cofertility platform"
        />
    );

    return (
        <div id="inner-body">
            <div id="app" className="pb-10 freeze">
                <div className="w-full md:h-screen md:w-sidebar md:fixed text-white-200">
                    <h1 className="absolute hidden md:block top-0 p-12 w-3/4">
                        <a href="https://www.cofertility.com">{logo}</a>
                        Family building options that put you first.
                    </h1>
                    <div className="absolute hidden md:block bottom-0 p-12 w-3/4">
                        Have a question?
                        <br />
                        Email us at{" "}
                        <a href="mailto:support@cofertility.com" className="hover:text-freeze-secondary !text-white underline underline-offset-2">
                            support@cofertility.com
                        </a>
                    </div>
                    {sidebar_image}
                </div>
                <div className="md:ml-sidebar md:h-screen flex-1 flex flex-col px-6">
                    <div className="md:mx-auto w-full md:max-w-md md:mt-29 flex flex-col justify-center relative">
                        <div className="bg-black md:bg-transparent bg-family-login-mobile bg-cover bg-[top_right_17rem] mb-10 md:bg-none -mx-6 px-6 md:text-black flex flex-col">
                            {mobileLogo}
                            <div className="mb-5 h-8 w-8 mt-6 md:mt-0">
                                <div className="active" style={{ display: "none" }}>
                                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h1 className="mb-8 display h2 md:h1 flex items-center">
                                <div className="mr-2">Sign in to Abra!</div>
                            </h1>
                        </div>
                        <div className="flex md:flex-col gap-8 flex-col-reverse pt-20">
                            <GoogleLoginButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffLoginPage;
