import React from "react";

export const MultiButtonFilter = ({ title, options, width, setFilterArray, selected, setSelected }) => {
    return (
        <div className="!text-[#191919]">
            <h4 className="p-1 my-1">{title}</h4>
            <div>
                {options &&
                    options.map((item) => {
                        return (
                            <div
                                key={item.value}
                                onClick={() => setFilterArray(selected, setSelected, item.value)}
                                className={`inline-block ml-2 h-16 ${width} rounded-lg  ${
                                    selected.includes(item.value) ? `bg-secondary text-white` : `bg-white`
                                } border border-black relative hover:bg-[#D2E9E6] hover:text-black`}
                            >
                                <img
                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${item.icon}`}
                                    className={`absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5 h-5 rounded-full ${selected.includes(item.value) ? `!text-white` : ``}`}
                                    alt={item.label}
                                />
                                <div className="absolute bottom-2 inset-x-0 text-center">{item.label}</div>
                            </div>
                        );
                    })}
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
