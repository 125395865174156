import { useContext, useEffect } from "react";
import UploadGallery from "../staff/files/Photos/UploadGallery";
import { IPDashboardContext } from "./Dashboard";
import { useParams } from "react-router-dom";

const IPDonorPhotos = () => {
    const { setCurrentPage } = useContext(IPDashboardContext);
    const params = useParams();
    setCurrentPage("donors");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top "
        >
            <UploadGallery user={{ id: params.userId }} userType={"split-members"} returnButton={true} />
        </div>
    );
};

export default IPDonorPhotos;
