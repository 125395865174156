import { useContext, useEffect } from "react";
import PublicProfile from "../profile/Profile";
import { AppContext } from "../../App";
import { SMDashboardContext } from "./Dashboard";
import { useNavigate } from "react-router-dom";

const SMProfile = () => {
    const { loggedInUser } = useContext(AppContext);
    const { setCurrentPage } = useContext(SMDashboardContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage("profile");
    }, [setCurrentPage]);

    return (
        <div>
            <PublicProfile userId={loggedInUser.id} owner={true} viewPhotos={() => navigate("/freeze/profile/photos/upload")} />
        </div>
    );
};

export default SMProfile;
