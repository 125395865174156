import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../../App";

const Searches = ({ user }) => {
    const [searches, setSearches] = useState([]);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);

    useEffect(() => {
        const fetchSearches = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/${user.id}/activity/searches`,
                    method: "GET",
                });

                const { searches } = response;

                setSearches(searches);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching searches:", error);
                setHasError(error);
            }
        };

        fetchSearches();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const displayCriteria = (criteria) => {
        let result = [];
        for (const key in criteria) {
            if (Array.isArray(criteria[key])) {
                if (typeof criteria[key][0] === "object") {
                    // is height filter
                    const min = criteria[key].filter((o) => o.name === "height_min");
                    const max = criteria[key].filter((o) => o.name === "height_max");

                    let temp = "";
                    if (min.length && max.length) {
                        temp += `${min[0].value} - ${max[0].value}`;
                    } else if (min.length) {
                        temp += `${min[0].value} <`;
                    } else if (max.length) {
                        temp += `< ${max[0].value}`;
                    }

                    result.push(
                        <div>
                            {handleString(key)}: {temp}
                        </div>
                    );
                } else {
                    result.push(
                        <div>
                            {handleString(key)}: {handleString(criteria[key].join(", "))}
                        </div>
                    );
                }
            }
        }

        return result;
    };

    const handleString = (s) => {
        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    return (
        <div className="mx-30 mb-10">
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-40 text-left py-2 border-b border-dashed border-gray-300">Date Created</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Name</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Criteria</th>
                    </tr>
                </thead>
                <tbody>
                    {searches &&
                        searches.map((search) => {
                            return (
                                <tr key={search.id}>
                                    <td className="w-40 text-left py-2 border-b border-dashed border-gray-300">{extractDate(search.created_at)}</td>
                                    <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{handleString(search.name)}</td>
                                    <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{displayCriteria(search.criteria)}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Searches;
