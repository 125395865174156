import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { KeepDashboardContext } from "./KeepDashboard";

const KeepSettings = () => {
    const [currentTab, setCurrentTab] = useState("personal-info");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [zipcode, setZipcode] = useState("");
    const { loggedInUser, refreshUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(KeepDashboardContext);
    setCurrentPage("settings");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    useEffect(() => {
        setFirstName(loggedInUser.firstName);
        setLastName(loggedInUser.lastName);
        if (loggedInUser.pronouns) setPronouns(loggedInUser.pronouns);
        if (loggedInUser.phoneNumber) setPhone(loggedInUser.phoneNumber);
        if (loggedInUser.address1) setAddress1(loggedInUser.address1);
        if (loggedInUser.address2) setAddress2(loggedInUser.address2);
        if (loggedInUser.city) setCity(loggedInUser.city);
        if (loggedInUser.state) setHomeState(loggedInUser.state);
        if (loggedInUser.zipcode) setZipcode(loggedInUser.zipcode);
    }, [loggedInUser]);

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/split-members/${loggedInUser.id}/contact-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    pronouns,
                    phone,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    zipcode,
                },
            });

            await refreshUser();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="w-full min-h-screen mx-auto bg-gray-100 p-8 rounded-lg flex justify-center">
            <div className="w-1/2 mt-10 grid grid-cols-7">
                <div className="col-span-2 flex flex-col">
                    <h1>
                        <span className="heading-underline">Settings</span>
                    </h1>
                    <h4 className="my-3">
                        <span onClick={() => setCurrentTab("personal-info")} className={`${currentTab === "personal-info" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                            Personal Info
                        </span>
                    </h4>
                </div>
                <div className="col-span-5 w-2/3">
                    {currentTab === "personal-info" ? (
                        <div className="flex flex-col">
                            <h4>Personal information</h4>
                            <div className="grid grid-cols-2 gap-2 my-2">
                                <div>
                                    <div className="my-2">Legal first name</div>
                                    <input onChange={(event) => handleInputChange(event, setFirstName)} value={firstName} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" />
                                </div>
                                <div>
                                    <div className="my-2">Legal last name</div>
                                    <input onChange={(e) => handleInputChange(e, setLastName)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={lastName} />
                                </div>
                                <div>
                                    <div className="my-2">Pronouns</div>
                                    <input onChange={(e) => handleInputChange(e, setPronouns)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={pronouns} />
                                </div>
                            </div>
                            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
                            <h4>Contact information</h4>
                            <div>
                                <div className="my-2">Address line 1</div>
                                <input onChange={(e) => handleInputChange(e, setAddress1)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address1} />
                            </div>
                            <div>
                                <div className="my-2">Address line 2</div>
                                <input onChange={(e) => handleInputChange(e, setAddress2)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address2} />
                            </div>
                            <div className="grid grid-cols-3 gap-2 my-2">
                                <div>
                                    <div>Zip code</div>
                                    <input onChange={(e) => handleInputChange(e, setZipcode)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={zipcode} />
                                </div>
                                <div>
                                    <div>City</div>
                                    <input onChange={(e) => handleInputChange(e, setCity)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={city} />
                                </div>
                                <div>
                                    <div>State</div>
                                    <input onChange={(e) => handleInputChange(e, setHomeState)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={homeState} />
                                </div>
                            </div>
                            <div className="my-2">
                                <div>Phone number</div>
                                <input onChange={(e) => handleInputChange(e, setPhone)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={phone} />
                            </div>
                            <button onClick={saveContactEdits} className="mt-5 text-secondary font-medium bg-primary border-2 border-secondary rounded-full px-3 py-2 w-full">
                                Save changes
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default KeepSettings;
