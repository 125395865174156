import React, { useState, useEffect, useContext } from "react";
import Favorites from "./Favorites";
import Viewed from "./Viewed";
import Searches from "./Searches";
import { AppContext } from "../../../../App";

const Activity = ({ tab, user }) => {
    const [currentTab, setCurrentTab] = useState(tab);
    const [intake, setIntake] = useState(null);
    const [hasError, setHasError] = useState(null);

    const { request } = useContext(AppContext);

    useEffect(() => {
        const fetchOverview = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/${user.id}/overview`,
                    method: "GET",
                });

                const { intake } = response;

                setIntake(intake);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchOverview();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const changeTab = (tabName) => {
        setCurrentTab(tabName);
        window.history.replaceState(null, "", `/staff/intended-parents/${user.id}/activity/${tabName}`);
    };

    return (
        <div className="pb-5">
            <h3 className="mx-30">I'm looking for...</h3>
            <div className="p-2 rounded-lg border border-solid mx-30">{user.intended_parent.family.donor_preferences ? user.intended_parent.family.donor_preferences : null}</div>
            <div className={`bg-white w-full pb-5`}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <div
                        onClick={() => changeTab(`favorites`)}
                        className={`${currentTab === "favorites" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        FAVORITES
                    </div>
                    <div
                        onClick={() => changeTab(`viewed`)}
                        className={`${currentTab === "viewed" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        VIEWED
                    </div>
                    <div
                        onClick={() => changeTab(`searches`)}
                        className={`${currentTab === "searches" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        SEARCHES
                    </div>
                </div>
            </div>

            <div>
                {currentTab === "favorites" ? <Favorites user={user} /> : null}

                {currentTab === "viewed" ? <Viewed user={user} /> : null}

                {currentTab === "searches" ? <Searches user={user} /> : null}
            </div>
        </div>
    );
};

export default Activity;
