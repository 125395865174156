import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

export const AuthenticationGuard = ({ component, options }) => {
    // TODO: update this to simply check the token is present and not expired
    // if expired send to login page
    // current behavior is that every component wrapped with AuthenticationGuard is
    // calling to auth0 to essentially do a login which is taking up to 500ms in some instances
    // this is before our own components can start loading, so feels slow
    // this is ultimately unnecessary and we have the token and can just send them to login if its expired
    // thereby only having them login once per session rather than refreshing their token every page visit
    // additionally the token is checked on the backend for most requests and if its invalid we will also send
    // them to login page once the server alerts us
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <div></div>,
    });

    return <Component {...options} />;
};
