import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import React from "react";

export const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handleSignUp = async () => {
        const email = queryParams.get("email");
        let authParams = {
            prompt: "login",
            screen_hint: "signup",
        };
        if (email !== null) {
            authParams.login_hint = email.replace(" ", "+");
        }

        await loginWithRedirect({
            appState: {
                firstName: queryParams.get("first_name"),
                lastName: queryParams.get("last_name"),
                responderUuid: queryParams.get("responder_uuid"),
                program: queryParams.get("program_selection"),
                flow: queryParams.get("flow") ? queryParams.get("flow") : "family-intake-form",
                operation: location.pathname.includes("activation") ? "activation" : "signup",
                activationToken: queryParams.get("activation_token") ? queryParams.get("activation_token").replace(" ", "+") : null,
            },
            authorizationParams: authParams,
        });
    };

    const getButtonColor = () => {
        if (location.pathname.includes("family")) {
            return "button-family-signup";
        }

        return "button-freeze-signup";
    };

    const getArrowColor = () => {
        if (location.pathname.includes("family")) {
            return "fill-black group-hover:fill-white";
        }

        return "fill-secondary group-hover:fill-white";
    };

    return (
        <button type="button" className={`${getButtonColor()} group`} onClick={handleSignUp}>
            <div className="flex gap-3 items-center text-lg whitespace-nowrap">
                <span className={`font-normal`}>Sign up with email</span>
                <span className="w-7 h-7">
                    <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            className={`${getArrowColor()}`}
                            d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                        ></path>
                    </svg>
                </span>
            </div>
        </button>
    );
};
