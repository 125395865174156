import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { amhStatuses, fmStatuses, scStatuses, ipStatuses, matchStatuses, matchStages, paymentStatuses } from "../../../../util/statuses";
import { AppContext } from "../../../../App";

const MatchPage = ({ tab }) => {
    const [currentTab, setCurrentTab] = useState(tab);
    const [match, setMatch] = useState(null);
    const [matchFamily, setMatchFamily] = useState(null);
    const [matchDonor, setMatchDonor] = useState(null);
    const [clinic, setClinic] = useState(null);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [stageDropdownOpen, setStageDropdownOpen] = useState(false);
    const [payDropdownOpen, setPayDropdownOpen] = useState(false);
    const [refetchMatch, setRefetchMatch] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stickyTabs, setStickyTabs] = useState(false);
    const [showMatchModal, setShowMatchModal] = useState(false);
    const [hasError, setHasError] = useState(null);

    const [refreshKey, setRefreshKey] = useState(0);

    const { request } = useContext(AppContext);
    const navigate = useNavigate();
    const { matchId } = useParams();
    const tabBarRef = useRef(null);
    const statusRef = useRef(null);
    const stageRef = useRef(null);
    const payRef = useRef(null);

    useEffect(() => {
        const fetchIntendedParent = async () => {
            try {
                // remove this GET request all together, we should have all the needed info within local user storage
                // once we update the prescreener and general questions logic to update to user record
                const response = await request({
                    url: `/staff/matches/${matchId}`,
                    method: "GET",
                });

                const { match } = response;

                setMatch(match);
                setMatchFamily(match.family);
                setMatchDonor(match.freeze_member);
                setClinic(match.clinic);
                setIsLoading(false);

                document.title = `Staff | Match #${match.match_number}`;
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchIntendedParent();
    }, [matchId, tab, request, refetchMatch]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (statusRef.current && !statusRef.current.contains(event.target)) {
                setStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (stageRef.current && !stageRef.current.contains(event.target)) {
                setStageDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (payRef.current && !payRef.current.contains(event.target)) {
                setPayDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const changeTab = (tabName) => {
        setCurrentTab(tabName);
        window.history.replaceState(null, "", `/staff/matches/${matchId}/${tabName}`);
        document.title = `Staff | Match #${match.match_number} | ${tabName[0].toUpperCase() + tabName.substring(1)}`;
        if (stickyTabs) {
            tabBarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const updateStatus = async (type, status, dropdownOpen) => {
        try {
            await request({
                url: `/staff/matches/${matchId}/status`,
                method: "PUT",
                data: {
                    type: type,
                    status,
                },
            });

            setRefetchMatch((prevKey) => prevKey + 1);
            dropdownOpen(false);
        } catch (error) {
            // TODO: handle this error
            console.error("Error updating user status:", error);
        }
    };

    const closeMatchModal = () => {
        setShowMatchModal(false);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/staff/matches/${matchId}/contact-info`,
                method: "PUT",
                data: {},
            });

            setRefetchMatch(refetchMatch + 1);
            setRefreshKey(refreshKey + 1);
            closeMatchModal();
        } catch (err) {
            console.log(err);
        }
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return null;
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    return (
        <div className="pb-20">
            <div className="divide-y divide-solid pb-5">
                <div className="flex flex-row w-1/2 pt-5 ml-10 mb-5">
                    <div onClick={() => navigate("/staff/matches")} className="mr-2 cursor-pointer text-secondary text-sm font-medium">
                        MATCHES
                    </div>
                    <span className="text-sm font-medium">{">"}</span>
                    <div className="ml-2 text-secondary text-sm font-medium">
                        {matchFamily.primary_parent.firstName} {matchFamily.primary_parent.lastName} (IP) & {matchDonor.firstName} {matchDonor.lastName} (SM)
                    </div>
                </div>
                <div></div>
            </div>

            <div className="flex p-4 pb-2 mr-10">
                <div className="w-24 mr-5 flex justify-center">
                    <h1 className="flex items-center justify-center w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#CACD9D" }}>
                        {matchFamily.primary_parent.firstName.charAt(0).toUpperCase()} + {matchDonor.firstName.charAt(0).toUpperCase()}
                    </h1>
                </div>

                <div className="flex-grow flex flex-col">
                    <div className="flex gap-2 items-center">
                        <h2>Match #{match.match_number}</h2>
                    </div>

                    <div ref={statusRef} className="child-right flex">
                        <div
                            onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                            className="px-2 py-1 rounded-md border font-medium text-xs flex items-center cursor-pointer"
                            style={{
                                backgroundColor: matchStatuses.filter((status) => status.value === match.status)[0].bg,
                                color: matchStatuses.filter((status) => status.value === match.status)[0].color,
                            }}
                        >
                            {matchStatuses.filter((status) => status.value === match.status)[0].label}
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                        </div>
                        <div
                            className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                            style={statusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                        >
                            <div>
                                {matchStatuses.map((status) => (
                                    <div
                                        key={status.value}
                                        onClick={() => updateStatus("status", status.value, setStatusDropdownOpen)}
                                        className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                        style={{ backgroundColor: status.bg, color: status.color }}
                                    >
                                        {status.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div ref={stageRef} className="hidden child-right flex mt-1">
                        <div
                            onClick={() => setStageDropdownOpen(!stageDropdownOpen)}
                            className="px-2 py-1 rounded-md border font-medium text-xs flex items-center cursor-pointer"
                            style={
                                !stageDropdownOpen
                                    ? {
                                          backgroundColor: matchStages.filter((status) => status.value === match.stage)[0].bg,
                                          color: matchStages.filter((status) => status.value === match.stage)[0].color,
                                      }
                                    : { display: "none" }
                            }
                        >
                            {matchStages.filter((status) => status.value === match.stage)[0].label}
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                        </div>
                        <div
                            className="origin-top-right absolute min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                            aria-orientation="vertical"
                            role="menu"
                            id="alpine-menu-items-1"
                            aria-labelledby="alpine-menu-button-1"
                            tabIndex="0"
                            style={stageDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                        >
                            <div>
                                {matchStages.map((status) => (
                                    <div
                                        key={status.value}
                                        onClick={() => updateStatus("stage", status.value, setStageDropdownOpen)}
                                        className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                        style={{ backgroundColor: status.bg, color: status.color }}
                                    >
                                        {status.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">Matched {extractDate(match.created_at)}</div>

                    <div className="flex items-center mt-3">
                        <span className="text-secondary text-sm font-normal cursor-pointer flex items-center">
                            Copper Match
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                </svg>
                            </div>
                        </span>
                    </div>
                </div>

                <div>
                    <div ref={payRef} className="hidden flex">
                        <div
                            onClick={() => setPayDropdownOpen(!payDropdownOpen)}
                            className="px-4 py-2 rounded-md border font-semibold text-md flex items-center cursor-pointer"
                            style={
                                !payDropdownOpen
                                    ? {
                                          backgroundColor: paymentStatuses.filter((status) => status.value === match.payment_status)[0].bg,
                                          color: paymentStatuses.filter((status) => status.value === match.payment_status)[0].color,
                                      }
                                    : { display: "none" }
                            }
                        >
                            {paymentStatuses.filter((status) => status.value === match.payment_status)[0].label}
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                        </div>
                        <div className="min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden" style={payDropdownOpen ? { zIndex: 1000 } : { display: "none" }}>
                            <div>
                                {paymentStatuses.map((status) => (
                                    <div
                                        key={status.value}
                                        onClick={() => updateStatus("payment_status", status.value, setPayDropdownOpen)}
                                        className={`px-4 py-2 rounded-md font-medium text-md my-2`}
                                        style={{ backgroundColor: status.bg, color: status.color }}
                                    >
                                        {status.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full border-b border-solid border-gray-300 my-2"></div>

            <div className="flex px-4 mx-30 mb-5">
                <div className="flex-grow flex flex-col">
                    <div className="grid grid-cols-3 gap-5 grid-rows-[1fr]">
                        <div>
                            <h3 className="mt-5 text-left">Donor</h3>
                            <div className="flex p-4 mb-5 border border-black rounded-lg bg-white-100 h-4/5">
                                <div className="w-24 mr-2 flex justify-center">
                                    <h1 className="relative w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#D2E9E6" }}>
                                        {matchDonor.user_photos && matchDonor.user_photos[0] ? (
                                            <img
                                                src={matchDonor.user_photos[0].cropped_source}
                                                alt={matchDonor.user_photos[0].name}
                                                className="absolute inset-0 w-full h-full object-cover rounded-full"
                                            />
                                        ) : (
                                            <span className="flex items-center justify-center w-full h-full rounded-full">{matchDonor.firstName.charAt(0).toUpperCase()}</span>
                                        )}
                                    </h1>
                                </div>

                                <div className="flex-grow flex flex-col">
                                    <div className="flex gap-2 items-center">
                                        <h2 className="cursor-pointer" onClick={() => navigate(`/staff/split-members/${matchDonor.id}`)}>
                                            {matchDonor.donorCode ? `[${matchDonor.donorCode}] ` : ""}
                                            {matchDonor.firstName} {matchDonor.lastName} {matchDonor.preferredName ? `- "${matchDonor.preferredName}"` : ""}{" "}
                                            {matchDonor.pronouns ? `(${matchDonor.pronouns.split("_").join("/")})` : null}
                                        </h2>
                                    </div>

                                    <div>
                                        <div className="align-left flex mb-1">
                                            <div
                                                className="text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                                style={{
                                                    backgroundColor: fmStatuses.filter((status) => status.value === matchDonor.freeze_member.status)[0].bg,
                                                    color: fmStatuses.filter((status) => status.value === matchDonor.freeze_member.status)[0].color,
                                                }}
                                            >
                                                <div className="inline-block mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                                    </svg>
                                                </div>
                                                {fmStatuses.filter((status) => status.value === matchDonor.freeze_member.status)[0].label}
                                            </div>
                                        </div>

                                        <div className="align-left flex mb-1">
                                            <div
                                                className="text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                                style={{
                                                    backgroundColor: amhStatuses.filter((status) => status.value === matchDonor.freeze_member.amh_status)[0].bg,
                                                    color: amhStatuses.filter((status) => status.value === matchDonor.freeze_member.amh_status)[0].color,
                                                }}
                                            >
                                                <div className="inline-block mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-steps" viewBox="0 0 16 16">
                                                        <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                                {amhStatuses.filter((status) => status.value === matchDonor.freeze_member.amh_status)[0].label}
                                            </div>
                                        </div>

                                        <div className="align-left flex">
                                            <div
                                                className="text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                                style={{
                                                    backgroundColor: scStatuses.filter((status) => status.value === matchDonor.freeze_member.cycle_first_status)[0].bg,
                                                    color: scStatuses.filter((status) => status.value === matchDonor.freeze_member.cycle_first_status)[0].color,
                                                }}
                                            >
                                                <div className="inline-block mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-snow" viewBox="0 0 16 16">
                                                        <path d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z" />
                                                    </svg>
                                                </div>
                                                {scStatuses.filter((status) => status.value === matchDonor.freeze_member.cycle_first_status)[0].label}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-2">
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                                </svg>
                                            </div>
                                            {formatPhoneNumber(matchDonor.phoneNumber)}
                                        </div>
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                </svg>
                                            </div>
                                            {matchDonor.email}
                                        </div>
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                </svg>
                                            </div>
                                            {matchDonor.address1}, {matchDonor.address2 ? `${matchDonor.address2},` : ""} {matchDonor.city}, {matchDonor.state}, {matchDonor.zipcode}
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                            Copper Profile
                                            <div className="inline-block ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </div>
                                        </span>
                                        <span className="border-l h-6 mx-1 border-gray-300"></span>
                                        <span
                                            onClick={() => navigate(`/staff/split-members/${matchDonor.id}/public-profile`)}
                                            className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center"
                                        >
                                            Public Profile
                                            <div className="inline-block ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="mt-5 text-left">Member Advocate</h3>
                            {match.member_advocate ? (
                                <div className="flex p-4 mb-5 border border-black rounded-lg bg-white-100 h-4/5">
                                    <div className="w-24 mr-2 flex justify-center">
                                        <h1 className="relative w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#D2E9E6" }}>
                                            <span className="flex items-center justify-center w-full h-full rounded-full">{match.member_advocate.firstName.charAt(0).toUpperCase()}</span>
                                        </h1>
                                    </div>

                                    <div className="flex-grow flex flex-col justify-between">
                                        <div className="flex gap-2 items-center">
                                            <h2>
                                                {match.member_advocate.firstName} {match.member_advocate.lastName}{" "}
                                                {match.member_advocate.preferredName ? `- "${match.member_advocate.preferredName}"` : ""}{" "}
                                                {match.member_advocate.pronouns ? `(${match.member_advocate.pronouns.split("_").join("/")})` : null}
                                            </h2>
                                        </div>

                                        <div>
                                            <div className="my-2">
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                                        </svg>
                                                    </div>
                                                    {formatPhoneNumber(match.member_advocate.phoneNumber)}
                                                </div>
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                        </svg>
                                                    </div>
                                                    {match.member_advocate.email}
                                                </div>
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </div>
                                                    {match.member_advocate.address1}, {match.member_advocate.address2 ? `${match.member_advocate.address2},` : ""} {match.member_advocate.city},{" "}
                                                    {match.member_advocate.state}, {match.member_advocate.zipcode}
                                                </div>
                                            </div>

                                            <div className="flex items-center mt-1">
                                                <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                                    Copper Profile
                                                    <div className="inline-block ml-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div>
                            <h3 className="mt-5 text-left">Clinic</h3>
                            {match.clinic ? (
                                <div className="flex p-4 mb-5 border border-black rounded-lg bg-white-100 h-4/5">
                                    <div className="w-24 mr-2 flex justify-center">
                                        <h1 className="relative w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#D2E9E6" }}>
                                            <span className="flex items-center justify-center w-full h-full rounded-full">{match.clinic.name.charAt(0).toUpperCase()}</span>
                                        </h1>
                                    </div>

                                    <div className="flex-grow flex flex-col justify-between">
                                        <div onClick={() => navigate(`/staff/clinics/${match.clinic.id}`)} className="flex gap-2 items-center cursor-pointer">
                                            <h2>{match.clinic.name}</h2>
                                        </div>

                                        <div>
                                            <div className="my-2">
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                                        </svg>
                                                    </div>
                                                    {formatPhoneNumber(match.clinic.phone_number)}
                                                </div>
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                        </svg>
                                                    </div>
                                                    {match.clinic.email}
                                                </div>
                                                <div className="my-1 text-sm flex items-center">
                                                    <div className="inline-block mr-2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                        </svg>
                                                    </div>
                                                    {match.clinic.address_1}, {match.clinic.address_2 ? `${match.clinic.address2},` : ""} {match.clinic.city}, {match.clinic.state},{" "}
                                                    {match.clinic.zipcode}
                                                </div>
                                            </div>

                                            <div className="flex items-center">
                                                <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                                    Copper Profile
                                                    <div className="inline-block ml-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <h3 className="mt-1 text-left">Intended Parents</h3>
                    <div className="grid grid-cols-3 gap-5">
                        {matchFamily.intended_parents.map((ip) => (
                            <div className="flex p-4 mb-5 border border-black rounded-lg bg-white-100">
                                <div className="w-24 mr-2 flex justify-center">
                                    <h1 className="relative w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#D2E9E6" }}>
                                        <span className="flex items-center justify-center w-full h-full rounded-full">{ip.user.firstName.charAt(0).toUpperCase()}</span>
                                    </h1>
                                </div>

                                <div className="flex-grow flex flex-col">
                                    <div className="flex gap-2 justify-between">
                                        <h2 onClick={() => navigate(`/staff/intended-parents/${ip.user.id}`)} className="cursor-pointer">
                                            {ip.user.donorCode ? `[${ip.user.donorCode}] ` : ""}
                                            {ip.user.firstName} {ip.user.lastName} {ip.user.preferredName ? `- "${ip.user.preferredName}"` : ""}{" "}
                                            {ip.user.pronouns ? `(${ip.user.pronouns.split("_").join("/")})` : null}
                                        </h2>
                                        {matchFamily.primary_parent.id === ip.user.id ? <span className="font-semibold text-nowrap">PRIMARY IP</span> : ""}
                                    </div>

                                    <div className="child-right flex">
                                        <div
                                            className="px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                            style={{
                                                backgroundColor: ipStatuses.filter((status) => status.value === matchFamily.status)[0].bg,
                                                color: ipStatuses.filter((status) => status.value === matchFamily.status)[0].color,
                                            }}
                                        >
                                            {ipStatuses.filter((status) => status.value === matchFamily.status)[0].label}
                                        </div>
                                    </div>

                                    <div className="my-2">
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                                </svg>
                                            </div>
                                            {formatPhoneNumber(ip.user.phoneNumber)}
                                        </div>
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                                </svg>
                                            </div>
                                            {ip.user.email}
                                        </div>
                                        <div className="my-1 text-sm flex items-center">
                                            <div className="inline-block mr-2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                </svg>
                                            </div>
                                            {ip.user.address1}, {ip.user.address2 ? `${ip.user.address2},` : ""} {ip.user.city}, {ip.user.state}, {ip.user.zipcode}
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                            Copper Profile
                                            <div className="inline-block ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                </svg>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showMatchModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-goldenrod mr-5">Edit Match Details</span>
                        </h3>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeMatchModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={saveContactEdits} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {/* <div className="min-h-screen">
                {currentTab === 'staff' ? (
                    <ClinicStaff 
                        staff={clinic.clinic_staff}
                    />
                ) : null }

                {currentTab === 'intended-parents' ? (
                    <ClinicIPs clinicId={clinicId} />
                ) : null}

                {currentTab === 'matches' ? (
                    <ClinicMatches clinicId={clinicId} />
                ) : null}
            </div> */}
        </div>
    );
};

export default MatchPage;
