import { useEffect, useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";

const Favorites = () => {
    const [favorites, setFavorites] = useState([]);
    const [refetchFaves, setRefetchFaves] = useState(0);
    const [hasError, setHasError] = useState(null);

    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(IPDashboardContext);

    setCurrentPage("favorites");

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/family/donors/favorites?load=full`,
                    method: "GET",
                });

                let { favorites } = response;

                favorites = favorites.sort((a, b) => {
                    // Priority 1: "on_hold" with matching userID
                    if (a.favorite_user.freeze_member.status === "on_hold" && a.favorite_user.sm_hold.user_id === loggedInUser.id) {
                        return -1; // a comes before b
                    }

                    if (b.favorite_user.freeze_member.status === "on_hold" && b.favorite_user.sm_hold.user_id === loggedInUser.id) {
                        return 1; // b comes before a
                    }

                    // Priority 2: "active" status
                    if (a.favorite_user.freeze_member.status === "active" && b.favorite_user.freeze_member.status !== "active") {
                        return -1;
                    }
                    if (b.favorite_user.freeze_member.status === "active" && a.favorite_user.freeze_member.status !== "active") {
                        return 1;
                    }

                    // Priority 3: Any other status (not active)
                    return 0; // Maintain original order
                });

                setFavorites(favorites);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchFavorites();
    }, [request, refetchFaves, loggedInUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleString = (s) => {
        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const toggleFavorite = async (donorID) => {
        try {
            await request({
                url: `/family/donors/${donorID}/favorite`,
                method: "PUT",
            });

            setRefetchFaves((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const placeHold = async (holdID) => {
        try {
            await request({
                url: `/family/donors/${holdID}/hold`,
                method: "POST",
            });

            setRefetchFaves((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const determineStyle = (fave) => {
        if (fave.favorite_user.freeze_member.status === "on_hold" && fave.favorite_user.sm_hold.user_id === loggedInUser.id) {
            return "border-2 border-yellow-500";
        } else if (fave.favorite_user.freeze_member.status !== "active") {
            return "grayscale opacity-50 pointer-events-none";
        }

        return "";
    };

    return (
        <div id="inner-body" className="min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top ">
            <div id="app" className="pb-10 pt-5">
                <div className="w-full flex justify-center">
                    <h1 className="text-left w-1/2">Favorite donors ({favorites.length})</h1>
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                    {favorites.length
                        ? favorites.map((fave) => {
                              return (
                                  <div className={`flex pr-4 w-1/2 bg-white mb-10 rounded-lg overflow-hidden ${determineStyle(fave)}`}>
                                      <div className="mr-5">
                                          {fave.favorite_user.user_photos ? (
                                              <img src={fave.favorite_user.user_photos[0].cropped_source} alt={fave.favorite_user.user_photos[0].name} className="w-[800px] h-full object-cover" />
                                          ) : null}
                                      </div>
                                      <div className="inline-block">
                                          <div className="flex justify-between items-center">
                                              <div>
                                                  <div className="flex items-center mt-15">
                                                      <div className="font-serif font-medium text-3xl max-w-fit mr-4">
                                                          {fave.favorite_user.donorCode}, {calculateAge(fave.favorite_user.dateOfBirth)}
                                                      </div>
                                                      <span
                                                          className={`text-secondary mt-1 ${
                                                              fave.favorite_user.freeze_member.status === "on_hold" && fave.favorite_user.sm_hold.user_id === loggedInUser.id
                                                                  ? "pointer-events-none"
                                                                  : "cursor-pointer"
                                                          }`}
                                                      >
                                                          <svg
                                                              onClick={() => toggleFavorite(fave.favorite_user.id)}
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="24"
                                                              height="24"
                                                              fill="#ED795E"
                                                              class="bi bi-heart-fill"
                                                              viewBox="0 0 16 16"
                                                          >
                                                              <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                                          </svg>
                                                      </span>
                                                  </div>
                                                  <div className="font-semibold text-s mt-1 mb-2">
                                                      {fave.favorite_user.answers.filter((ans) => ans.question.slug === "job_title")[0].answer.value.toUpperCase()}
                                                  </div>
                                              </div>
                                              <div className="flex items-center">
                                                  {fave.favorite_user.freeze_member.status === "on_hold" && fave.favorite_user.sm_hold.user_id === loggedInUser.id ? (
                                                      <button className="h-12 bg-yellow-500 text-black rounded-full py-2 px-6 font-semibold mt-10 mr-2 pointer-events-none">On hold</button>
                                                  ) : (
                                                      <button
                                                          onClick={() => placeHold(fave.favorite_user.id)}
                                                          className="h-12 bg-[#ED795E] text-black rounded-full py-2 px-6 font-semibold mt-10 mr-2 cursor-pointer"
                                                      >
                                                          Hold match
                                                      </button>
                                                  )}
                                                  <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      class="bi bi-question-circle mt-10 cursor-pointer"
                                                      viewBox="0 0 16 16"
                                                  >
                                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                                  </svg>
                                              </div>
                                          </div>
                                          <div className="flex items-center text-secondary mb-1">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill mr-3" viewBox="0 0 16 16">
                                                  <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z" />
                                                  <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z" />
                                              </svg>
                                              <span>
                                                  {handleString(fave.favorite_user.answers.filter((ans) => ans.question.slug === "education_level")[0].answer.value)},{" "}
                                                  {handleString(
                                                      fave.favorite_user.answers.filter((ans) => ans.question.slug === "school_ranking").length
                                                          ? fave.favorite_user.answers.filter((ans) => ans.question.slug === "school_ranking")[0].answer.value
                                                          : ""
                                                  )}
                                              </span>
                                          </div>
                                          <div className="flex items-center text-secondary mb-2">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill mr-3" viewBox="0 0 16 16">
                                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                              </svg>
                                              <span>{handleString(fave.favorite_user.answers.filter((ans) => ans.question.slug === "race")[0].answer.value.join(", "))}</span>
                                          </div>
                                          <div className="my-2 font-medium">Willingness to travel/Availability</div>
                                          <div className="my-2">{fave.favorite_user.freeze_member.travel_availability}</div>
                                          <div className="my-2 font-medium">About me</div>
                                          <div className="my-2">{fave.favorite_user.freeze_member.profile_bio}</div>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </div>
    );
};

export default Favorites;
