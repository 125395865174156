import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";

const Favorites = ({ user }) => {
    const [favorites, setFavorites] = useState([]);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/${user.id}/activity/favorites`,
                    method: "GET",
                });

                const { favorites } = response;

                setFavorites(favorites);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching favorites:", error);
                setHasError(error);
            }
        };

        fetchFavorites();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToIntendedParentPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleString = (s) => {
        if (!s) {
            return "";
        } else if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    return (
        <div className="mx-30 mb-10">
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-40 text-left py-2 border-b border-dashed border-gray-300">Favorite Date</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Parent Name</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Location</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Timeframe</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Meet Donor?</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Relationship Type</th>
                        <th className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">Donor Preference</th>
                    </tr>
                </thead>
                <tbody>
                    {favorites &&
                        favorites.map((fave) => {
                            return (
                                <tr key={fave.id}>
                                    <td className="w-40 text-left py-2 border-b border-dashed border-gray-300">{extractDate(fave.created_at)}</td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <div onClick={() => navigateToIntendedParentPage(fave.user_id)}>
                                            {fave.ip_user.firstName} {fave.ip_user.lastName}
                                        </div>
                                    </td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">{fave.ip_user.city}</td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">
                                        {fave.ip_user.intended_parent.family.ideal_timeframe ? (
                                            handleString(fave.ip_user.intended_parent.family.ideal_timeframe)
                                        ) : (
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        )}
                                    </td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">
                                        {fave.ip_user.intended_parent.family.donor_meeting ? (
                                            handleString(fave.ip_user.intended_parent.family.donor_meeting)
                                        ) : (
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        )}
                                    </td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">
                                        {fave.ip_user.intended_parent.family.donor_relationship ? (
                                            handleString(fave.ip_user.intended_parent.family.donor_relationship)
                                        ) : (
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        )}
                                    </td>
                                    <td className="w-1/5 text-left py-2 border-b border-dashed border-gray-300">
                                        {fave.ip_user.intended_parent.family.donor_preferences ? (
                                            handleString(fave.ip_user.intended_parent.family.donor_preferences)
                                        ) : (
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Favorites;
