import { useEffect } from "react";
import { GoogleSignupButton } from "./GoogleSignup";
import { SignupButton } from "./SignupButton";
import { useLocation } from "react-router-dom";

const FreezeSignupPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const firstName = queryParams.get("first_name");

    useEffect(() => {
        document.title = "Cofertility | Freeze Sign up";

        if (email === null) {
            window.location.href = `https://www.cofertility.com/freeze`;
        }
    }, [location, email]);

    const logo = () => {
        return <img className="pb-2" height="31" width="148" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/freeze/freeze-extension.svg`} />;
    };

    const mobileLogo = <img srcSet={`${process.env.PUBLIC_URL}/images/freeze/freeze-extension.svg`} className="block md:hidden w-45 pt-8 pb-16" alt="Mobile logo" />;

    const sidebar_image = () => {
        return (
            <img
                srcSet={`${process.env.PUBLIC_URL}/images/freeze_signup_image.jpeg 390w`}
                sizes="(min-width: 768px) 390px"
                className="hidden md:block md:w-sidebar md:h-screen object-cover object-[15%_center]"
                alt="Woman using Cofertility platform"
            />
        );
    };

    const signupMessage = () => {
        return (
            <div className="mb-4 md:mb-0">
                <h1 className="mb-3 display h2 md:h1">Congratulations, {firstName}, you’re eligible to apply for our Split program! </h1>
                <div className="text-sm">Create an account to continue.</div>
            </div>
        );
    };

    return (
        <div id="inner-body">
            <div id="app" className="pb-10 freeze">
                <div className="w-full md:h-screen md:w-sidebar md:fixed text-white-200">
                    <h1 className="absolute hidden md:block top-0 p-12 w-3/4">
                        <a href="https://www.cofertility.com">{logo()}</a>
                        <span>Family building options that put you first.</span>
                    </h1>
                    <div className="absolute hidden md:block bottom-0 p-12 w-3/4">
                        Have a question?
                        <br />
                        Email us at{" "}
                        <a href="mailto:support@cofertility.com" className="hover:text-freeze-secondary !text-white underline underline-offset-2">
                            support@cofertility.com
                        </a>
                    </div>
                    {sidebar_image()}
                </div>
                <div className="md:ml-sidebar md:h-screen flex-1 flex flex-col px-6">
                    <div className="md:mx-auto w-full md:max-w-md md:mt-29 flex flex-col justify-center relative">
                        <div className="bg-black md:bg-transparent bg-signup-mobile text-white-200 mb-10 md:bg-none bg-cover -mx-6 px-6 md:text-black flex flex-col">
                            {mobileLogo}
                            <div className="mb-5 h-8 w-8 mt-6 md:mt-0">
                                <div className="active" style={{ display: "none" }}>
                                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            {signupMessage()}
                        </div>
                        <div className="flex md:flex-col gap-8 flex-col-reverse">
                            <GoogleSignupButton />
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm min-w-full">
                                    <span className="px-4 py bg-white-200 border border-gray-300 text-gray-700 uppercase text-[10px] rounded-full font-bold">Or</span>
                                </div>
                            </div>
                            <div>
                                <SignupButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FreezeSignupPage;
