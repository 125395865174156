import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../App";

const DevSettings = ({ options }) => {
    const { loggedInUser, request, refreshUser } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (!loggedInUser) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    const resetEnvironment = async () => {
        try {
            const response = await request({
                url: `/staff/scripts/reset-environment`,
                method: "POST",
            });
        } catch (error) {
            // TODO: handle this error
            console.error("Error fetching applications:", error);
        }
    };

    if (!loggedInUser) {
        return null;
    }

    if (loggedInUser.email !== "sophie@cofertility.com") {
        return null;
    }

    return (
        <div className="flex">
            <button onClick={resetEnvironment}>Reset Environment</button>
        </div>
    );
};

export default DevSettings;
