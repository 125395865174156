import FAQ from "./FAQ";

const FamilyFAQ = () => {
    return (
        <FAQ
            backNav={"/family/donors"}
            title={"Family"}
            items={[
                {
                    question: "What is egg sharing?",
                    answer: "In traditional egg donation, all of the eggs retrieved in a given cycle are given to the intended parents in exchange for payment. However, in egg sharing, the donor has the opportunity to keep half of the eggs retrieved for their own future family planning in exchange for donating the other half to your family.\n\nAt Cofertility, we exclusively work under the egg sharing model, which we call Split, because we think it’s better for everyone involved – the intended parents, the egg donor, and ultimately the donor-conceived child.\n\nRead more about the Reasons Why Egg Sharing is an Ideal Egg Donation Model for Intended Parents",
                },
                {
                    question: "Tell me about your Baby Guarantee",
                    answer: "While some agencies require you to pay extra to receive any form of a guarantee, Cofertility’s baby guarantee is built into our pricing. We want to help you bring your baby home, and we’ll re-match you for free until that happens. \n\nWhat does this mean? If the donor doesn’t clear her screening… we’ll rematch you. If the cycle doesn’t lead to any blastocysts… we’ll rematch you. If none of the blastocysts turn into a pregnancy… we’ll rematch you. \n\nIn short, if at any point along your egg donation journey, something out of your control prevents you from bringing your baby home, we’ll re-match you for free or we’ll offer you a full or partial refund if you do not move forward with another donor. \n\nTo read more about Cofertility’s Baby Guarantee, including how it applies to our fresh and frozen egg donation programs, click here.",
                },
                {
                    question: "How do I place a hold on a match?",
                    answer: "When you’ve found a donor you want to move forward with, press the “Place a Hold” button at the top of her profile. From there, you’ll put down a $500 deposit to place a hold on that match. Placing a hold removes a donor from the platform and prevents her from matching with any other intended parents. Within 24 business hours, your Member Advocate will reach out to set up a kickoff call and discuss next steps.",
                },
                {
                    question: "How long does the matching and cycling process take?",
                    answer: "When you match with a donor in our fresh egg donation program, the process from match to cycle may generally be two to four months. Once you create a free account, you’ll gain immediate access to our matching platform and can start to learn more about our donors. When you’re ready to begin the matching process, you can place a hold on the match. As soon as the mutual match is confirmed, we can begin the process of scheduling her screening and testing with your clinic, which generally takes a few weeks. Once your doctor gives the go-ahead, we’d set a date for the cycle at a time that is mutually convenient.\n\nMatching with a donor in our frozen program can provide a unique opportunity to move forward with your family-building plans even faster, as frozen eggs can be fertilized or shipped to your clinic immediately or as soon as the cycle is complete. Time from match to cycle can be a lot faster with frozen since the screening – which is what takes the most time – is already completed. If you’re looking to transfer embryos ASAP, matching with a frozen donor can help make that happen.",
                },
                {
                    question: "What is the difference between fresh and frozen egg donation with Cofertility?",
                    answer: "With a fresh donation cycle, you match with the donor before she undergoes any screening. Her screening and retrieval will be done at a clinic of your choice. With a frozen donation cycle, the donor has already completed all of her screening and is either fully cleared to donate or, in some cases, has already completed her cycle. Her screening and retrieval will be done at one of Cofertility’s partner clinics. You can either thaw and fertilize the eggs there or have them shipped to your clinic. \n\nUltimately, the path that’s best for you depends on your timeline and family-building goals. To learn more about how to decide which path to pursue, check out this blog we’ve written on the topic.",
                },
                {
                    question: "Can you break down pricing for me?",
                    answer: "During a fresh egg donation journey with Cofertility, you will pay a match deposit, coordination fee, administrative expenses plus any medical expenses from your clinic associated with the screening, egg retrieval, and embryo creation processes. If your donor needs to travel for any part of this, you will be responsible for covering those costs.\n\nThis includes:\n\nMatch deposit: $500\nCofertility’s Coordination Fee: $7,500\nCycle administrative fees: $5,700 \nMedical expenses associated with the donor’s screening, medication, and retrieval - varies by clinic\n*estimate based on Cofertility’s recommended service providers\n\nIn a frozen egg donation journey with Cofertility, the number of eggs that you receive will vary based on the donor’s cycle outcomes. Pricing is based on a per-egg pricing model and the total cost of each match in turn varies based on the number of frozen eggs available. With our egg sharing model, you must keep half of the mature eggs retrieved. Meaning if 18 mature eggs are retrieved, you must keep all 9 frozen eggs available for donation.\n\nThe match cost is inclusive of the match deposit and all expenses associated with the coordination of the cycle and with the donor’s screening, retrieval, and medications. It’s the all-in cost of getting frozen eggs.\n\nPrice per frozen egg included:\n\n6-8 ----- $3,500\n\n9-11 ----- $3,300\n\n12+ ----- $3,100\n\nFor example, if the donor has 7 frozen eggs available the cost would be $24,500. If she has 9 frozen eggs available the cost would be $29,700. \n\nFor a more detailed breakdown, view our pricing page.",
                },
                {
                    question: "Do I have to be working with a clinic already?",
                    answer: "Nope! We’re excited to work with you regardless of where you are in the family-building journey. Whether you’re just starting the process or have an established relationship with a clinic, we’d love to walk the rest of the road with you. Additionally, our team is happy to help you find a clinic to work with if you are still searching.",
                },
                {
                    question: "Will I get enough eggs?",
                    answer: "At Cofertility, we exclusively work under the egg sharing model. Rather than cash compensation, our donors keep half of the eggs retrieved and donate the other half to your family. We believe this model benefits everyone involved, particularly the donor-conceived child.\n\nEgg share programs like ours implement meticulous testing and medical screening to ensure donors have a high likelihood of success. For those who match with a donor undergoing a fresh donation cycle, the average number of mature eggs a family receives and fertilizes is 12. The number of eggs retrieved varies by patient and cycle, but can be predicted by a donor’s age, AMH, and antral follicle count, all of which will be known to you after the donor’s initial screening. Egg share donors also often work closely with a fertility doctor to determine, based on their own medical history, the optimal number of eggs needed for their own future family-building goals. Should it make sense for the donor, they may choose to pursue a second egg-sharing cycle to maximize the chances of success for everyone involved.\n\nDonors undergoing frozen donation cycles complete equally rigorous ovarian reserve testing. While the total number of frozen eggs available will vary based on the donor’s retrieval outcomes, every frozen match is guaranteed to have a minimum of at least 6 frozen eggs. \n\nTo learn more, click here to download our 'Egg Sharing: Will I get enough eggs?' guide.\n\n*Note: this number is updated periodically based on cycle outcomes. ",
                },
                {
                    question: "What are my disclosure options?",
                    answer: "In a disclosed donation with Cofertility, you and the donor will exchange contact information and can communicate directly. In an undisclosed donation, you will not exchange contact information and only communicate via Cofertility. With an undisclosed donation, you could arrange to have the information available to your child down the road (this is sometimes called Open ID). \n\nWe highly encourage disclosed donations as we’ve met with the US Donor Conceived Council and heard from the donor conceived community that disclosed is best for the donor conceived person. However, if you choose to move forward with an undisclosed donation after careful consideration, we will honor that decision. \n\n‍Read more in Disclosed vs. Undisclosed Donation: What's the Difference?",
                },
                {
                    question: "Can you help me find a clinic?",
                    answer: "Absolutely. While we do not have any exclusive clinic relationships, we pride ourselves on working with a range of clinics whose clinical teams deliver high quality, compassionate care. Get in touch and we’ll be happy to connect you with one in your area.",
                },
                {
                    question: "When does the donor undergo genetic testing?",
                    answer: "For donors who are completing a fresh donation cycle, we will work with your clinic to run a genetic screening for your donor upon matching. Their results, along with the intended father or sperm source’s genetic testing results, will be evaluated by a genetic counselor to ensure there is no genetic issue or mismatch between the two.\n\nDonors who are completing a frozen donation cycle will complete genetic carrier screening in advance of their retrieval. Once you place a match, we can share all of those records with you and your physician.",
                },
            ]}
        />
    );
};

export default FamilyFAQ;
