import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const EditRequests = () => {
    const [editRequests, setEditRequests] = useState([]);
    const [hasError, setHasError] = useState(null);
    // TODO: redo as individual states setting values to asc/desc
    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Staff | IP | Edit Requests";
        const fetchEditRequests = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/edit-requests`,
                    method: "GET",
                });

                const { editRequests } = response;

                setEditRequests(editRequests);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchEditRequests();
    }, [request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/intended-parents/${userId}`);
    };

    return (
        <table className="table-fixed w-full divide-y divide-dotted">
            <thead>
                <tr>
                    <th className="w-1/7 relative">Submitted</th>
                    <th className="w-1/7 relative">Donor Name</th>
                    <th className="w-1/7 relative">Section</th>
                    <th className="w-1/7 relative">Field</th>
                    <th className="w-1/7 relative">Edit</th>
                    <th className="w-1/7 relative">Status</th>
                    <th className="w-1/7 relative">Actions</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-dotted">
                {editRequests &&
                    editRequests.map((request) => {
                        return (
                            <tr key={request.id}>
                                <td className="w-1/7 text-center">{new Date(request.created_at).toLocaleString()}</td>
                                <td className="w-1/7 text-center text-secondary">
                                    <div onClick={() => navigateToSplitMemberPage(request.userId)}>
                                        {request.user.firstName} {request.user.lastName}
                                    </div>
                                </td>
                                <td className="w-1/7 text-center">{request.section}</td>
                                <td className="w-1/7 text-center">{request.field}</td>
                                <td className="w-1/7 text-center">
                                    <div>Current value: {request.current_value.value}</div>
                                    <div>Requested value: {request.requested_value.value}</div>
                                </td>
                                <td className="w-1/7 text-center">{request.status}</td>
                                <td className="ml-5 w-1/7 text-center">
                                    <button className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600 w-22 h-12 mx-1">Reject</button>
                                    <button className="bg-secondary text-white py-2 px-4 rounded-full hover:text-primary w-18 h-12 mx-1">Approve</button>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

export default EditRequests;
