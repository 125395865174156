import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AppContext } from "../../../../App";

const ClinicIPs = ({ clinicId }) => {
    const [intendedParents, setIntendedParents] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [loginDateRange, setLoginDateRange] = useState([null, null]);
    const [joinDateRange, setJoinDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("last_login");
    const [orderDir, setOrderDir] = useState("desc");
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [hasError, setHasError] = useState(null);
    const statusDropdownRef = useRef(null);
    const navigate = useNavigate();
    const { request } = useContext(AppContext);

    const statuses = ["application_in_progress"];

    useEffect(() => {
        const fetchIntendedParents = async () => {
            try {
                let filterString = "";
                if (filters.length) {
                    filters.forEach((filter) => {
                        if (filter.name.includes("range")) {
                            if (filter.value[0] !== null) {
                                filterString += `&${filter.name}_min=${filter.value[0]}`;
                            }
                            if (filter.value[1] !== null) {
                                filterString += `&${filter.name}_max=${filter.value[1]}`;
                            }
                        } else if (filter.name === "status") {
                            filter.value.forEach((v) => {
                                filterString += `&${filter.name}=${v}`;
                            });
                        } else {
                            filterString += `&${filter.name}=${filter.value}`;
                        }
                    });
                }

                const response = await request({
                    url: `/staff/intended-parents?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}&clinic_id=${clinicId}`,
                    method: "GET",
                });

                const { intendedParents } = response;

                setHasMore(intendedParents.length > 0);
                if (page !== 1) {
                    setIntendedParents((prev) => [...prev, ...intendedParents]);
                } else {
                    setIntendedParents(intendedParents);
                }
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchIntendedParents();
    }, [request, page, orderBy, orderDir, filters, clinicId]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    const navigateToIntendedParentPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop === "last_name") {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleNameSearch = (event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "name");

        if (search.length) {
            filteredFilters.push({
                name: "name",
                value: search,
            });

            window.history.replaceState(null, "", `${window.location.pathname.includes("?") ? `${window.location.pathname}&name=${search}` : `${window.location.pathname}?name=${search}`}`);
        } else {
            window.history.replaceState(null, "", window.location.pathname.replace("?name=", ""));
            window.history.replaceState(null, "", window.location.pathname.replace("&name=", ""));
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleStatusClick = (status) => {
        let ns = [];
        if (selectedStatuses.includes(status)) {
            ns = structuredClone(selectedStatuses).filter((s) => s !== status); // Remove if already selected
        } else {
            ns = [...structuredClone(selectedStatuses), status]; // Add if not selected
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        if (ns.length) {
            filteredFilters.push({
                name: "status",
                value: ns,
            });
        }
        setPage(1);
        setFilters(filteredFilters);
        setSelectedStatuses(ns);
    };

    const clearAllStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        setPage(1);
        setFilters(filteredFilters);
        setSelectedStatuses([]);
    };

    return (
        <div className="mx-30 mb-20">
            <div className="mb-5 ml-auto">
                <div className="p-3">
                    <input className="p-1 inline-block border border-solid ml-2 rounded-lg" onChange={handleNameSearch} placeholder="Parent name..."></input>
                    <div className="p-1 inline-block border border-solid ml-2 rounded-lg">
                        <DatePicker
                            selected={loginDateRange[0]}
                            onChange={(update) => {
                                handleDateFilter("login_range", update, setLoginDateRange);
                            }}
                            selectsRange
                            isClearable
                            startDate={loginDateRange[0]}
                            endDate={loginDateRange[1]}
                            placeholderText="Login range"
                        />
                    </div>
                    <div className="p-1 inline-block border border-solid ml-2 rounded-lg">
                        <DatePicker
                            selected={joinDateRange[0]}
                            onChange={(update) => {
                                handleDateFilter("join_range", update, setJoinDateRange);
                            }}
                            selectsRange
                            isClearable
                            startDate={joinDateRange[0]}
                            endDate={joinDateRange[1]}
                            placeholderText="Join range"
                        />
                    </div>
                    <div className="relative p-1 inline-block ml-2 rounded-lg" ref={statusDropdownRef}>
                        <button
                            onClick={() => setStatusDropdownOpen(!statusDropdownOpen)}
                            className="py-1 px-2 rounded-md border bg-white text-gray-700" // Style as needed
                        >
                            Filter by Status{" "}
                            <span className="mx-2" onClick={clearAllStatuses}>
                                x
                            </span>
                        </button>

                        {statusDropdownOpen && (
                            <div className="absolute mt-2 w-full left-0 right-0 bg-white rounded-md shadow-lg divide-y divide-dotted">
                                {statuses.map((status) => (
                                    <div key={status} onClick={() => handleStatusClick(status)} className={`px-4 py-2 cursor-pointer ${selectedStatuses.includes(status) ? "bg-gray-100" : ""}`}>
                                        {status}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("last_login")} className="flex items-center">
                                <span className="mr-1">Last Login</span>
                                <span className="flex items-center">
                                    {orderBy === "last_login" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_login" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Join Date</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("last_name")} className="flex items-center">
                                <span className="mr-1">Intended Parent</span>
                                <span className="flex items-center">
                                    {orderBy === "last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Status</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {intendedParents &&
                        intendedParents.map((member) => {
                            return (
                                <tr key={member.id} className="w-full">
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(member.lastLogin)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(member.createdAt)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span onClick={() => navigateToIntendedParentPage(member.id)}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {member.firstName.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">
                                                {member.firstName} {member.lastName}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                            {member.intended_parent.family.status
                                                .split("_")
                                                .map((word) => word[0].toUpperCase() + word.substring(1))
                                                .join(" ")}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default ClinicIPs;
