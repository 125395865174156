import React from "react";

export const Filter = ({ children, isStaff, filterRef, clearAllFilters, showFilters, setShowFilters, haveFilters }) => {
    return (
        <div
            ref={isStaff ? filterRef : null}
            className={
                isStaff
                    ? `fixed w-[450px] p-5 inset-y-0 right-0 border-l border-solid border-black bg-[#F5F5F5] shadow-lg transform transition-transform duration-300 translate-x-0 overflow-y-auto !text-[#191919]`
                    : `fixed w-[400px] p-5 inset-y-0 top-20 left-0 pb-5 border-l border-solid border-black bg-[#F5F5F5] shadow-lg transform transition-transform duration-300 translate-x-0 overflow-y-auto !text-[#191919]`
            }
        >
            {isStaff ? (
                <div className="flex justify-between">
                    <h1>Filter by</h1>
                    {haveFilters ? (
                        <button
                            className="bg-white text-secondary border border-secondary rounded-lg px-2 w-24 h-10 hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                            onClick={() => clearAllFilters()}
                        >
                            Clear all
                        </button>
                    ) : null}
                    <span className="cursor-pointer" onClick={() => setShowFilters(!showFilters)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </span>
                </div>
            ) : (
                <div className="flex justify-between">
                    <h1 className="flex items-center">
                        <svg className="mr-[5px]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 16.25L12 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 16.25L3 16.25" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21 8L16.5 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8L3 8" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 19.25L7.5 12.5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.5 11.75L16.5 5" stroke="#191919" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Filter by</span>
                    </h1>
                    {haveFilters ? (
                        <button
                            className="bg-white text-secondary border border-secondary rounded-lg px-2 w-24 h-10 hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                            onClick={() => clearAllFilters()}
                        >
                            Clear all
                        </button>
                    ) : null}
                </div>
            )}
            {children}
        </div>
    );
};
