import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import AptitudeChart from "./AptitudeChart";
import { AppContext } from "../../App";
import { DegreeTypesMap, EthnicitiesMap, FriendlySlugTranslation, RaceMap, SchoolRanking, SchoolRankingMap } from "../../util/helpers";

const PublicProfile = ({ userId, staff = false, owner = false, ip = false, viewPhotos = null }) => {
    const [user, setUser] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [amh, setAMH] = useState("");
    const [answers, setAnswers] = useState(null);
    const [groups, setGroups] = useState(null);
    const [famMedHist, setFamMedHist] = useState(null);
    const [favorited, setFavorited] = useState(false);
    const [currentSection, setCurrentSection] = useState("personality");
    const [changingSections, setChangingSections] = useState(false);
    const [stickyTabs, setStickyTabs] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [updatedAnswer, setUpdatedAnswer] = useState("");
    const [modalData, setModalData] = useState(null);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);

    const navigate = useNavigate();
    const params = useParams();

    const persRef = useRef(null);
    const physAttrsRef = useRef(null);
    const eduWorkRef = useRef(null);
    const famAttrRef = useRef(null);
    const fertRef = useRef(null);
    const medHistRef = useRef(null);
    const famMedHistRef = useRef(null);
    const tabBarRef = useRef(null);
    const editModalRef = useRef(null);

    const swipeHandlers = useSwipeable({
        onSwiped: (eventData) => {
            const { dir } = eventData;
            if (dir === "Left") {
                handleCarouselNext();
            } else if (dir === "Right") {
                handleCarouselPrev();
            }
        },
    });

    if (!userId) {
        userId = params.userId;
    }

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await request({
                    url: `/split-members/${userId}/profile`,
                    method: "GET",
                });

                const { user, answers, groupQs, photos, famMedHist, amh, favorited } = response;

                setUser(user);
                setAnswers(answers);
                setGroups(groupQs);
                setFamMedHist(famMedHist);
                setPhotos(photos);
                setAMH(amh);
                setFavorited(favorited);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching profile:", error);
                setHasError(error);
            }
        };

        fetchProfile();
    }, [request, userId, refreshKey]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (tabBarRef.current) {
                const rect = tabBarRef.current.getBoundingClientRect();

                const shouldBeSticky = rect.top <= 0;

                // Update the state ONLY if it has changed
                if (shouldBeSticky !== stickyTabs) {
                    setStickyTabs(shouldBeSticky);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [stickyTabs]);

    useEffect(() => {
        const scrollSection = (ref, name) => {
            if (ref.current && !changingSections) {
                const rect = ref.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                // Check if the top of the section is above the viewport's halfway point
                const isViewed = rect.top >= viewportHeight / 2 && rect.top < viewportHeight / 2 + 50;

                if (isViewed && name !== currentSection) {
                    setCurrentSection(name);
                }
            }
        };

        const handleScroll = () => {
            scrollSection(persRef, "personality");
            scrollSection(physAttrsRef, "phys-attr");
            scrollSection(eduWorkRef, "edu-work-skills");
            scrollSection(famAttrRef, "fam-attr");
            scrollSection(fertRef, "fertility");
            scrollSection(medHistRef, "med-hist");
            scrollSection(famMedHistRef, "fam-med-hist");
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [currentSection, changingSections]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (editModalRef.current && !editModalRef.current.contains(event.target)) {
                closeEdit();
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleEditAnswer = (event) => {
        setUpdatedAnswer(event.target.value);
    };

    const openEdit = (qa, group = false, valOverride = null, taOverride = null, labelOverride = null, type = null, props = null, keys = null) => {
        setModalData({
            questionAnswer: qa,
            useGroup: group,
            valOverride,
            labelOverride,
            type,
            props,
            keys,
        });

        if (taOverride) {
            setUpdatedAnswer(taOverride);
        } else {
            setUpdatedAnswer(valOverride ? valOverride : formatString({ answer: qa.answer.value, type, keys, props, textArea: true }));
        }

        setShowEditModal(true);
    };

    const closeEdit = () => {
        setModalData(null);
        setUpdatedAnswer("");
        setShowEditModal(false);
    };

    const saveEditRequest = async (group = false) => {
        console.log(modalData);
        try {
            await request({
                url: `/split-members/${userId}/edit-request`,
                method: "POST",
                data: {
                    answer_id: modalData.questionAnswer.answer_id,
                    application_id: modalData.questionAnswer.application_id,
                    group_id: group ? modalData.questionAnswer.group_id : null,
                    updated_answer: updatedAnswer,
                    flow: modalData.questionAnswer.flow,
                },
            });

            closeEdit();
        } catch (err) {
            console.log(err);
        }
    };

    const formatString = ({ answer, type = null, keys = false, props = null, textArea = false }) => {
        if (!answer) {
            return <span className="italic text-gray-300 font-normal">None</span>;
        }

        if (type !== null) {
            if (type === "obj_array") {
                let final = [];
                let ta = "";
                answer.forEach((a, i) => {
                    let result = "";
                    let divs = [];
                    props.forEach((p, j) => {
                        if (!a[p]) {
                            return;
                        }

                        let bit = ``;
                        if (p === "degree_type") {
                            bit = `${keys ? `${formatString({ answer: p })}: ` : ""}${formatString({ answer: DegreeTypesMap[a[p]] })}`;
                        } else {
                            bit = `${keys ? `${formatString({ answer: p })}: ` : ""}${formatString({ answer: a[p] })}`;
                        }

                        divs.push(<div>{bit}</div>);
                        if (j === 0) {
                            result += bit;
                            return;
                        }

                        if (keys) {
                            result += `\r\n${bit}`;
                        } else {
                            result += ` - ${bit}`;
                        }
                    });
                    if (i === 0) {
                        ta += `${result}`;
                    } else {
                        ta += `\r\n\r\n${result}`;
                    }

                    if (keys) {
                        final.push(<div className="my-2">{divs}</div>);
                    } else {
                        final.push(<div>{result}</div>);
                    }
                });

                if (textArea) {
                    return ta;
                }

                return final;
            } else if (type === "family") {
                let final = [];
                let ta = "";
                props.forEach((p, i) => {
                    if (i === 0 && answers[p]) {
                        ta += `${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`}</div>);
                        return;
                    } else if (answers[p]) {
                        ta += `\r\n${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`}</div>);
                    }
                });
                final.push(<br />);
                if (textArea) {
                    return ta;
                }

                return final;
            } else if (type === "sibling") {
                let final = [];
                let ta = "";
                props.forEach((p, i) => {
                    if (i === 0 && answer[p]) {
                        ta += `${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`}</div>);
                        return;
                    } else if (answer[p]) {
                        ta += `\r\n${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`}</div>);
                    }
                });
                final.push(<br />);
                if (textArea) {
                    return ta;
                }

                return final;
            }
        }

        if (Array.isArray(answer)) {
            return answer
                .map((sen, i) => {
                    let remUnd = sen.split("_").join(" ");
                    if (i === 0) {
                        return remUnd[0].toUpperCase() + remUnd.slice(1);
                    }

                    return remUnd;
                })
                .join(", ");
        }

        if (typeof answer !== "string") {
            return answer;
        }

        if (answer in FriendlySlugTranslation) {
            return FriendlySlugTranslation[answer];
        }

        answer = answer.replace("death_age", "age_of_death_and_cause");

        answer = answer
            .split(" - ")
            .map((w) => (w[0] ? w[0].toUpperCase() + w.slice(1) : ""))
            .join(" - ");
        let split = answer.split(".");
        let capped = split.map((sen, i) => {
            let remUnd = sen.split("_").join(" ");
            if (i === 0) {
                return remUnd[0].toUpperCase() + remUnd.slice(1);
            }

            return remUnd;
        });
        return capped.join(". ");
    };

    const handleCopy = async (toCopy) => {
        await navigator.clipboard.writeText(toCopy);
    };

    const headerQuestion = (answer, group = false, labelOverride = null) => {
        if (!answer) {
            return <div></div>;
        }

        return (
            <div className="mb-8">
                <div className="flex md:items-center">
                    <h3 className="italic">{labelOverride ? labelOverride : answer.question.label}</h3>
                    {editPencil({
                        answer,
                        group,
                        labelOverride,
                        style: "mb-3 ml-5 mt-3 md:mt-0",
                    })}
                </div>
                <div className="whitespace-pre-line">{formatString({ answer: answer.answer.value })}</div>
            </div>
        );
    };

    const simpleQuestion = (answer, group = false, valOverride = null, labelOverride = null, type = null, props = null) => {
        return (
            <div className="mb-5">
                <div className="flex items-center">
                    <div className="font-semibold">{labelOverride ? labelOverride : answer.question.label}</div>
                    {editPencil({
                        answer,
                        group,
                        valOverride,
                        labelOverride,
                        type,
                        props,
                        style: "ml-2",
                    })}
                </div>
                <div>
                    {formatString({
                        answer: valOverride ? valOverride : answer.answer.value,
                        type,
                        props,
                    })}
                </div>
            </div>
        );
    };

    const conditionalQuestion = (conditional, answer = null, group = false, valOverride = null, labelOverride = null, type = null, props = null) => {
        return (
            <div className="mb-5">
                <div className="flex items-center">
                    <div className="font-semibold">{labelOverride ? labelOverride : answer.question.label}</div>
                    {editPencil({
                        answer,
                        group,
                        valOverride,
                        labelOverride,
                        type,
                        props,
                        style: "ml-2",
                    })}
                </div>
                <div className="mt-2">
                    {!conditional || conditional.answer.value === "no" ? (
                        <span className="italic text-gray-300 font-normal">No</span>
                    ) : (
                        formatString({
                            answer: valOverride ? valOverride : answer ? answer.answer.value : null,
                            type,
                            props,
                        })
                    )}
                </div>
            </div>
        );
    };

    const personalitySection = () => {
        return (
            <div id="personality" class="pt-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Personality & Motivations</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-x-16 lg:grid grid-cols-8 gap-y-12 mt-10 md:mt-5">
                            <div class="col-span-4 border border-black rounded-lg text-center bg-tertiary-light relative bg-freeze-category bg-cover py-12 flex flex-col gap-y-3.5">
                                <h4 class="bg-white border border-black rounded-full italic px-4 py-1 shadow-medium absolute -top-5 ml-1/2 -translate-x-1/2">My personality</h4>
                                <div class="flex flex-col my-auto">
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["logical_creative"] && answers["logical_creative"].answer.value === "logical" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Logical
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["logical_creative"] && answers["logical_creative"].answer.value === "creative" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Creative
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["serious_silly"] && answers["serious_silly"].answer.value === "serious" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Serious
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["serious_silly"] && answers["serious_silly"].answer.value === "silly" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Silly
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["introvert_extrovert"] && answers["introvert_extrovert"].answer.value === "introverted" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Introvert
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["introvert_extrovert"] && answers["introvert_extrovert"].answer.value === "extroverted" ? "bg-primary" : "bg-gray-100"
                                            }`}
                                        >
                                            Extrovert
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-4 border border-black rounded-lg text-center bg-tertiary-light relative bg-freeze-category bg-cover py-12 flex flex-col gap-y-3.5">
                                <h4 class="bg-white border border-black rounded-full italic px-4 py-1 shadow-medium absolute -top-5 ml-1/2 -translate-x-1/2">My favorites</h4>
                                <div class="flex flex-col my-auto gap-y-10 md:px-10">
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_movie.svg`} />
                                        <div class="text-left">{answers["movie"] ? formatString({ answer: answers["movie"].answer.value }) : null}</div>
                                    </div>
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_book.svg`} />
                                        <div class="text-left">{answers["book"] ? formatString({ answer: answers["book"].answer.value }) : null}</div>
                                    </div>
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_food.svg`} />
                                        <div class="text-left">{answers["food"] ? formatString({ answer: answers["food"].answer.value }) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {headerQuestion(answers["passions"])}

                        {headerQuestion(answers["goals_in_life"])}

                        {headerQuestion(answers["greatest_strengths"])}

                        {headerQuestion(answers["perfect_day"])}

                        {headerQuestion(answers["dinner_party"], false, "If you were planning a dinner and could invite any three people (living or dead, famous or not), who would they be and why?")}
                    </div>
                </div>
            </div>
        );
    };

    const physicalAttributesSection = () => {
        return (
            <div id="phys-attr" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/physical_attributes_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Physical attributes</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col lg:grid grid-cols-8 w-3/4 gap-y-4 mt-10 md:mt-0">
                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Eye color", answers["eye_color"], false, null, true)}
                                    {attributeValueColumn("Complexion", answers["complexion"], false, null, true)}
                                    {attributeValueColumn("Hair color", answers["hair_color"], false, null, true)}
                                    {attributeValueColumn("Hair style", answers["hair_type"], false, null, true)}
                                    {attributeValueColumn("Hair texture", answers["hair_texture"], false, null, true)}
                                </div>
                            </div>

                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Height", answers["height_ft"], true, `${answers["height_ft"].answer.value}'${answers["height_in"].answer.value}"`)}
                                    {attributeValueColumn("Weight", answers["weight"], false, `${answers["weight"].answer.value} lbs`)}
                                    {attributeValueColumn("Dominant hand", answers["dominant_hand"])}
                                    {attributeValueColumn("Freckles", answers["freckles"])}
                                    {attributeValueColumn("Dimples", answers["dimples"])}
                                    {attributeValueColumn("BMI", answers["elig_bmi"])}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const eduWorkSkillsSection = () => {
        let formattedJobTitle = "";
        if (answers["job_title"] && answers["job_field"]) {
            formattedJobTitle = `${answers["job_title"].answer.value} - ${answers["job_field"].answer.value}`;
        } else if (answers["job_title"]) {
            formattedJobTitle = answers["job_title"].answer.value;
        }
        return (
            <div id="eduskill" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/education_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Education, work & skills</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-x-16 mt-10 md:mt-0">
                            {answers["education_level"] ? simpleQuestion(answers["education_level"], false, null, "Education") : null}

                            {answers["education_history"] ? simpleQuestion(answers["education_history"], false, null, "Degrees/Major", "obj_array", ["degree_type", "specialty_or_major"]) : null}

                            {formattedJobTitle !== "" ? simpleQuestion(answers["job_title"], true, formattedJobTitle, "Current occupation & field", null) : null}

                            {conditionalQuestion(answers["past_occupations"], answers["previous_jobs"], false, null, "Occupation history", "obj_array", ["job_title", "job_field"])}

                            {answers["achievements"] ? simpleQuestion(answers["achievements"], false, null, "Awards & achievements") : null}

                            {conditionalQuestion(answers["volunteer_work"], answers["volunteer_history"], false, null, "Completed or currently involved in any volunteer work?", "obj_array", [
                                "volunteer_work",
                            ])}
                        </div>
                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {conditionalQuestion(answers["musical_ability"], answers["describe_musical_ability"], false, null, "Do you play any musical instruments or sing?")}

                        {conditionalQuestion(answers["langs_not_english"], answers["languages"], false, null, "Languages", "obj_array", ["language"])}

                        {/* TODO: aptitude scale */}
                        {AptitudeChart([
                            { name: "Athleticism", value: answers["athletic_ability"] ? answers["athletic_ability"].answer.value : 0 },
                            { name: "Singing", value: answers["singing_ability"] ? answers["singing_ability"].answer.value : 0 },
                            { name: "Art", value: answers["artistic_ability"] ? answers["artistic_ability"].answer.value : 0 },
                            { name: "Science", value: answers["scientific_ability"] ? answers["scientific_ability"].answer.value : 0 },
                            { name: "Math", value: answers["mathematical_ability"] ? answers["mathematical_ability"].answer.value : 0 },
                        ])}
                    </div>
                </div>
            </div>
        );
    };

    const familyAttributesSection = () => {
        return (
            <div id="fam-attr" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/family_attributes_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Family attributes</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-y-4 lg:grid grid-cols-8 gap-x-6 mt-10 md:mt-0">
                            <div class="bg-tertiary-light col-span-4 border border-black rounded-md flex flex-col">
                                <div className="flex justify-between">
                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                        <div class="h-3 w-3 bg-tertiary border border-black rounded-full"></div>
                                        <span>Mother</span>
                                    </div>
                                    {editPencil({
                                        answer: answers["mother_eye_color"],
                                        group: true,
                                        type: "family",
                                        props: [
                                            "mother_age",
                                            "mother_death_age",
                                            "mother_body_frame",
                                            "mother_height_ft",
                                            "mother_height_in",
                                            "mother_eye_color",
                                            "mother_hair_color",
                                            "mother_complexion",
                                        ],
                                        style: "m-5",
                                    })}
                                </div>
                                <div class="flex flex-col gap-y-4 lg:grid grid-cols-4 grid-rows-3 gap-x-6 grid-flow-col border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Age</p>
                                        <p class="text-sm flex gap-2">{answers["mother_age"] ? answers["mother_age"].answer.value : `Deceased - ${answers["mother_death_age"].answer.value}`}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                        <p class="text-sm flex gap-2">{formatString({ answer: answers["mother_body_frame"].answer.value.replace("_", "/") })}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Height</p>
                                        <p class="text-sm flex gap-2">
                                            {answers["mother_height_ft"].answer.value}'{answers["mother_height_in"].answer.value}"
                                        </p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_eye_color"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["mother_eye_color"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["mother_eye_color"].answer.value })}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_hair_color"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["mother_hair_color"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["mother_hair_color"].answer.value })}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_complexion"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["mother_complexion"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["mother_complexion"].answer.value })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-tertiary-light col-span-4 border border-black rounded-md flex flex-col">
                                <div className="flex justify-between">
                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                        <div class="h-3 w-3 bg-tertiary border border-black rounded-full"></div>
                                        <span>Father</span>
                                    </div>
                                    {editPencil({
                                        answer: answers["father_eye_color"],
                                        group: true,
                                        type: "family",
                                        props: [
                                            "father_age",
                                            "father_death_age",
                                            "father_body_frame",
                                            "father_height_ft",
                                            "father_height_in",
                                            "father_eye_color",
                                            "father_hair_color",
                                            "father_complexion",
                                        ],
                                        style: "m-5",
                                    })}
                                </div>
                                <div class="flex flex-col gap-y-4 lg:grid grid-cols-4 grid-rows-3 gap-x-6 grid-flow-col border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Age</p>
                                        <p class="text-sm flex gap-2">{answers["father_age"] ? answers["father_age"].answer.value : `Deceased - ${answers["father_death_age"].answer.value}`}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                        <p class="text-sm flex gap-2">{formatString({ answer: answers["father_body_frame"].answer.value.replace("_", "/") })}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Height</p>
                                        <p class="text-sm flex gap-2">
                                            {answers["father_height_ft"].answer.value}'{answers["father_height_in"].answer.value}"
                                        </p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_eye_color"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["father_eye_color"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["father_eye_color"].answer.value })}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_hair_color"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["father_hair_color"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["father_hair_color"].answer.value })}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                        <div className="flex items-center">
                                            <img
                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_complexion"].answer.value}.svg`}
                                                className="inline-block w-3 h-3 mr-2"
                                                alt={`${answers["father_complexion"].answer.value}-eye`}
                                            />
                                            <p class="text-sm flex gap-2">{formatString({ answer: answers["father_complexion"].answer.value })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {answers["siblings"].answer.value === "yes" ? (
                            <div>
                                <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                                <div class="flex overflow-x-auto mt-5">
                                    <div className="flex flex-nowrap gap-x-6">
                                        {answers["sibling_details"].answer.value.map((s) => (
                                            <div class="bg-freeze-secondary-50 w-card col-span-3 flex-none border border-black rounded-md flex flex-col">
                                                <div className="flex justify-between">
                                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                                        <div class="h-3 w-3 bg-freeze-secondary-200 border border-black rounded-full"></div>
                                                        <span>Sibling</span>
                                                    </div>
                                                    {editPencil({
                                                        answer: answers["eye_color"],
                                                        group: true,
                                                        type: "sibling",
                                                        valOverride: formatString({
                                                            answer: s,
                                                            type: "sibling",
                                                            props: [
                                                                "sibling_age",
                                                                "sibling_death_age",
                                                                "sex_at_birth",
                                                                "half_or_full_sibling",
                                                                "height_ft",
                                                                "height_in",
                                                                "body_frame",
                                                                "eye_color",
                                                                "hair_color",
                                                                "complexion",
                                                            ],
                                                        }),
                                                        taOverride: formatString({
                                                            answer: s,
                                                            type: "sibling",
                                                            props: [
                                                                "sibling_age",
                                                                "sibling_death_age",
                                                                "sex_at_birth",
                                                                "half_or_full_sibling",
                                                                "height_ft",
                                                                "height_in",
                                                                "body_frame",
                                                                "eye_color",
                                                                "hair_color",
                                                                "complexion",
                                                            ],
                                                            textArea: true,
                                                        }),
                                                        style: "m-5",
                                                    })}
                                                </div>
                                                <div class="flex flex-col gap-x-6 gap-y-4 grid-flow-col  border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Age</p>
                                                        <p class="text-sm flex gap-2">{s.sibling_age ? s.sibling_age : `Deceased - ${s.sibling_death_age}`}</p>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Sex at birth</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.sex_at_birth })}</p>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Half or full sibling</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.half_or_full_sibling })}</p>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Height</p>
                                                        <p class="text-sm flex gap-2">
                                                            {s.height_feet}'{s.height_inches}"
                                                        </p>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.body_frame.replace("_", "/") })}</p>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.eye_color}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.eye_color}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.eye_color })}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.hair_color}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.hair_color}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.hair_color })}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.complexion}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.complexion}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.complexion })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="col-span-8">
                            <div class="flex flex-col my-auto gap-y-4">
                                {singleAttributeValueColumn("Ma. grandmother age", answers["maternal_grandmother_living"], false, getGrandparentStatus("maternal_grandmother"))}
                                {singleAttributeValueColumn("Ma. grandfather age", answers["maternal_grandfather_living"], false, getGrandparentStatus("maternal_grandfather"))}
                                {singleAttributeValueColumn("Pa. grandmother age", answers["paternal_grandmother_living"], false, getGrandparentStatus("paternal_grandmother"))}
                                {singleAttributeValueColumn("Pa. grandfather age", answers["paternal_grandfather_living"], false, getGrandparentStatus("paternal_grandfather"))}
                            </div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="col-span-8">
                            <div class="flex flex-col my-auto gap-y-4">
                                {singleAttributeValueColumn("Marital status", answers["marital_status"])}
                                {singleAttributeValueColumn("Religion", answers["religion"])}
                                {singleAttributeValueColumn("Jewish ancestry", answers["jewish_ancestry"])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const fertilitySection = () => {
        return (
            <div id="phys-attr" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/fertility_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Fertility</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="col-span-8 mt-10 md:mt-0">
                            <div class="flex flex-col my-auto gap-y-4">
                                <div class="w-full md:grid md:grid-cols-4 text-sm md:w-full ">
                                    <div class="md:col-span-1 text-left font-semibold">AMH Level</div>
                                    <div class="md:col-span-3 flex items-center">
                                        <div class="text-left">{amh}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {answers["egg_retrieval_history"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable(
                            "Past egg retrieval cycles",
                            answers["egg_retrieval_history"],
                            ["Personal/Donation", "Retrieval date", "# of eggs retrieved", "Clinic name", "Program name"],
                            ["freeze_cycle_reason", "egg_retrieval_history_date", "egg_retrieval_history_count", "egg_retrieval_history_clinic", "egg_retrieval_history_program"]
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const medicalHistory = () => {
        return (
            <div id="phys-attr" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/medical_history_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Medical history</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="col-span-8 mt-10 md:mt-0">
                            <div class="flex flex-col my-auto gap-y-4">{singleAttributeValueColumn("Blood type", answers["blood_type"])}</div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="flex flex-col my-auto gap-y-4">
                            {singleAttributeValueColumn("Vision", answers["vision_quality"])}
                            {singleAttributeValueColumn("Near/farsighted", answers["near_far_sighted"])}
                            {singleAttributeValueColumn("Corrective lenses", answers["corrective_lenses"])}
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {conditionalQuestion(answers["dental_work"], answers["dental_work_details"], false, null, "Dental work or orthodontic work?")}

                        {answers["allergies"] && answers["allergies"].answer.value === "yes"
                            ? medCards(
                                  "Allergies",
                                  answers["allergy_history"],
                                  "allergy_substance",
                                  ["Symptoms", "Age of first reaction"],
                                  ["allergy_symptoms", "allergy_reaction"],
                                  "med_hist_allergy"
                              )
                            : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["outgrown_allergies"], answers["outgrown_allergies_details"], false, null, "Outgrown allergies")}

                        {answers["prescription_medications"] && answers["prescription_medications"].answer.value === "yes"
                            ? medCards(
                                  "Prescription medications taken in the last 12 months",
                                  answers["recent_prescriptions"],
                                  "medication_name",
                                  ["Reason", "Frequency", "Date last taken", "Time on medication", "Willing to stop"],
                                  ["medication_reason", "medication_frequency", "medication_date_last_taken", "medication_length_of_time", "medication_willing_stop"],
                                  "med_hist_rx"
                              )
                            : null}

                        {answers["otc_medications"] && answers["otc_medications"].answer.value === "yes"
                            ? medCards(
                                  "Over-the-counter medications taken in the last 12 months",
                                  answers["recent_otc"],
                                  "otc_name",
                                  ["Reason", "Date last taken"],
                                  ["otc_reason", "otc_date_last_taken"],
                                  "med_hist_otc"
                              )
                            : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["vitamins_supplements"], answers["vitamin_supplement_history"], false, null, "Vitamins or supplements")}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div className="mb-5">
                            <div className="flex items-center">
                                <div className="font-semibold">Diet</div>
                                {editPencil({
                                    answer: answers["diet"],
                                    valOverride:
                                        answers["diet"].answer.value === "other"
                                            ? formatString({ answer: answers["diet_type_other"].answer.value })
                                            : answers["diet"].answer.value === "meat_eater_non_vegetarian"
                                            ? formatString({ answer: "Meat eater/non-vegetarian" })
                                            : formatString({ answer: answers["diet"].answer.value }),
                                    labelOverride: "Diet",
                                    style: "ml-3",
                                })}
                            </div>

                            <div>
                                {answers["diet"].answer.value === "other"
                                    ? formatString({ answer: answers["diet_type_other"].answer.value })
                                    : answers["diet"].answer.value === "meat_eater_non_vegetarian"
                                    ? formatString({ answer: "Meat eater/non-vegetarian" })
                                    : formatString({ answer: answers["diet"].answer.value })}
                            </div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["have_you_ever_had_anesthesia"], answers["anesthesia_complications_reason"], false, null, "Anesthesia history")}

                        {answers["surgical_history"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable("Surgical history", answers["surgical_history"], ["Surgery", "Date", "Age", "Reason"], ["surgery_name", "surgery_date", "surgery_age", "surgery_reason"])}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["vaccinations"], answers["vaccination_history"], false, null, "Vaccines or immunizations within the last 12 months?")}
                        {conditionalQuestion(answers["covid_vax"], answers["covid_vax_details"], false, null, "Have you had any COVID-19 vaccinations, and if so when?")}

                        {answers["serious_medical_problems"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable(
                            "Hospitalizations, surgeries, or institutionalizations that have occured in the past 2 years",
                            answers["serious_medical_problems"],
                            ["Diagnosis", "Age", "Treatment"],
                            ["serious_diagnosis", "age_serious_diagnosis", "treatment_serious_diagnosis"]
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["blood_donation"], answers["blood_donation_refusal_details"], false, null, "Have you ever been refused as a blood donor or told not to donate?")}

                        {conditionalQuestion(answers["blood_transfusion"], answers["blood_transfusion_details"], false, null, "Have you received a blood transfusion or any blood products?")}

                        {answers["eating_disorders"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable(
                            "Eating disorders",
                            answers["eating_disorders"],
                            ["Diagnosis", "Age of diagnosis", "Applicable treatment"],
                            ["ed_diagnosis", "ed_age_diagnosed", "ed_treatments"]
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(
                            answers["toxic_substance"],
                            answers["toxic_substance_details"],
                            false,
                            null,
                            "Have you ever been exposed to a substance that may be transferred in toxic doses, such as lead, mercury, or gold?"
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(
                            answers["occupational_exposure"],
                            answers["occupational_exposure_details"],
                            false,
                            null,
                            "Have you ever been in an occupation with increased risk of radiation or chemical exposure?"
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const famMedHistSection = () => {
        return (
            <div id="phys-attr" class="py-10">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/family_medical_history_icon.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Family medical history</span>
                            </h2>
                        </div>
                        {owner ? (
                            <div className="text-sm ml-2 mt-3">
                                <div>Have an update to your family medical history not listed here?</div>
                                <div>Please contact Cofertility support and we will help!</div>
                                <div className="text-secondary cursor-pointer flex items-center" onClick={() => handleCopy("support@cofertility.com")}>
                                    <div className="mr-2 mt-1 text-base">support@cofertility.com</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-4 mt-10 md:mt-0">
                            {Object.keys(famMedHist).map((key) => {
                                const skip = {
                                    birth_defect: true,
                                    cancer: true,
                                    ears_hearing: true,
                                    gastro: true,
                                    genetic_dd: true,
                                    genital_repro: true,
                                    heart_blood: true,
                                    mental_hlth_dev: true,
                                    metabolic_endocrine: true,
                                    muscle_joints: true,
                                    neuro: true,
                                    other_disease: true,
                                    respiratory: true,
                                    skin: true,
                                    visual_dd: true,
                                    anything_else: true,
                                };
                                if (skip[key]) {
                                    return null;
                                }
                                return (
                                    <div class="p-6 rounded-md border border-neutral-400 flex flex-col gap-6">
                                        <div className="flex items-center justify-between">
                                            <h4 class="mb-0">{famMedHist[key].question.label}</h4>
                                            {editPencil({
                                                answer: famMedHist[key],
                                                type: "obj_array",
                                                valOverride: formatString({
                                                    answer: famMedHist[key].answer.value,
                                                    type: "obj_array",
                                                    keys: true,
                                                    props: ["person", "diagnosis_age", "treatments"],
                                                }),
                                                taOverride: formatString({
                                                    answer: famMedHist[key].answer.value,
                                                    type: "obj_array",
                                                    keys: true,
                                                    props: ["person", "diagnosis_age", "treatments"],
                                                    textArea: true,
                                                }),
                                                style: "ml-3",
                                            })}
                                        </div>
                                        {Array.isArray(famMedHist[key].answer.value)
                                            ? famMedHist[key].answer.value.map((y, i) => (
                                                  <div>
                                                      <div className="w-full border-b border-solid border-gray-300 mb-5"></div>
                                                      <div class="md:grid md:grid-cols-3 gap-4 w-full">
                                                          <div class="flex flex-col gap-y-1 mb-4">
                                                              <p class="text-sm col-span-2">Who</p>
                                                              <p class="font-bold col-span-2">{formatString({ answer: y.person })}</p>
                                                          </div>
                                                          <div class="flex flex-col gap-y-1 mb-4">
                                                              <p class="text-sm col-span-2">Age Diagnosed</p>
                                                              <p class="col-span-2">{formatString({ answer: y.diagnosis_age })}</p>
                                                          </div>
                                                          <div class="flex flex-col gap-y-1 mb-4">
                                                              <p class="text-sm col-span-2">Treatment/Additional info</p>
                                                              <p class="col-span-2">{formatString({ answer: y.treatments })}</p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              ))
                                            : famMedHist[key].answer.value}
                                    </div>
                                );
                            })}
                            {famMedHist["anything_else"] ? (
                                <div class="p-6 rounded-md border border-neutral-400 flex flex-col gap-6">
                                    <div className="flex items-center justify-between">
                                        <h4 class="mb-0">{famMedHist["anything_else"].question.label}</h4>
                                        {editPencil({ answer: famMedHist["anything_else"] })}
                                    </div>
                                    <div>
                                        <div className="w-full border-b border-solid border-gray-300 mb-5"></div>
                                        <div class="w-full">
                                            <div class="flex flex-col gap-y-1 mb-4">
                                                <p class="font-bold col-span-2">{formatString({ answer: famMedHist["anything_else"].answer.value })}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const medCards = (label, answer, titleKey, columns, valueKeys, iconName) => {
        return (
            <div>
                <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                <div className="flex items-center">
                    <div className="font-semibold">{label}</div>
                    {editPencil({
                        answer: answer,
                        type: "obj_array",
                        valOverride: formatString({
                            answer: answer.answer.value,
                            type: "obj_array",
                            keys: true,
                            props: valueKeys,
                        }),
                        taOverride: formatString({
                            answer: answer.answer.value,
                            type: "obj_array",
                            keys: true,
                            props: valueKeys,
                            textArea: true,
                        }),
                        style: "ml-3",
                    })}
                </div>
                <div class="flex overflow-x-auto mt-5">
                    <div className="flex flex-nowrap gap-x-6">
                        {answer.answer.value.map((d) => (
                            <div class="w-card grid grid-cols-1 grid-rows-1 rounded-md border border-neutral-400 p-5 flex-none">
                                <img
                                    className="row-start-1 row-end-1 col-start-1 col-end-1 justify-self-end -mr-3 -mt-3"
                                    height="40"
                                    width="40"
                                    alt="FreezeByCo logo"
                                    src={`${process.env.PUBLIC_URL}/images/profile/${iconName}.svg`}
                                />
                                <div class="row-start-1 row-end-1 col-start-1 col-end-1 flex flex-col gap-y-4">
                                    <h4 class="pr-10 mb-0">{d[titleKey]}</h4>
                                    {columns.map((c, i) => {
                                        if (d[valueKeys[i]]) {
                                            return (
                                                <div>
                                                    <header class="text-xs">{c}</header>
                                                    <p class="text-sm">{formatString({ answer: d[valueKeys[i]] })}</p>
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const numberedTable = (title, answer, columns, valueKeys) => {
        if (!answer || !answer.answer) {
            return;
        }

        return (
            <div>
                <div className="flex items-center">
                    <div className="font-semibold mb-5">{title}</div>
                    {editPencil({
                        answer: answer,
                        type: "obj_array",
                        valOverride: formatString({
                            answer: answer.answer.value,
                            type: "obj_array",
                            keys: true,
                            props: valueKeys,
                        }),
                        taOverride: formatString({
                            answer: answer.answer.value,
                            type: "obj_array",
                            keys: true,
                            props: valueKeys,
                            textArea: true,
                        }),
                        style: "ml-3 mb-5",
                    })}
                </div>
                {answer.answer.value.map((d, i) => {
                    return (
                        <div className="md:flex md:w-full">
                            <h1 className="mr-5 ">0{i + 1}.</h1>
                            <div className="md:grid md:grid-cols-3 md:justify-between mb-5 md:w-full">
                                {columns.map((c, j) => {
                                    return (
                                        <div className="md:col-span-1 flex md:block align-top mb-5 md:mr-10 items-center">
                                            <div className="text-sm font-medium text-left mr-3 md:mr-0">
                                                {c}
                                                <span className="md:hidden">:</span>
                                            </div>
                                            <div className="text-left">{d[valueKeys[j]] ? formatString({ answer: d[valueKeys[j]] }) : "NA"}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const getGrandparentStatus = (name) => {
        const isLiving = answers[`${name}_living`].answer.value;
        if (isLiving === "not_sure") {
            return "Unknown";
        } else if (isLiving === "yes") {
            return answers[`${name}_age`].answer.value;
        }

        return `Deceased - ${answers[`${name}_death_age`].answer.value}`;
    };

    const singleAttributeValueColumn = (labelOverride, answer, group = false, valOverride = null, icon = null) => {
        if (!answer) {
            return null;
        }

        return (
            <div class="flex justify-between w-full md:grid md:grid-cols-2 text-sm md:w-1/2 ">
                <div class="md:col-span-1 text-left font-semibold">{labelOverride}</div>
                <div class="md:col-span-1 flex items-center">
                    {icon ? (
                        <img
                            srcSet={`${process.env.PUBLIC_URL}/images/profile/${valOverride ? valOverride : formatString({ answer: answer.answer.value })}.svg`}
                            className="inline-block w-3 h-3 mx-2"
                            alt={`${valOverride ? valOverride : formatString({ answer: answer.answer.value })}-eye`}
                        />
                    ) : null}
                    <div class="text-right md:text-left">{valOverride ? valOverride : formatString({ answer: answer.answer.value })}</div>
                    {editPencil({
                        answer,
                        group,
                        valOverride,
                        labelOverride,
                        style: "ml-3",
                    })}
                </div>
            </div>
        );
    };

    const attributeValueColumn = (labelOverride, answer, group = false, valOverride = null, icon = false) => {
        return (
            <div class="grid grid-cols-2 gap-x-6 text-sm">
                <div class="col-span-1 text-left font-semibold">{labelOverride}</div>
                <div class="col-span-1 flex items-center">
                    {icon ? (
                        <img
                            srcSet={`${process.env.PUBLIC_URL}/images/profile/${valOverride ? valOverride : answer.answer.value}.svg`}
                            className="inline-block w-3 h-3 mx-2"
                            alt={`${valOverride ? valOverride : answer.answer.value}-eye`}
                        />
                    ) : null}
                    <div class="text-left">{formatString({ answer: valOverride ? valOverride : answer.answer.value })}</div>
                    {editPencil({
                        answer,
                        group,
                        valOverride,
                        labelOverride,
                        style: "ml-3",
                    })}
                </div>
            </div>
        );
    };

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    if (!user) {
        return null;
    }

    const changeSection = (ref, name) => {
        const rect = ref.current.getBoundingClientRect();
        var headerOffset = 75;
        var elementPosition = rect.top;
        var offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
        setCurrentSection(name);
        setChangingSections(true);
        setTimeout(() => {
            setChangingSections(false);
        }, 600);
    };

    const placeHold = async (holdID) => {
        try {
            await request({
                url: `/family/donors/${holdID}/hold`,
                method: "POST",
            });
        } catch (err) {
            console.log(err);
        }
    };

    const toggleFavorite = async (donorID) => {
        try {
            await request({
                url: `/family/donors/${donorID}/favorite`,
                method: "PUT",
            });

            setRefreshKey((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const editPencil = ({ answer, group = false, valOverride = null, taOverride = null, labelOverride = null, type = null, props = null, style = "", keys = null }) => {
        if (staff || ip) {
            return null;
        }

        return (
            <div>
                <svg
                    onClick={(e) => {
                        e.stopPropagation();
                        openEdit(answer, group, valOverride, taOverride, labelOverride, type, props, keys);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    className={`bi bi-pencil ${style}`}
                    viewBox="0 0 16 16"
                >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                </svg>
            </div>
        );
    };

    const setPhotoMargin = (i) => {
        if (i === 0) {
            return "mb-1 mr-1";
        } else if (i === 1) {
            return "mb-1";
        } else if (i === 2) {
            return "mr-1";
        }

        return "";
    };

    const imageCarousel = () => {
        let photosToRender = [];
        if (!photos.length) {
            photosToRender = [
                <div key={0} className={`flex-shrink-0 w-full block`}>
                    <div
                        className="w-full bg-gray-200"
                        style={{
                            height: 400,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                </div>,
            ];
        } else {
            photosToRender = photos.map((p, i) => (
                <div key={i} className={`flex-shrink-0 w-full ${i === activeCarouselIndex ? "block" : "hidden"}`}>
                    <div
                        className="w-full"
                        style={{
                            backgroundImage: `url("${p.cropped_source}")`,
                            height: 400,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                </div>
            ));
        }

        return (
            <div className="relative md:hidden">
                <div {...swipeHandlers} className="flex overflow-x-auto">
                    {photosToRender}
                </div>
            </div>
        );
    };

    const imageGrid = () => {
        let headerImage = [];
        let gridImages = [];

        if (!photos.length) {
            headerImage = [
                <div
                    className="mr-1 bg-gray-200"
                    style={{
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = [0, 1, 2, 3].map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)} bg-gray-200`}
                    style={{
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));
        } else if (photos.length === 1) {
            headerImage = [
                <div
                    className="mr-1"
                    style={{
                        backgroundImage: `url("${photos[0].cropped_source}")`,
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = [0, 1, 2, 3].map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)} bg-gray-200`}
                    style={{
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));
        } else if (photos.length > 1) {
            headerImage = [
                <div
                    className="mr-1"
                    style={{
                        backgroundImage: `url("${photos[0].cropped_source}")`,
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = photos.slice(1).map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)}`}
                    style={{
                        backgroundImage: `url("${p.cropped_source}")`,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));

            const gridDiff = 4 - gridImages.length;
            for (let i = 0; i < gridDiff; i++) {
                gridImages.push(
                    <div
                        className={`${setPhotoMargin(i + gridDiff)} bg-gray-200`}
                        style={{
                            gridRowStart: null,
                            gridColumnStart: null,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                );
            }
        }

        return (
            <div className="hidden md:grid grid-cols-2 h-full relative z-10">
                {headerImage}
                <div className="grid grid-cols-2 grid-rows-2">{gridImages}</div>
                <button
                    onClick={() => {
                        if (staff) {
                            navigate(`/staff/split-members/${userId}/public-profile/photos`);
                        } else if (owner) {
                            viewPhotos();
                        } else {
                            navigate(`/family/donors/${userId}/photos`);
                        }
                    }}
                    className="text-secondary font-normal absolute bottom-0 right-0 py-3 px-4 m-2 bg-white text-black rounded-full z-10"
                >
                    View Photos
                </button>
            </div>
        );
    };

    const handleCarouselNext = () => {
        if (!photos.length) {
            return;
        }

        setActiveCarouselIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
    };

    const handleCarouselPrev = () => {
        if (!photos.length) {
            return;
        }

        setActiveCarouselIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
    };

    return (
        <div>
            {staff ? (
                <div className="divide-y divide-solid">
                    <div className="flex flex-row w-1/2 pt-5 ml-10 mb-5">
                        <div onClick={() => navigate("/staff/split-members")} className="mr-2 cursor-pointer text-secondary text-sm font-medium">
                            SPLIT MEMBERS
                        </div>
                        <span className="text-sm font-medium">{">"}</span>
                        <div onClick={() => navigate(`/staff/split-members/${userId}`)} className="ml-2 text-secondary text-sm font-medium cursor-pointer">
                            {user.firstName} {user.lastName}
                        </div>
                    </div>
                    <div></div>
                </div>
            ) : null}

            {showEditModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 30,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl overflow-y-auto"
                        ref={editModalRef}
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4 flex justify-between items-center">
                            <span className="heading-underline-forest-50 ">Send Edit Request</span>
                        </h3>
                        <h4 className="mb-3 bg-gray-100 p-4 rounded-lg font-semibold">
                            {modalData.labelOverride
                                ? modalData.labelOverride
                                : modalData.useGroup
                                ? groups[modalData.questionAnswer.group_id].map((g) => <div>{g}</div>)
                                : modalData.questionAnswer.question.label}
                        </h4>
                        <div>
                            <div className="mx-2 font-semibold text-nowrap">Current answer:</div>
                            <div className="mx-2">
                                {modalData.valOverride ? modalData.valOverride : formatString({ answer: modalData.questionAnswer.answer.value, type: modalData.type, props: modalData.props })}
                            </div>
                        </div>
                        <div className="m-2 font-semibold">Type your updated answer below and our team will review and get back to you:</div>
                        <textarea
                            className="w-full m-2 bg-gray-50 p-4 rounded-lg"
                            value={updatedAnswer}
                            onChange={handleEditAnswer}
                            rows={10}
                            cols={50}
                            placeholder="Type your updated answer here..."
                        />
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeEdit} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={() => saveEditRequest(modalData.useGroup)} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div id="profile" class="flex flex-col bg-gray-100">
                <div class={`${staff || ip ? "bg-[#bbadd5]" : "bg-freeze-primary"} lg:pb-12 flex justify-center`}>
                    <div class="w-full lg:px-6 xl:px-0 md:w-3/4">
                        <div class="bg-white lg:shadow-hard lg:rounded-lg lg:mt-10 overflow-hidden">
                            <section style={{ zIndex: 10 }}>
                                {imageGrid()}
                                {imageCarousel()}
                            </section>
                            <div class="block lg:grid grid-cols-12 gap-x-6 p-6 lg:p-0 my-4">
                                <section class="col-span-4 self-center col-start-2 gap-x-6">
                                    <h1 class="mb-2 flex items-center justify-between">
                                        <div>
                                            <span class="heading-underline">{user.donorCode}</span>
                                        </div>
                                        {/* <span class="inline-block ml-1">{{ svg.tooltip("Your donor profile will display a 3-letter donor code and will remain anonymous to intended parents.") }}</span> */}
                                        {owner ? null : (
                                            <div className="flex items-center mt-5 text-secondary mb-3 md:hidden">
                                                {favorited ? (
                                                    <svg
                                                        onClick={() => toggleFavorite(user.id)}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        fill="#ED795E"
                                                        class="bi bi-heart-fill cursor-pointer mr-5"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        onClick={() => toggleFavorite(user.id)}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="30"
                                                        height="30"
                                                        fill="currentColor"
                                                        className="bi bi-heart cursor-pointer mr-5"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                                    </svg>
                                                )}
                                                <button onClick={() => placeHold(user.id)} className="h-10 bg-[#ED795E] text-lg text-black rounded-full py-2 px-6 font-semibold cursor-pointer">
                                                    Hold match
                                                </button>
                                            </div>
                                        )}
                                    </h1>
                                    <div className="flex items-center w-full">
                                        {answers["job_title"] ? <div className="eyebrow text-sm">{answers["job_title"].answer.value.split("_").join(" ")}</div> : null}
                                        {answers["job_field"] ? <div className="text-sm">, {answers["job_field"].answer.value.toUpperCase().split("_").join(" ")}</div> : null}
                                    </div>
                                    {user.freeze_member.school_ranking ? (
                                        <div className="flex items-center w-full mt-2">
                                            <img height="20" width="20" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/school_ranking.svg`} />
                                            <div className="text-sm">{SchoolRankingMap[user.freeze_member.school_ranking]}</div>
                                        </div>
                                    ) : null}
                                    {owner ? null : (
                                        <div className="hidden md:flex items-center w-full mt-5 text-secondary mb-3">
                                            {favorited ? (
                                                <svg
                                                    onClick={() => toggleFavorite(user.id)}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    fill="#ED795E"
                                                    class="bi bi-heart-fill cursor-pointer mr-5"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                                </svg>
                                            ) : (
                                                <svg
                                                    onClick={() => toggleFavorite(user.id)}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    fill="currentColor"
                                                    className="bi bi-heart cursor-pointer mr-5"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                                </svg>
                                            )}
                                            <button onClick={() => placeHold(user.id)} className="h-12 bg-[#ED795E] text-black rounded-full py-2 px-6 font-semibold cursor-pointer">
                                                Hold match
                                            </button>
                                        </div>
                                    )}
                                </section>
                                <section class="col-span-6">
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Race/Identity</p>
                                            <p className="w-3/4 text-sm">
                                                {answers["race"] ? answers["race"].answer.value.map((r) => RaceMap[r]).join(", ") : <span className="italic text-gray-300 font-normal">None</span>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Father's heritage</p>
                                            <p className="w-3/4 text-sm">
                                                {answers["fathers_ethnicities"] ? (
                                                    answers["fathers_ethnicities"].answer.value.map((e) => EthnicitiesMap[e.ethnicity]).join(", ")
                                                ) : (
                                                    <span className="italic text-gray-300 font-normal">None</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Mother's heritage</p>
                                            <p className="w-3/4 text-sm">
                                                {answers["mothers_ethnicities"] ? (
                                                    answers["mothers_ethnicities"].answer.value.map((e) => EthnicitiesMap[e.ethnicity]).join(", ")
                                                ) : (
                                                    <span className="italic text-gray-300 font-normal">None</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Age</p>
                                            <p className="w-3/4 text-sm">{calculateAge(user.dateOfBirth)}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Relationship</p>
                                            <p className="w-3/4 text-sm">{formatString({ answer: user.freeze_member.relationship })}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">AMH level</p>
                                            <p className="w-3/4 text-sm">{amh}</p>
                                        </div>
                                    </div>

                                    {/* <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Engagement</p>
                                            <p className="w-3/4 text-sm">{formatString({ answer: user.freeze_member.engagement })}</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Cycle type</p>
                                            <p className="w-3/4 text-sm">{user.freeze_member.is_cycle_first ? "Frozen" : "Fresh"}</p>
                                        </div>
                                    </div> */}
                                </section>
                            </div>
                        </div>

                        {staff || ip ? (
                            <div className="mt-10">
                                <img className="mx-auto" height="72" width="72" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/profile-heart-message.svg`} />
                                <div className="flex justify-center mt-3">
                                    <h1 className="text-center heading-underline-forest-50">About {user.donorCode}</h1>
                                </div>
                                <div className="text-center">{user.freeze_member.profile_bio}</div>
                                {user.freeze_member.match_preferences ? <div className="text-center mt-5">{user.freeze_member.match_preferences}</div> : null}
                                {user.freeze_member.travel_availability ? <div className="text-center mt-5">{user.freeze_member.travel_availability}</div> : null}
                                {user.freeze_member.donor_video ? (
                                    <div className="flex justify-center mt-5">
                                        <div className="flex items-center cursor-pointer text-secondary" onClick={() => window.open(user.freeze_member.donor_video, "_blank", "noopener,noreferrer")}>
                                            <div className="mr-3">Check out my video!</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                                                />
                                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div id="profile-nav" class="bg-tertiary-light w-full sticky top-0 z-20" ref={tabBarRef}>
                    <div class="flexflex-wrap px-6 xl:justify-center lg:mx-auto text-center py-6 h-20">
                        {/* {{ carousel(categories, type="menu", indicator=False) }} */}
                        <div className="flex flex-row w-full md:justify-center overflow-scroll mt-1">
                            <div
                                onClick={() => changeSection(persRef, "personality")}
                                className={`${
                                    currentSection === "personality" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                PERSONALITY
                            </div>
                            <div
                                onClick={() => changeSection(physAttrsRef, "phys-attr")}
                                className={`${
                                    currentSection === "phys-attr" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                PHYSICAL ATTRIBUTES
                            </div>
                            <div
                                onClick={() => changeSection(eduWorkRef, "edu-work-skills")}
                                className={`${
                                    currentSection === "edu-work-skills" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                EDU/WORK/SKILLS
                            </div>
                            <div
                                onClick={() => changeSection(famAttrRef, "fam-attr")}
                                className={`${
                                    currentSection === "fam-attr" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                FAMILY ATTRIBUTES
                            </div>
                            <div
                                onClick={() => changeSection(fertRef, "fertility")}
                                className={`${
                                    currentSection === "fertility" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                FERTILITY
                            </div>
                            <div
                                onClick={() => changeSection(medHistRef, "med-hist")}
                                className={`${
                                    currentSection === "med-hist" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                MEDICAL HISTORY
                            </div>
                            <div
                                onClick={() => changeSection(famMedHistRef, "fam-med-hist")}
                                className={`${
                                    currentSection === "fam-med-hist" ? "border-secondary" : "border-transparent"
                                } text-secondary border-x-0 pb-1 border-b-3 text-sm font-medium mr-7 cursor-pointer whitespace-nowrap`}
                            >
                                FAMILY MEDICAL HISTORY
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={persRef} className={`mx-auto w-5/6`}>
                    {personalitySection()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={physAttrsRef} className={`mx-auto w-5/6`}>
                    {physicalAttributesSection()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={eduWorkRef} className={`mx-auto w-5/6`}>
                    {eduWorkSkillsSection()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={famAttrRef} className={`mx-auto w-5/6`}>
                    {familyAttributesSection()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={fertRef} className={`mx-auto w-5/6`}>
                    {fertilitySection()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={medHistRef} className={`mx-auto w-5/6`}>
                    {medicalHistory()}
                </div>
                <div className="w-full border-b border-solid border-black my-4"></div>

                <div ref={famMedHistRef} className={`mx-auto w-5/6`}>
                    {famMedHistSection()}
                </div>
                {/* <div className="w-full border-b border-solid border-black my-4"></div> */}
            </div>
        </div>
    );
};

export default PublicProfile;
