import { useContext, useEffect } from "react";
import Documents from "../staff/files/Documents";
import { AppContext } from "../../App";
import { SMDashboardContext } from "./Dashboard";

const SMDocuments = () => {
    const { loggedInUser } = useContext(AppContext);
    const { setCurrentPage } = useContext(SMDashboardContext);
    setCurrentPage("documents");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top "
        >
            <div className="w-3/4 mx-auto mt-10 bg-white py-8 px-4">
                <Documents user={loggedInUser} userType={"split-members"} />
            </div>
        </div>
    );
};

export default SMDocuments;
