import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClinicStaff from "./ClinicStaff";
import ClinicIPs from "./ClinicIPs";
import ClinicMatches from "./ClinicMatches";
import { AppContext } from "../../../../App";

const ClinicPage = ({ tab }) => {
    const [currentTab, setCurrentTab] = useState(tab);
    const [clinic, setClinic] = useState(null);
    const [refetchClinic, setRefetchClinic] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stickyTabs, setStickyTabs] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [hasError, setHasError] = useState(null);

    const { request } = useContext(AppContext);
    const navigate = useNavigate();
    const { clinicId } = useParams();
    const tabBarRef = useRef(null);

    useEffect(() => {
        const fetchIntendedParent = async () => {
            try {
                // remove this GET request all together, we should have all the needed info within local user storage
                // once we update the prescreener and general questions logic to update to user record
                const response = await request({
                    url: `/staff/clinics/${clinicId}`,
                    method: "GET",
                });

                const { clinic } = response;

                setClinic(clinic);
                if (clinic.name) setName(clinic.name);
                if (clinic.phoneNumber) setPhone(clinic.phoneNumber);
                if (clinic.address1) setAddress1(clinic.address1);
                if (clinic.address2) setAddress2(clinic.address2);
                if (clinic.city) setCity(clinic.city);
                if (clinic.state) setHomeState(clinic.state);
                if (clinic.country) setCountry(clinic.country);
                if (clinic.zipcode) setZipcode(clinic.zipcode);
                setIsLoading(false);

                document.title = `Staff | ${clinic.name}`;
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchIntendedParent();
    }, [request, clinicId, tab, refetchClinic]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (tabBarRef.current) {
                const rect = tabBarRef.current.getBoundingClientRect();

                const shouldBeSticky = rect.top <= 0;

                // Update the state ONLY if it has changed
                if (shouldBeSticky !== stickyTabs) {
                    setStickyTabs(shouldBeSticky);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [stickyTabs]);

    const changeTab = (tabName) => {
        setCurrentTab(tabName);
        window.history.replaceState(null, "", `/staff/clinics/${clinicId}/${tabName}`);
        document.title = `Staff | ${clinic.name}. | ${tabName[0].toUpperCase() + tabName.substring(1)}`;
        if (stickyTabs) {
            tabBarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return null;
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const closeContactModal = () => {
        setName(clinic.name);
        setEmail(clinic.email);
        if (clinic.phoneNumber) setPhone(clinic.phoneNumber);
        else setPhone("");
        if (clinic.address1) setAddress1(clinic.address1);
        else setAddress1("");
        if (clinic.address2) setAddress2(clinic.address2);
        else setAddress2("");
        if (clinic.city) setCity(clinic.city);
        else setCity("");
        if (clinic.state) setHomeState(clinic.state);
        else setHomeState("");
        if (clinic.country) setCountry(clinic.country);
        else setCountry("");
        if (clinic.zipcode) setZipcode(clinic.zipcode);
        else setZipcode("");
        setShowContactModal(false);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/staff/clinics/${clinicId}/contact-info`,
                method: "PUT",
                data: {
                    name,
                    phone,
                    email,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    country,
                    zipcode,
                },
            });

            setRefetchClinic(refetchClinic + 1);
            setRefreshKey(refreshKey + 1);
            closeContactModal();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="pb-20">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10 mb-5">
                    <div onClick={() => navigate("/staff/intended-parents")} className="mr-2 cursor-pointer text-secondary text-sm font-medium">
                        CLINICS
                    </div>
                    <span className="text-sm font-medium">{">"}</span>
                    <div className="ml-2 text-secondary text-sm font-medium">{clinic.name}</div>
                </div>
                <div></div>
            </div>

            <div className="flex p-4 mr-30 mb-5">
                <div className="w-24 mr-2 flex justify-center">
                    <h1 className="flex items-center justify-center w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#CACD9D" }}>
                        {clinic.name.charAt(0).toUpperCase()}
                    </h1>
                </div>

                <div className="flex-grow flex flex-col">
                    <div className="flex gap-2 items-center">
                        <h2>{clinic.name}</h2>
                    </div>

                    <div className="my-2">
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>
                            </div>
                            {formatPhoneNumber(clinic.phone_number)}
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </div>
                            {clinic.email}
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                            </div>
                            {clinic.address_1}, {clinic.address_2 ? `${clinic.address_2},` : ""} {clinic.city}, {clinic.state}, {clinic.zipcode}
                        </div>
                    </div>

                    <div className="flex items-center">
                        <span onClick={() => setShowContactModal(true)} className="text-secondary text-sm font-normal mr-2 cursor-pointer flex items-center">
                            <div className="inline-block mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                            Edit Contact Info
                        </span>
                        <span className="border-l h-6 mx-1 border-gray-300"></span>
                    </div>
                </div>
            </div>

            <div className={`bg-white divide-y divide-solid w-full pb-5`} ref={tabBarRef}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    {/* <div onClick={() => changeTab(`overview`)} className={`${currentTab === 'overview' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>OVERVIEW</div> */}
                    <div
                        onClick={() => changeTab(`staff`)}
                        className={`${currentTab === "staff" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        STAFF
                    </div>
                    {/* <div onClick={() => changeTab(`documents`)} className={`${currentTab === 'documents' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>DOCUMENTS</div> */}
                    <div
                        onClick={() => changeTab(`intended-parents`)}
                        className={`${
                            currentTab === "intended-parents" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        INTENDED PARENTS
                    </div>
                    <div
                        onClick={() => changeTab(`matches`)}
                        className={`${currentTab === "matches" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        MATCHES
                    </div>
                    {/* <div onClick={() => changeTab(`activity`)} className={`${currentTab === 'activity' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>ACTIVITY</div> */}
                </div>

                <div></div>
            </div>

            {stickyTabs ? (
                <div className={`bg-white divide-y divide-solid w-full`} style={{ position: "fixed", top: 0, zIndex: 10 }}>
                    <div className="ml-30 flex flex-row w-1/2 pt-5">
                        {/* <div onClick={() => changeTab(`overview`)} className={`${currentTab === 'overview' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>OVERVIEW</div> */}
                        <div
                            onClick={() => changeTab(`staff`)}
                            className={`${currentTab === "staff" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            STAFF
                        </div>
                        {/* <div onClick={() => changeTab(`documents`)} className={`${currentTab === 'documents' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>DOCUMENTS</div> */}
                        <div
                            onClick={() => changeTab(`intended-parents`)}
                            className={`${
                                currentTab === "intended-parents" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            INTENDED PARENTS
                        </div>
                        <div
                            onClick={() => changeTab(`matches`)}
                            className={`${currentTab === "matches" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            MATCHES
                        </div>
                        {/* <div onClick={() => changeTab(`activity`)} className={`${currentTab === 'activity' ? 'border-secondary' : 'border-transparent'} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}>ACTIVITY</div> */}
                    </div>

                    <div></div>
                </div>
            ) : null}

            {showContactModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-goldenrod mr-5">Edit Contact Details</span>
                        </h3>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div>
                                <label className="text-sm font-medium">First name:</label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setName)} value={name} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Phone number: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setPhone)} value={phone} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Email: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setEmail)} value={email} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 1: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress1)} value={address1} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 2: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress2)} value={address2} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">City: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCity)} value={city} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">State: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setHomeState)} value={homeState} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Country: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCountry)} value={country} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Zipcode: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setZipcode)} value={zipcode} />
                            </div>

                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeContactModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={saveContactEdits} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="min-h-screen">
                {currentTab === "staff" ? <ClinicStaff staff={clinic.clinic_staff} /> : null}

                {currentTab === "intended-parents" ? <ClinicIPs clinicId={clinicId} /> : null}

                {currentTab === "matches" ? <ClinicMatches clinicId={clinicId} /> : null}
            </div>
        </div>
    );
};

export default ClinicPage;
