import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

function AptitudeChart(data, pdf = false) {
    return (
        <div className="break-inside-avoid">
            <div class="hidden md:flex rounded-lg text-center bg-tertiary-light relative bg-cover py-12 flex-col gap-y-3.5 w-3/4 mt-15">
                <h4 class={`bg-white rounded-full italic px-4 py-1 shadow-medium absolute ${pdf ? "-top-0" : "-top-5"} ml-1/2 -translate-x-1/2`}>Aptitude rating</h4>
                <div class="flex flex-col my-auto mx-auto">
                    <BarChart width={500} height={300} data={data} layout="vertical" margin={{ top: 10, right: 30, left: 30, bottom: 10 }}>
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={{
                                stroke: "#939393",
                                strokeWidth: 1,
                            }}
                            horizontal={false}
                        />
                        <XAxis type="number" domain={[0, 5]} tickCount={6} style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif", fill: "black" }} />
                        <YAxis type="category" dataKey="name" style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif", fill: "black" }} />
                        <Bar dataKey="value" fill="#065951" radius={[0, 10, 10, 0]} barSize={25} />
                    </BarChart>
                </div>
            </div>
            <div class="md:hidden rounded-lg text-center bg-tertiary-light relative bg-cover py-12 flex flex-col gap-y-3.5 w-full mt-15">
                <h4 class={`bg-white rounded-full italic px-4 py-1 shadow-medium absolute ${pdf ? "-top-0" : "-top-5"} ml-1/2 -translate-x-1/2`}>Aptitude rating</h4>
                <div class="flex flex-col my-auto mx-auto">
                    <BarChart width={300} height={200} data={data} layout="vertical" margin={{ top: 10, right: 30, left: 30, bottom: 10 }}>
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={{
                                stroke: "#939393",
                                strokeWidth: 1,
                            }}
                            horizontal={false}
                        />
                        <XAxis type="number" domain={[0, 5]} tickCount={6} style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif", fill: "black" }} />
                        <YAxis type="category" dataKey="name" style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif", fill: "black" }} />
                        <Bar dataKey="value" fill="#065951" radius={[0, 10, 10, 0]} barSize={10} />
                    </BarChart>
                </div>
            </div>
        </div>
    );
}

export default AptitudeChart;
