import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useLocation } from "react-router-dom";

export const LogoutButton = () => {
    const location = useLocation();
    const { logout } = useAuth0();

    const determineReturn = () => {
        if (location.pathname.includes("freeze")) {
            return window.location.origin + "/freeze/login";
        }

        return window.location.origin + "/family/login";
    };

    const handleLogout = () => {
        const returnTo = determineReturn();
        console.log("returnTo: ", returnTo);
        logout({
            logoutParams: {
                returnTo: returnTo,
            },
        });
    };

    return (
        <div onClick={handleLogout} className="cursor-pointer underline">
            Logout
        </div>
    );
};
