import { useEffect, useState, useRef, useCallback, useContext } from "react";
import { Ethnicities, EthnicitiesMap, RaceMap } from "../../util/helpers";
import { Filter } from "../filter/Filter";
import { DropdownSearchFilter } from "../filter/DropdownSearchFilter";
import { MinMaxFilter } from "../filter/MinMaxFilter";
import { MultiCheckboxFilter } from "../filter/MultiCheckboxFilter";
import { MultiButtonFilter } from "../filter/MultiButtonFilter";
import { MultiButtonGridFilter } from "../filter/MultiButtonGridFilter";
import { SingleSelectCheckboxFilter } from "../filter/SingleSelectCheckboxFilter";
import { DisclosureFilter } from "../filter/DisclosureFilter";
import { InputFilter } from "../filter/InputFilter";
import { SavedSearches } from "../filter/SavedSearches";
import { SimpleModal } from "../modals/SimpleModal";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";

const DonorSearch = () => {
    const [donors, setDonors] = useState([]);
    const [favorites, setFavorites] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [hasError, setHasError] = useState(null);

    const [haveFilters, setHaveFilters] = useState(false);

    const [selectedStates, setSelectedStates] = useState([]);

    const [heritageFilterFocus, setHeritageFilterFocus] = useState(false);
    const [heritageInput, setHeritageInput] = useState("");
    const [selectedHeritages, setSelectedHeritages] = useState([]);

    const [filterOptions, setFilterOptions] = useState(null);
    const [filters, setFilters] = useState([]);
    const [flagsFilter, setFlagsFilter] = useState([]);
    const [raceFilter, setRaceFilter] = useState([]);
    const [hairColorFilter, setHairColorFilter] = useState([]);
    const [hairTypeFilter, setHairTypeFilter] = useState([]);
    const [eyeColorFilter, setEyeColorFilter] = useState([]);
    const [educationFilter, setEducationFilter] = useState([]);
    const [rankingFilter, setRankingFilter] = useState([]);
    const [personalityFilter, setPersonalityFilter] = useState([]);
    const [aptitudeFilter, setAptitudeFilter] = useState([]);
    const [jewishAncestryFilter, setJewishAncestryFilter] = useState("");
    const [disclosureFilter, setDisclosureFilter] = useState([]);

    const searchRef = useRef(null);
    const [searches, setSearches] = useState([]);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [savedSearchModal, setSavedSearchModal] = useState(false);
    const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [refetchSearches, setRefetchSearches] = useState(0);

    const [updateDateRange, setUpdateDateRange] = useState([null, null]);

    const [refetchFaves, setRefetchFaves] = useState(0);
    const [refetchDonors, setRefetchDonors] = useState(0);

    const [orderBy, setOrderBy] = useState("updated_at");
    const [orderDir, setOrderDir] = useState("desc");

    const heritageDropdownRef = useRef(null);
    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(IPDashboardContext);

    setCurrentPage("donors");

    const getFilterString = useCallback(() => {
        let filterString = "";

        if (filters.length) {
            filters.forEach((filter) => {
                if (filter.name.includes("range")) {
                    if (filter.value[0] !== null) {
                        filterString += `&${filter.name}_min=${filter.value[0]}`;
                    }
                    if (filter.value[1] !== null) {
                        filterString += `&${filter.name}_max=${filter.value[1]}`;
                    }
                } else if (filter.name === "status" || filter.name === "frozen") {
                    filter.value.forEach((v) => {
                        filterString += `&${filter.name}=${v}`;
                    });
                } else {
                    filterString += `&${filter.name}=${filter.value}`;
                }
            });
        }

        if (selectedStates.length) {
            selectedStates.forEach((item) => (filterString += `&states=${item}`));
        }
        if (selectedHeritages.length) {
            selectedHeritages.forEach((item) => (filterString += `&heritages=${item}`));
        }
        if (flagsFilter.length) {
            flagsFilter.forEach((item) => (filterString += `&flags=${item}`));
        }
        if (raceFilter.length) {
            raceFilter.forEach((item) => (filterString += `&race=${item}`));
        }
        if (hairColorFilter.length) {
            hairColorFilter.forEach((item) => (filterString += `&hair_color=${item}`));
        }
        if (hairTypeFilter.length) {
            hairTypeFilter.forEach((item) => (filterString += `&hair_type=${item}`));
        }
        if (eyeColorFilter.length) {
            eyeColorFilter.forEach((item) => (filterString += `&eye_color=${item}`));
        }
        if (educationFilter.length) {
            educationFilter.forEach((item) => (filterString += `&education_level=${item}`));
        }
        if (rankingFilter.length) {
            rankingFilter.forEach((item) => (filterString += `&school_ranking=${item}`));
        }
        if (personalityFilter.length) {
            personalityFilter.forEach((item) => (filterString += `&personality=${item}`));
        }
        if (aptitudeFilter.length) {
            aptitudeFilter.forEach((item) => (filterString += `&aptitude=${item}`));
        }
        if (jewishAncestryFilter.length) {
            filterString += `&jewish_ancestry=${jewishAncestryFilter}`;
        }
        if (disclosureFilter.length) {
            disclosureFilter.forEach((item) => (filterString += `&disclosure=${item}`));
        }

        if (filterString.length) {
            setHaveFilters(true);
        } else {
            setHaveFilters(false);
        }

        return filterString;
    }, [
        filters,
        flagsFilter,
        raceFilter,
        hairColorFilter,
        hairTypeFilter,
        eyeColorFilter,
        educationFilter,
        rankingFilter,
        personalityFilter,
        aptitudeFilter,
        jewishAncestryFilter,
        disclosureFilter,
        selectedStates,
        selectedHeritages,
    ]);

    useEffect(() => {
        const fetchSearches = async () => {
            try {
                const response = await request({
                    url: `/family/donors/searches`,
                    method: "GET",
                });

                const { searches } = response;

                setSearches(searches);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchSearches();
    }, [request, refetchSearches]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/family/donors/favorites`,
                    method: "GET",
                });

                const { favorites } = response;

                let faves = {};

                favorites.forEach((f) => (faves[f.favorite_id] = true));

                setFavorites(faves);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchFavorites();
    }, [request, refetchFaves]);

    useEffect(() => {
        document.title = "Donor Search";

        if (favorites === null) {
            return;
        }

        const fetchDonors = async () => {
            const filterString = getFilterString();

            try {
                const response = await request({
                    url: `/family/donors?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { donors } = response;

                if (page !== 1) {
                    setDonors((prev) => [...prev, ...donors]);
                } else {
                    setDonors(donors);
                }

                setHasMore(donors.length > 0);
                // window.history.replaceState(null, '', `${window.location.pathname}?page=${page}${window.location.search}`)
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchDonors();
    }, [request, page, orderBy, orderDir, getFilterString, favorites]);

    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                const response = await request({
                    url: `/family/donors/filter-options`,
                    method: "GET",
                });

                const { filterOptions } = response;
                setFilterOptions(filterOptions);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
            }
        };

        fetchFilterOptions();
    }, [request]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (heritageDropdownRef.current && !heritageDropdownRef.current.contains(event.target)) {
                setHeritageFilterFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const setFilterArray = (stateArray, setStateArray, value) => {
        let filterArray = structuredClone(stateArray);

        if (filterArray.includes(value)) {
            filterArray = filterArray.filter((item) => item !== value);
        } else {
            filterArray.push(value);
        }

        setStateArray(filterArray);
        setPage(1);
    };

    const setMinMax = (name, event) => {
        let val = event.target.value;

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (val.length) {
            if (name === "height_min" || name === "height_max") {
                val = val.replace(/[^0-9']/g, "");
                const h = filters.filter((f) => f.name === name);
                if (h.length && val.length < h[0].value.length && h[0].value.endsWith("'") && !val.endsWith("'")) {
                    // Do nothing, allow the single quote deletion
                } else if (val.length > 0 && !val.includes("'")) {
                    const lastChar = val[val.length - 1];
                    if (!isNaN(lastChar)) {
                        val += "'";
                    }
                }
            }

            filteredFilters.push({
                name: name,
                value: val,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearAllFilters = () => {
        setSelectedSearch("");
        setFilters([]);
        setRaceFilter([]);
        setHairColorFilter([]);
        setHairTypeFilter([]);
        setEyeColorFilter([]);
        setEducationFilter([]);
        setRankingFilter([]);
        setPersonalityFilter([]);
        setAptitudeFilter([]);
        setJewishAncestryFilter("");
        setDisclosureFilter([]);
        setUpdateDateRange([null, null]);
        setFlagsFilter([]);
        setSelectedStates([]);
        setHeritageInput("");
        setSelectedHeritages([]);
        setPage(1);
    };

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });

            // window.history.replaceState(null, '', `${window.location.pathname.includes('?') ? `${window.location.pathname}&name=${search}` : `${window.location.pathname}?name=${search}`}`)
        } else {
            // window.history.replaceState(null, '', window.location.pathname.replace('?name=', ''))
            // window.history.replaceState(null, '', window.location.pathname.replace('&name=', ''))
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleInputChange = (event, setInputState) => {
        const input = event.target.value;

        setInputState(input);
    };

    const updateSelectedHeritages = (heritage) => {
        const filteredHeritages = structuredClone(selectedHeritages).filter((selected) => selected !== heritage);

        if (!selectedHeritages.includes(heritage)) {
            filteredHeritages.push(heritage);
        }

        setPage(1);
        setHeritageInput("");
        setSelectedHeritages(filteredHeritages);
        setHeritageFilterFocus(false);
    };

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const handleString = (s) => {
        if (s === "bachelors_degree") {
            return "Bachelor's degree";
        }

        if (s === "masters_degree") {
            return "Master's degree";
        }
        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const toggleFavorite = async (donorID) => {
        try {
            await request({
                url: `/family/donors/${donorID}/favorite`,
                method: "PUT",
            });

            setRefetchFaves((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const clearDropdownSearch = (setInput, setSelected) => {
        setPage(1);
        setInput("");
        setSelected([]);
    };

    const clearMinMax = (name) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const closeSaveSearchModal = () => {
        setSearchName("");
        setSavedSearchModal(false);
    };

    const setCriteria = () => {
        let filter = {};

        if (raceFilter.length) {
            filter.race = raceFilter;
        }

        if (hairColorFilter.length) {
            filter.hair_color = hairColorFilter;
        }

        if (hairTypeFilter.length) {
            filter.hair_type = hairTypeFilter;
        }

        if (eyeColorFilter.length) {
            filter.eye_color = eyeColorFilter;
        }

        if (educationFilter.length) {
            filter.education_level = educationFilter;
        }

        if (rankingFilter.length) {
            filter.school_ranking = rankingFilter;
        }

        if (jewishAncestryFilter.length) {
            filter.jewish_ancestry = jewishAncestryFilter;
        }

        if (disclosureFilter.length) {
            filter.relationship_preferences = disclosureFilter;
        }

        if (aptitudeFilter.length) {
            filter.aptitude = aptitudeFilter;
        }

        if (personalityFilter.length) {
            filter.personality = personalityFilter;
        }

        if (selectedHeritages.length) {
            filter.ethnicities = selectedHeritages;
        }

        if (updateDateRange[0] !== null && updateDateRange[1] !== null) {
            filter.updated_range = updateDateRange;
        }

        if (filters.filter((f) => f.name === "height_min" || f.name === "height_max").length) {
            filter.height = filters.filter((f) => f.name === "height_min" || f.name === "height_max");
        }

        return filter;
    };

    const setFilterJson = () => {
        let filter = {};

        filter.race = setRaceFilter;
        filter.hair_color = setHairColorFilter;
        filter.hair_type = setHairTypeFilter;
        filter.eye_color = setEyeColorFilter;
        filter.education_level = setEducationFilter;
        filter.school_ranking = setRankingFilter;
        filter.jewish_ancestry = setJewishAncestryFilter;
        filter.relationship_preferences = setDisclosureFilter;
        filter.aptitude = setAptitudeFilter;
        filter.personality = setPersonalityFilter;
        filter.ethnicities = setSelectedHeritages;
        filter.updated_range = setUpdateDateRange;
        filter.height = setFilters;

        return filter;
    };

    const getEthnicities = (answers, slug, races) => {
        if (!answers) {
            return "";
        }

        const filter = answers.filter((a) => a.question.slug === slug);

        const found = filter[0];
        if (!found || !found.answer) {
            return "";
        }

        let result = "";

        found.answer.value.forEach((e, i) => {
            if (e.ethnicity === "african_american" && races["black_african_american"]) {
                return;
            } else if (e.ethnicity === "alaskan_native" && races["indigenous_american_alaska_native"]) {
                return;
            } else if (e.ethnicity === "indigenous_american" && races["indigenous_american_alaska_native"]) {
                return;
            }
            result += `, ${EthnicitiesMap[e.ethnicity]}`;
        });

        return result;
    };

    const saveSearch = async () => {
        try {
            await request({
                url: `/family/donors/searches`,
                method: "POST",
                data: {
                    name: searchName,
                    userID: loggedInUser.id,
                    criteria: setCriteria(),
                },
            });

            setRefetchSearches((prev) => prev + 1);
            setSavedSearchModal(false);
            setSearchName("");
        } catch (error) {
            // TODO: handle this error
            console.error("Error fetching checklist:", error);
        }
    };

    const clearSelectedSearch = () => {
        setSelectedSearch(null);
        clearAllFilters();
    };

    return (
        <div id="inner-body" className="min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top ">
            {savedSearchModal ? (
                <SimpleModal
                    title="Save search"
                    name="Name"
                    inputs={[{ title: "Name", value: searchName, setInputState: setSearchName, handleChange: handleInputChange, placeholder: "Name your search" }]}
                    close={closeSaveSearchModal}
                    save={saveSearch}
                />
            ) : null}

            <div id="app" className="pb-10">
                {/* <div className="md:hidden">
                    <Filter clearAllFilters={clearAllFilters} haveFilters={haveFilters}>
                        {[
                            <SavedSearches
                                searchRef={searchRef}
                                searches={searches}
                                selectedSearch={selectedSearch}
                                setSelectedSearch={setSelectedSearch}
                                setSavedSearchModal={setSavedSearchModal}
                                clearSelectedSearch={clearSelectedSearch}
                                searchDropdownOpen={searchDropdownOpen}
                                setSearchDropdownOpen={setSearchDropdownOpen}
                                filters={setFilterJson()}
                                haveFilters={haveFilters}
                            />,
                            <MultiCheckboxFilter
                                title="Race"
                                options={filterOptions ? filterOptions.races : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={raceFilter}
                                setSelected={setRaceFilter}
                            />,
                            <DropdownSearchFilter
                                title="Heritages"
                                placeholder="Heritage..."
                                dropdownRef={heritageDropdownRef}
                                filterFocus={heritageFilterFocus}
                                setFilterFocus={setHeritageFilterFocus}
                                input={heritageInput}
                                setInput={setHeritageInput}
                                handleInputChange={handleInputChange}
                                options={Ethnicities}
                                selected={selectedHeritages}
                                setSelected={setSelectedHeritages}
                                updateSelected={updateSelectedHeritages}
                                clearSelected={clearDropdownSearch}
                            />,
                            <SingleSelectCheckboxFilter
                                title="Jewish Ancestry"
                                options={filterOptions ? filterOptions.jewishAncestry : []}
                                setPage={setPage}
                                selected={jewishAncestryFilter}
                                setSelected={setJewishAncestryFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Hair Color"
                                options={filterOptions ? filterOptions.hairColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={hairColorFilter}
                                setSelected={setHairColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Eye Color"
                                options={filterOptions ? filterOptions.eyeColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={eyeColorFilter}
                                setSelected={setEyeColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Level of Education"
                                options={filterOptions ? filterOptions.education : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={educationFilter}
                                setSelected={setEducationFilter}
                            />,
                            <MultiCheckboxFilter
                                title="School Ranking"
                                options={filterOptions ? filterOptions.schoolRanking : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={rankingFilter}
                                setSelected={setRankingFilter}
                            />,
                            <MinMaxFilter title="Height" isText={true} filters={filters} nameMin="height_min" nameMax="height_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <MultiButtonFilter
                                title="Hair Style"
                                options={filterOptions ? filterOptions.hairTypes : []}
                                width="w-[20%]"
                                setFilterArray={setFilterArray}
                                selected={hairTypeFilter}
                                setSelected={setHairTypeFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Personality Traits"
                                slugs={["logical", "creative", "silly", "serious", "introvert", "extrovert"]}
                                setFilterArray={setFilterArray}
                                selected={personalityFilter}
                                setSelected={setPersonalityFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Aptitude"
                                slugs={["mathematical_ability", "scientific_ability", "athletic_ability", "singing_ability", "artistic_ability"]}
                                setFilterArray={setFilterArray}
                                selected={aptitudeFilter}
                                setSelected={setAptitudeFilter}
                            />,
                            <DisclosureFilter options={filterOptions ? filterOptions.disclosures : []} setFilterArray={setFilterArray} selected={disclosureFilter} setSelected={setDisclosureFilter} />,
                            <InputFilter
                                name="donor_code"
                                title="Donor Code"
                                placeholder="3 letter donor code..."
                                clearState={clearNameSearch}
                                clearValue="donor_code"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                        ]}
                    </Filter>
                </div> */}
                <div className="hidden md:block">
                    <Filter clearAllFilters={clearAllFilters} haveFilters={haveFilters}>
                        {[
                            <SavedSearches
                                searchRef={searchRef}
                                searches={searches}
                                selectedSearch={selectedSearch}
                                setSelectedSearch={setSelectedSearch}
                                setSavedSearchModal={setSavedSearchModal}
                                clearSelectedSearch={clearSelectedSearch}
                                searchDropdownOpen={searchDropdownOpen}
                                setSearchDropdownOpen={setSearchDropdownOpen}
                                filters={setFilterJson()}
                                haveFilters={haveFilters}
                            />,
                            <MultiCheckboxFilter
                                title="Race"
                                options={filterOptions ? filterOptions.races : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={raceFilter}
                                setSelected={setRaceFilter}
                            />,
                            <DropdownSearchFilter
                                title="Heritages"
                                placeholder="Heritage..."
                                dropdownRef={heritageDropdownRef}
                                filterFocus={heritageFilterFocus}
                                setFilterFocus={setHeritageFilterFocus}
                                input={heritageInput}
                                setInput={setHeritageInput}
                                handleInputChange={handleInputChange}
                                options={Ethnicities}
                                selected={selectedHeritages}
                                setSelected={setSelectedHeritages}
                                updateSelected={updateSelectedHeritages}
                                clearSelected={clearDropdownSearch}
                            />,
                            <SingleSelectCheckboxFilter
                                title="Jewish Ancestry"
                                options={filterOptions ? filterOptions.jewishAncestry : []}
                                setPage={setPage}
                                selected={jewishAncestryFilter}
                                setSelected={setJewishAncestryFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Hair Color"
                                options={filterOptions ? filterOptions.hairColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={hairColorFilter}
                                setSelected={setHairColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Eye Color"
                                options={filterOptions ? filterOptions.eyeColors : []}
                                grid={true}
                                setFilterArray={setFilterArray}
                                selected={eyeColorFilter}
                                setSelected={setEyeColorFilter}
                            />,
                            <MultiCheckboxFilter
                                title="Level of Education"
                                options={filterOptions ? filterOptions.education : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={educationFilter}
                                setSelected={setEducationFilter}
                            />,
                            <MultiCheckboxFilter
                                title="School Ranking"
                                options={filterOptions ? filterOptions.schoolRanking : []}
                                grid={false}
                                setFilterArray={setFilterArray}
                                selected={rankingFilter}
                                setSelected={setRankingFilter}
                            />,
                            <MinMaxFilter title="Height" isText={true} filters={filters} nameMin="height_min" nameMax="height_max" setMinMax={setMinMax} clearState={clearMinMax} />,
                            <MultiButtonFilter
                                title="Hair Style"
                                options={filterOptions ? filterOptions.hairTypes : []}
                                width="w-[20%]"
                                setFilterArray={setFilterArray}
                                selected={hairTypeFilter}
                                setSelected={setHairTypeFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Personality Traits"
                                items={[{ slug: "logical" }, { slug: "creative" }, { slug: "silly" }, { slug: "serious" }, { slug: "introvert" }, { slug: "extrovert" }]}
                                setFilterArray={setFilterArray}
                                selected={personalityFilter}
                                setSelected={setPersonalityFilter}
                            />,
                            <MultiButtonGridFilter
                                title="Aptitude"
                                items={[
                                    { slug: "mathematical_ability", icon: "math_filter_icon" },
                                    { slug: "scientific_ability", icon: "science_filter_icon" },
                                    { slug: "athletic_ability", icon: "athletic_filter_icon" },
                                    { slug: "singing_ability", icon: "singing_filter_icon" },
                                    { slug: "artistic_ability", icon: "art_filter_icon" },
                                ]}
                                setFilterArray={setFilterArray}
                                selected={aptitudeFilter}
                                setSelected={setAptitudeFilter}
                            />,
                            <DisclosureFilter options={filterOptions ? filterOptions.disclosures : []} setFilterArray={setFilterArray} selected={disclosureFilter} setSelected={setDisclosureFilter} />,
                            <InputFilter
                                name="donor_code"
                                title="Donor Code"
                                placeholder="3 letter donor code..."
                                clearState={clearNameSearch}
                                clearValue="donor_code"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                        ]}
                    </Filter>
                </div>
                <div className="sm:w-full md:w-3/4 sm:ml-10 md:ml-[422px] sm:mr-20  md:mr-[48px] grid sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-4 bg-white-200">
                    {donors.map((donor) => {
                        let races = {};
                        return (
                            <div key={donor.id} className="p-4 w-full">
                                <div>
                                    {donor.user_photos ? (
                                        <img
                                            onClick={() => window.open(window.location.origin + `/family/donors/${donor.id}`, "_blank")}
                                            src={donor.user_photos[0].cropped_source}
                                            alt={donor.user_photos[0].name}
                                            className="w-full rounded-lg aspect-square object-cover cursor-pointer"
                                        />
                                    ) : null}
                                </div>
                                <div className="flex items-center justify-between mt-3">
                                    <div
                                        className="font-serif font-medium text-xl max-w-fit cursor-pointer"
                                        onClick={() => window.open(window.location.origin + `/family/donors/${donor.id}`, "_blank")}
                                    >
                                        {donor.donorCode}
                                    </div>
                                    <span className="text-secondary">
                                        {favorites && favorites[donor.id] ? (
                                            <svg
                                                onClick={() => toggleFavorite(donor.id)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="#ED795E"
                                                class="bi bi-heart-fill cursor-pointer"
                                                viewBox="0 0 16 16"
                                            >
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                            </svg>
                                        ) : (
                                            <svg
                                                onClick={() => toggleFavorite(donor.id)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="currentColor"
                                                className="bi bi-heart cursor-pointer"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                            </svg>
                                        )}
                                    </span>
                                </div>
                                <div className="font-semibold text-xs mt-1 mb-2">
                                    {donor.answers ? donor.answers.filter((ans) => ans.question.slug === "job_title")[0].answer.value.toUpperCase() : null}
                                </div>
                                <div className="h-14">
                                    <div className="grid grid-cols-12 text-secondary mb-1 items-center">
                                        <div className="h-4 w-4 col-span-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mortarboard-fill mr-3" viewBox="0 0 16 16">
                                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z" />
                                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z" />
                                            </svg>
                                        </div>
                                        <div className="col-span-11 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {donor.answers ? handleString(donor.answers.filter((ans) => ans.question.slug === "education_level")[0].answer.value) : null}
                                            {donor.freeze_member.school_ranking && donor.freeze_member.school_ranking !== "empty" ? `, ${handleString(donor.freeze_member.school_ranking)}` : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 text-secondary mb-2">
                                        <div className="h-4 w-4 col-span-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill mr-3" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg>
                                        </div>
                                        <div className="col-span-11 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                                            {donor.answers
                                                ? donor.answers
                                                      .filter((ans) => ans.question.slug === "race")[0]
                                                      .answer.value.map((s) => {
                                                          if (["black_african_american", "indigenous_american_alaska_native"].includes(s)) {
                                                              races[s] = s;
                                                          }
                                                          return RaceMap[s];
                                                      })
                                                      .join(", ")
                                                : null}
                                            {getEthnicities(donor.answers, "mothers_ethnicities", races)}
                                            {getEthnicities(donor.answers, "fathers_ethnicities", races)}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-[#393939]">{donor.freeze_member.profile_bio}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DonorSearch;
