import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Cofertility | FAQ";
    }, []);

    const toggleSelected = (num) => {
        const filteredSelected = structuredClone(selected).filter((selected) => selected !== num);

        if (!selected.includes(num)) {
            filteredSelected.push(num);
        }

        setSelected(filteredSelected);
    };

    const goBack = (path) => {
        navigate(path);
    };

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/freeze/logo.svg`} />;

    return (
        <div className="pb-20 bg-freeze-light-dashboard bg-cover bg-no-repeat bg-center">
            <div className={`px-6 grow flex flex-col`}>
                <div id="faq" className="container mx-auto my-auto">
                    <div className="mt-6 mb-6">
                        <div onClick={() => goBack("/freeze/dashboard")} className="flex gap-x-2 items-center !no-underline cursor-pointer">
                            <span className="h-5 w-5">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <span className="!no-underline">Back</span>
                        </div>
                    </div>

                    <h1 className="mb-1">
                        <span className="heading-underline">Frequently asked questions</span>
                    </h1>

                    <div className="mb-6">
                        <p>If you have any questions at all, please don't hesitate to email. We're here to help.</p>
                        <p>
                            <a href="mailto:freeze-support@cofertility.com" rel="noopener noreferrer" target="_blank">
                                freeze-support@cofertility.com
                            </a>
                        </p>
                    </div>

                    <div>
                        <div className="mb-12">
                            <h1>Split program</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(1)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I apply for the Split program?
                                        <span className={`${selected.includes(1) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(1) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When you submit your intake quiz, you’ll be notified which Cofertility programs you are pre-qualified for. If you are eligible to apply for our Split program,
                                        you’ll be able to submit your full application for the program in your Cofertility account. Once you submit your application, you’ll be notified via email about
                                        the status of your application and the next steps in the process.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(2)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Who qualifies for the Split program?
                                        <span className={`${selected.includes(2) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(2) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While we’d love to have as many members join our Split program as possible, we are required to follow ASRM and FDA guidelines around egg donation, which are
                                        designed to protect the health and wellbeing of everyone involved.
                                    </div>
                                    <br />
                                    <div className="font-semibold">To qualify for the program, among other factors, you must:</div>
                                    <li className="ml-4 my-1">Have both ovaries</li>
                                    <li className="ml-4 my-1">Be between ages 21-33.5</li>
                                    <li className="ml-4 my-1">Have a BMI greater than 18 and less than 30, due to limitations on medication administration and egg retrieval complexities</li>
                                    <li className="ml-4 my-1">Be physically and emotionally healthy with no genetic or reproductive disorders/abnormalities</li>
                                    <li className="ml-4 my-1">Be a non-smoker and abstain from recreational drugs or Depo Provera birth control</li>

                                    <br />
                                    <div>
                                        To learn more, visit{" "}
                                        <a href="https://www.cofertility.com/freeze-learn/the-ultimate-guide-to-the-split-program" target="_blank" rel="noreferrer">
                                            our guide to the Split program.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h1>Keep program</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(3)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What is the Keep program?
                                        <span className={`${selected.includes(3) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(3) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Cofertility’s Keep program offers members the ability to self-pay to freeze their eggs and keep all the eggs retrieved for their own future use, while accessing
                                        discounts through Cofertility’s partner network to help reduce the financial load.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(4)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What are the differences between the Split and Keep programs?
                                        <span className={`${selected.includes(4) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(4) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In Cofertility’s Split program, those who qualify can freeze their eggs for free when they donate half the eggs retrieved to a family who cannot conceive
                                        otherwise.
                                    </div>
                                    <br />
                                    <div className="answer whitespace-pre-line">
                                        In Cofertility’s Keep program, you can self-fund your egg freezing journeys and keep the entire portion of eggs retrieved for your own future use. While this
                                        program is not free, you’ll get access to discounts on parts of the process (like storage) through Cofertility’s partner network.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(5)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I apply for the Keep program?
                                        <span className={`${selected.includes(5) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(5) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When you apply to Cofertility and indicate you are interested in our Keep program, you will have a link in your Cofertility account to start the Keep
                                        application process.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(6)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Who qualifies for the Keep program?
                                        <span className={`${selected.includes(6) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(6) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The Keep program is available to everyone. There are no specific eligibility requirements beyond the initial intake application and being in overall good health
                                        without conditions that impair egg quality or retrieval.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h1>Application & approval</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(7)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How can I check my progress in the application process?
                                        <span className={`${selected.includes(7) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(7) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, you’ll be directed to your home page, which features a progress tracker. This tracker gives you a clear overview of each section of the
                                        application process. It’s a convenient tool that helps you stay on top of your progress and ensures that no steps are overlooked.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(8)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I complete and submit my application?
                                        <span className={`${selected.includes(8) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(8) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To complete your application, log in to your account and you’ll be directed to your checklist, which features a progress tracker. This tracker gives you a clear
                                        overview of each section of your application, showing which parts are complete and which still need to be finished. Simply complete any unfinished sections,
                                        review your entire application, and then submit it for the Cofertility team to review.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(9)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I check the status of my application after submitting it?
                                        <span className={`${selected.includes(9) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(9) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        You will receive email notifications with updates on your application status as soon as our team reviews it. Additionally, after logging into your account
                                        you’ll be directed to your checklist which features a progress tracker. Your progress tracker and application checklist will be updated in your account,
                                        reflecting any changes in real time. This makes it easy to track where you stand in the process.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(10)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How long will it take to find out if my application has been approved?
                                        <span className={`${selected.includes(10) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(10) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Our team aims to review and process applications promptly. You can expect to receive a decision and next steps via email within 2-3 business days after
                                        submitting your application. If there are any delays or additional information is needed, a member of our team will reach out via email.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(11)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What are my next steps after submitting my application?
                                        <span className={`${selected.includes(11) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(11) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After you submit your application, you'll be directed to your checklist, which includes a progress tracker. From there, you’ll be prompted to schedule a call
                                        with a member of our team to discuss the next steps and answer any questions you may have. This call is mandatory for all approved members of the program, but
                                        you can schedule it any time after your application has been submitted. If it turns out that you are not eligible for egg donation and thus unable to
                                        participate in Split, the call will be automatically canceled. You will also receive email notifications with updates on your application status as soon as our
                                        team reviews it.
                                    </div>
                                    <br />
                                    <div>
                                        If approved for our Split program, you’ll be asked to complete a mandatory call with a member of our team. This call is a key step in confirming your interest
                                        in the egg donation and egg freezing processes. During this one-on-one conversation, you'll receive a detailed overview of the Split program and have the
                                        opportunity to ask any questions before deciding to move forward.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(12)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I book my call?
                                        <span className={`${selected.includes(12) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(12) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Once you log into your account, after submitting your application, you’ll see a prompt on the homepage to schedule your call. Simply click the button and choose
                                        a time that works best for you to schedule your call.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(13)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        If I am approved for the Split program, how do I move forward?
                                        <span className={`${selected.includes(13) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(13) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Once you're approved for our Split program, the next step is to have a call with a member of our team. After your call, you’ll be prompted to sign a Split
                                        Member Agreement, which officially confirms your participation in the program and allows us to move forward with listing your profile on our platform so that
                                        you are available to match with intended parents.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(14)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What if I don’t want to move forward with the Split program?
                                        <span className={`${selected.includes(14) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(14) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you decide not to move forward, that's completely fine! You are not obligated to proceed with our Split program at any point prior to matching with intended
                                        parents and signing a legal agreement with them. Simply get in touch to notify us about your decision. If you'd like, you can always explore other options, such
                                        as transitioning to our Keep program, where you can self-pay to freeze your eggs, keep all the eggs retrieved for your own future use, with access to discounts
                                        through Cofertility’s partner network to help lessen the financial burden. We’re here to support whatever decision feels right for you.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(15)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens if I am not eligible to move forward with the Split program?
                                        <span className={`${selected.includes(15) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(15) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you are not eligible for our Split program, you will be notified via email. In that email, you’ll receive more information about other options that may be
                                        available to you, including our Keep program. With the Keep program, you can self-pay to freeze your eggs, keep all the eggs retrieved for your own future use,
                                        and access discounts through Cofertility’s partner network to help reduce the financial load. If you log back into your Cofertility account, you’ll also find
                                        more information about the Keep program on your homepage.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(16)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I move forward with Keep?
                                        <span className={`${selected.includes(16) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(16) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you indicate you are interested in the Keep program at the time of applying, simply log into your Cofertility account, where you will have access to start
                                        the Keep process by filling out a brief intake form.
                                    </div>
                                    <br />
                                    <div>
                                        If you applied but are not eligible for our Split program and decide to move forward with Keep, your account will now include a link to a form to confirm your
                                        interest in Keep and provide further details.{" "}
                                    </div>
                                    <br />
                                    <div>
                                        From there, the rest of your experience will primarily be conducted via email. You’ll receive a checklist outlining the steps involved, but it’s a
                                        straightforward process that ensures you have all the information you need to proceed. You will also benefit from access to Cofertility’s partner network, which
                                        provides discounts to help reduce the financial burden of egg freezing.
                                    </div>
                                    <br />
                                    <div>
                                        If at any time you would like to learn more about or move forward with our Keep program, you can also reach out to us by emailing{" "}
                                        <a href="mailto:freeze-support@cofertility.com" target="_blank" rel="noreferrer">
                                            freeze-support@cofertility.com.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h1>Profile & matching</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(17)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        When will I be able to match with intended parents?
                                        <span className={`${selected.includes(17) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(17) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Once your application is approved, your mandatory 1:1 call is complete, and you have signed your Split Member Agreement, you will be prompted to complete and
                                        submit your Split member profile.
                                    </div>
                                    <br />
                                    <div>
                                        Your Split member profile is what will be listed on our platform for Intended Parents to see, and will act as their chance to get to know you as a person and
                                        potential donor match for their family. Having a complete, thoughtful profile can help you match and start your egg freezing cycle faster. Don’t be afraid to
                                        let your personality shine through! Intended parents love working with us because of how incredibly diverse our donors are. Also, highlighting your motivations
                                        for donating your eggs to another family, not just for freezing them for yourself, can help Intended Parents feel more connected and inspired by your journey.
                                    </div>
                                    <br />
                                    <div>
                                        Once your profile has been reviewed by our team to ensure it is complete, you are officially considered a member of our Split program and as such, your profile
                                        will be available for intended parents to match with.{" "}
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(18)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Can I see what my profile looks like?
                                        <span className={`${selected.includes(18) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(18) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, you can! When you log into your account, just click the 'Profile' tab to see exactly what intended parents see. Note that our team may make cosmetic edits
                                        to your profile to ensure the information displayed is clear, concise, and free of spelling or grammatical errors.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(19)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update my profile?
                                        <span className={`${selected.includes(19) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(19) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To make changes to your profile, click the pencil icon next to each field you’d like to edit. Once you’ve made your changes and saved them, they’ll be submitted
                                        to the Cofertility team for approval. You’ll be notified by email when your updates have been reviewed and approved.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(20)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add more photos to my profile?
                                        <span className={`${selected.includes(20) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(20) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To update or add more photos, log into your account and click the 'Profile' tab. At the top of the page, you’ll see a 'View Photos' button. Click it to see the
                                        photos you’ve already uploaded, and use the 'Upload Photo' button to add more. This is also where you can review our photo guidelines. We require at least 10
                                        photos—five recent and five from infancy and childhood—but there’s no limit, and most donors upload 15-20.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(21)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Why do profile updates need to be approved?
                                        <span className={`${selected.includes(21) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(21) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Profile updates require approval to protect everyone involved. This helps us ensure that all information is accurate, appropriate, and adheres to our profile
                                        guidelines, maintaining a safe and respectful environment for all. You will receive email notifications when your submitted edits are approved, or if additional
                                        revisions are needed.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(22)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How will I know if/when I have a match?
                                        <span className={`${selected.includes(22) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(22) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When an intended parent expresses interest in matching with you, our team will reach out via email to notify you. Thanks to our mutual match process, you’ll
                                        have the opportunity to learn more about the family you matched with and once you confirm your interest in moving forward, your egg freezing cycle will
                                        officially begin!
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(23)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens after I have a match with intended parents?
                                        <span className={`${selected.includes(23) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(23) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Once you have a match, a member of our team will reach out to confirm your interest in moving forward. What happens next depends on your match preferences as
                                        well as those of the intended parents you matched with, but a team member will guide you through the process and help coordinate the next steps for your cycle.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1>Platform support</h1>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(24)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How can I access the community?
                                        <span className={`${selected.includes(24) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(24) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Once you sign your Split member agreement, a team member will reach out to provide you with access to our community.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(25)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Do you have additional educational resources or content?
                                        <span className={`${selected.includes(25) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(25) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, once you log into your account, click the "Learn" tab at the top of the page to access additional educational resources and content. You can also visit{" "}
                                        <a href="http://cofertility.com/freeze/learn" target="_blank" rel="noreferrer">
                                            Freeze by Co | Learn
                                        </a>{" "}
                                        for more information.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(26)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is my information protected?
                                        <span className={`${selected.includes(26) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(26) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Absolutely. Unlike some others in the space, Cofertility uses a proprietary data management system that allows us to manage and protect your personal
                                        information with advanced encryption and privacy protocols.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h2 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(27)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Who can I get in touch with if I have additional questions or concerns?
                                        <span className={`${selected.includes(27) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h2>
                                <div style={selected.includes(27) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you've already been in touch with a member of our team during your application process, feel free to reach out to them directly. If not, you can email us at{" "}
                                        <a href="mailto:freeze-support@cofertility.com">freeze-support@cofertility.com</a>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
