import React from "react";

export const MultiButtonGridFilter = ({ title, items, setFilterArray, selected, setSelected }) => {
    return (
        <div>
            <h4 className="p-1 my-1">{title}</h4>
            <div className="grid grid-cols-2 gap-2 my-1">
                {items.map((item) => (
                    <div
                        onClick={() => setFilterArray(selected, setSelected, item.slug)}
                        className={`w-full rounded-lg p-3 ${selected.includes(item.slug) ? `bg-[#D2E9E6]` : `bg-white`} border border-black cursor-pointer`}
                    >
                        {item.icon ? <img className="max-w-none pb-1" src={`${process.env.PUBLIC_URL}/images/${item.icon}.svg`} alt="Complete your application" width="16" height="16" /> : null}
                        <div>
                            {item.slug
                                .split("_")
                                .map((word) => word[0].toUpperCase() + word.substring(1))
                                .join(" ")}
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
